import React, {useEffect} from 'react'

import EmptyStateImage from "../../assets/images/no-data.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import {connect} from "react-redux";
import {useGetTransferBalanceHistoryQuery} from "../../redux/services/balanceService";

const AccountBalanceTransfersList = (isBalanceChanged) => {

    const { currentData: transfers, refetch: refetchTransfers, isLoading } = useGetTransferBalanceHistoryQuery({}, { refetchOnMountOrArgChange: true })

    useEffect(() => {
        if (isBalanceChanged) {
            refetchTransfers()
        }
    }, [transfers, isBalanceChanged, refetchTransfers]);

    const renderStatus = (status) => {
        if (status === 'TRANSFERRED_TO_USER') {
            return (
                <span className='badge bg-warning'>Transferred to user</span>
            )
        }

        if (status === 'RECEIVED_FROM_USER') {
            return (
                <span className='badge bg-success'>Received from user</span>
            )
        }
    }

    return (
        <div className='col-xl-12 col-xxl-12'>
            <div className='card card-balance-wrapper'>
                <div className='card-header py-3 d-flex flex-wrap justify-content-between align-items-center bg-transparent border-bottom-0'>
                    <h6 className='mb-0 fw-bold'>Transfers</h6>
                </div>
                {!transfers?.data?.length ? (
                    <>
                        {isLoading ? (
                            <div className='d-flex justify-content-center mb-4'>Loading...</div>
                        ) : (
                            <div className='card-body text-center p-5'>
                                <img src={EmptyStateImage} className='img-fluid mx-size' alt='Your account has transfers' />
                                <div className='mt-4 mb-2'>
                                    <span className='text-muted'>Your account has no transfers</span>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className='card-body' style={{ paddingTop: '0px' }}>
                        <PerfectScrollbar className='table-fixed-height'>
                            <div className='table-responsive'>
                                <table className='table table-nowrap mb-0'>
                                    <thead>
                                    <tr>
                                        <th>Currency</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>User</th>
                                        <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {transfers?.data.map((transfer, index) => (
                                        <tr key={index}>
                                            <td style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: '20px', marginRight: '5px' }}
                                                    src={require(`../../assets/images/coin/${transfer.currency.symbol}.png`)}
                                                    alt={transfer.currency.name}
                                                />
                                                {transfer.currency.name}
                                            </td>
                                            <td>
                                                <span className='text-muted'>{parseFloat(transfer.amount)} {transfer.currency.symbol}</span>
                                            </td>
                                            <td>
                                                <span className='text-muted'>{renderStatus(transfer.type)}</span>
                                            </td>
                                            <td>
                                                <span className='text-muted'>{transfer.nickname || '-'}</span>
                                            </td>
                                            <td>
                                                <span className='text-muted'>{transfer.created_at}</span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </PerfectScrollbar>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isBalanceChanged: state.accountReducer.isBalanceChanged,
});

export default connect(mapStateToProps)(AccountBalanceTransfersList);
