import React, { useEffect, useState } from 'react'
import { Button, InputGroup } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'

import { APPUrl } from '../../redux/helpers/utils'

const AffiliateSummaryCard = (props) => {
    const { affiliationsData, affiliationToken, affiliationTokenError } = props

    const token = affiliationToken?.data.token ? affiliationToken.data.token : null

    const [value, setValue] = useState(`${APPUrl}register?affiliate_token=${token}`)
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        setValue(`${APPUrl}register?affiliate_token=${token}`)
    }, [token])

    const handleCopy = ({ target: { value } }) => {
        setValue(value)
        if (copied) {
            setCopied(false)
        }
    }

    const onCopy = () => {
        setCopied(true)
        toast.success('Copied To Clipboard!')
    }

    let affiliationsTotalReward = 0
    let affiliationsTotalCurrency = ''

    affiliationsTotalReward = affiliationsData?.reduce((total, item) => {
        if (item.reward_paid_amount && !isNaN(item.reward_paid_amount)) {
            return total + parseFloat(item.reward_paid_amount)
        }
        return total
    }, affiliationsTotalReward)

    affiliationsTotalCurrency = affiliationsData[0]?.reward_paid_currency || ''

    return (
        <div className='card'>
            <div className='card-header py-3 d-flex justify-content-between bg-transparent'>
                <h6 className='mb-0 fw-bold'>Affiliate</h6>
            </div>
            <div className='card-body'>
                <div className='bg-light p-2'>
                    <div className='d-flex flex-wrap justify-content-around'>
                        <div className='d-flex flex-column'>
                            <span>You Received:</span>
                            <h5>
                                {affiliationsTotalReward ?? affiliationsTotalReward}{' '}
                                {affiliationsTotalCurrency ?? affiliationsTotalCurrency}
                            </h5>
                        </div>
                        <div className='d-flex flex-column'>
                            <span>Invited friends:</span>
                            <h5>{affiliationsData?.length}</h5>
                        </div>
                    </div>
                </div>
                <form className='mt-5'>
                    <div className='mb-3'>
                        <label className='form-label'>Referral Link</label>
                        <input type='text' className='form-control' value={value} onChange={handleCopy} disabled />
                    </div>
                    {affiliationTokenError && (
                        <div className='invalid-feedback mb-3 text-center' style={{ display: 'block' }}>
                            {affiliationTokenError?.data?.message}
                        </div>
                    )}
                    <InputGroup className='input-group-merge'></InputGroup>
                    <CopyToClipboard onCopy={onCopy} text={value}>
                        <Button color='primary'>Copy Link</Button>
                    </CopyToClipboard>
                </form>
            </div>
        </div>
    )
}

export default AffiliateSummaryCard
