import React from 'react'
import { Form, Tab, Button, FloatingLabel } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { ForgotPasswordSchema } from '../../helpers/forms/schema/auth'
import { useForgotPasswordMutation } from '../../redux/services/authService'
import AuthLoadingButton from './AuthLoadingButton'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const ForgotPassword = () => {
    const [userForgotPassword, { isLoading }] = useForgotPasswordMutation()

    const navigate = useNavigate()

    const defaultValues = {
        email: ''
    }

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(ForgotPasswordSchema) })

    const onSubmit = async (data) => {
        try {
            const result = await userForgotPassword(data.email)
            if (!result.error) {
                MySwal.fire({
                    title: 'Password reset',
                    text: 'We have sent you a link to reset your password to your e-mail address',
                    icon: 'success',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                }).then(() => navigate('/'))
            }
        } catch (err) {
            setError('email', {
                type: 'manual',
                message: err.response.data.message
            })
        }
    }

    return (
        <div className='body d-flex p-0 p-xl-5'>
            <div className='container'>
                <div className='row g-3'>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center auth-h100'>
                        <div className='d-flex flex-column'>
                            <h1>Reset Your Password</h1>
                            <span className='text-muted text-center'>Reset password to your account</span>
                            <Tab.Container defaultActiveKey='first'>
                                <Tab.Content className='tab-content mt-4 mb-3'>
                                    <Tab.Pane className='tab-pane fade' id='Email' eventKey='first'>
                                        <div className='card'>
                                            <div className='card-body p-4'>
                                                <Form onSubmit={handleSubmit(onSubmit)}>
                                                    <Form.Group className='mb-3' controlId='emailAddress'>
                                                        <Controller
                                                            control={control}
                                                            name='email'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='email'
                                                                    label='Email address'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.email}
                                                                        placeholder='Email address'
                                                                        type='email'
                                                                    />
                                                                    {errors?.email && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.email.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    {isLoading ? (
                                                        <AuthLoadingButton />
                                                    ) : (
                                                        <Button
                                                            type='submit'
                                                            className='btn btn-primary py-2 fs-5 w-100 mt-2'>
                                                            Reset password
                                                        </Button>
                                                    )}
                                                </Form>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            <div className='text-center'>
                                <Link to='/login' title='#' className='text-primary text-decoration-underline'>
                                    Back to login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
