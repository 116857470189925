import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Select, { components } from 'react-select'

import { yupResolver } from '@hookform/resolvers/yup'
import { CryptoBuySchema } from '../../helpers/forms/schema/base'

import { useForm, Controller } from 'react-hook-form'

import { useGetUserQuery } from '../../redux/services/authService'
import { useBuyCryptoCalculateMutation, useBuyCryptoMutation } from '../../redux/services/buyCryptoService'
import { useGetCryptoExchangeHistoryQuery } from '../../redux/services/exchangeCryptoService'
import { useGetUserFiatBalanceQuery } from '../../redux/services/userService'

import { cryptocurrenciesData } from '../../helpers/cryptocurrency'
import { PriceComponent } from '../../helpers/utils'

const ExchangeCardBuy = (props) => {
    const { mainFiatCurrency, selectedTab } = props

    const cryptocurrencies = cryptocurrenciesData

    const [selectedCurrency, setSelectedCurrency] = useState(cryptocurrencies.find((currency) => currency.value === 'BTC'))
    const [cryptoCalculate, setCryptoCalculate] = useState(null)
    const [exchangeEnabled, setExchangeEnabled] = useState(true)
    const [currentRate, setCurrentRate] = useState(null)
    const [successfullyExchanged, setSuccessfullyExchanged] = useState(false)
    const [currentSocketPrices, setCurrentSocketPrices] = useState('')

    const { currentData: userData, refetch: refreshUserData } = useGetUserQuery()
    const [buyCryptoCalculate, { isCryptoCalculateError }] = useBuyCryptoCalculateMutation()
    const [buyCrypto, { isLoading: isBuyCryptoLoading }] = useBuyCryptoMutation()
    const { refetch: refreshGetCryptoExchangeHistory } = useGetCryptoExchangeHistoryQuery()
    const { currentData: fiatBalance } = useGetUserFiatBalanceQuery()

    const OptionComponent = ({ data, ...props }) => {
        const icon = data.icon
        return (
            <components.Option {...props}>
                {icon}
                {data.label}
            </components.Option>
        )
    }

    const defaultValues = {
        amount: '1'
    }

    const socketData = (data) => {
        setCurrentSocketPrices(data)
    }

    const {
        control,
        handleSubmit,
        setError,
        watch,
        clearErrors,
        setValue,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(CryptoBuySchema) })

    const watchAmountInput = watch('amount')

    useEffect(() => {
        if (selectedTab === 'buy' && watchAmountInput.length > 0) {
            const checkData = async () => {
                try {
                    const result = await buyCryptoCalculate({
                        amount: watchAmountInput,
                        currency: selectedCurrency.value
                    }).unwrap()
                    setCryptoCalculate(result.data)
                    clearErrors('amount')
                    setExchangeEnabled(true)
                    setCurrentRate({
                        currency: selectedCurrency.value,
                        rate: result.data.rate
                    })
                } catch (error) {
                    setExchangeEnabled(false)
                    setCryptoCalculate(null)
                    setError('amount', {
                        type: 'manual',
                        message: error.data?.message ? error.data.message : 'Something went wrong'
                    })
                }
            }
            const delay = setTimeout(() => checkData(watchAmountInput), 800)
            return () => clearTimeout(delay)
        }

        // eslint-disable-next-line
    }, [watchAmountInput, selectedCurrency, currentSocketPrices, selectedTab])

    const onSubmit = async (data) => {
        if (!selectedCurrency) {
            setError('selectedCurrency', {
                type: 'manual',
                message: 'Cryptocurrency field is required'
            })
        } else {
            try {
                await buyCrypto({
                    amount: data.amount,
                    currency: selectedCurrency.value
                }).unwrap()
                setSuccessfullyExchanged(true)
                setTimeout(() => setSuccessfullyExchanged(false), 3000)
                refreshUserData()
                refreshGetCryptoExchangeHistory()
            } catch (error) {
                setError('amount', {
                    type: 'manual',
                    message: error.data.message
                })
            }
        }
    }

    const setAmountValueFromUserBalance = (e) => {
        e.preventDefault()
        setValue('amount', `${fiatBalance?.data?.balance}`)
    }

    return (
        <div className='row g-3'>
            <PriceComponent getPricesData={socketData} />
            <div className='col-lg-12'>
                {userData && (
                    <div className='d-flex align-items-center justify-content-between mu-3'>
                        <span className='small text-muted'>Available</span>
                        <span className=''>
                            <span onClick={setAmountValueFromUserBalance} className='text-secondary a-link'>
                                {parseFloat(fiatBalance?.data?.balance).toFixed(2)} {fiatBalance?.data?.currency}
                            </span>
                        </span>
                    </div>
                )}
                <div className='d-flex align-items-center justify-content-between my-2'>
                    <span className='small text-muted'>Rate</span>
                    <span className=''>
                        {currentRate
                            ? `1 ${mainFiatCurrency} = ${parseFloat(currentRate.rate)} ${currentRate.currency}`
                            : 'n/a'}
                    </span>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <label className='form-label'>Cryptocurrency</label>
                        <Select
                            name='selectedCurrency'
                            options={cryptocurrencies}
                            value={selectedCurrency}
                            onChange={setSelectedCurrency}
                            isClearable={false}
                            components={{
                                Option: OptionComponent
                            }}
                        />
                        {errors?.selectedCurrency && (
                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                {errors.selectedCurrency.message}
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group className='mb-3 mt-3' controlId='amount'>
                        <label className='form-label'>Amount</label>
                        <Controller
                            control={control}
                            name='amount'
                            render={({ field: { onChange, value } }) => (
                                <Form.Control
                                    onChange={onChange}
                                    value={value.replace(/,/g, '.')}
                                    isInvalid={errors.amount}
                                    placeholder='Enter the amount'
                                    type='text'
                                />
                            )}
                        />
                        {errors?.amount && (
                            <Form.Control.Feedback type='invalid'>{errors.amount.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    {cryptoCalculate && (
                        <>
                            <div className=''>
                                <div className='mb-3'>
                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <div className='d-flex exchange-calculate'>
                                            <div className='truncated'>You will exchange</div>
                                            <div className='text-muted  truncated px-1'>
                                                {cryptoCalculate
                                                    ? `${parseFloat(cryptoCalculate.input_amount).toFixed(2)} ${
                                                          cryptoCalculate.input_currency
                                                      }`
                                                    : 'n/a'}
                                            </div>
                                            <div className='truncated'>to</div>
                                            <div className='text-muted  truncated px-1'>
                                                {cryptoCalculate
                                                    ? `${parseFloat(cryptoCalculate.amount)} ${
                                                          cryptoCalculate.output_currency
                                                      }`
                                                    : 'n/a'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {isBuyCryptoLoading ? (
                        <Button disabled className='btn flex-fill btn-light-success py-2 fs-5 text-uppercase px-5 w-100'>
                            Processing...
                            <div className='spinner-border text-light' role='status'></div>
                        </Button>
                    ) : (
                        <>
                            {successfullyExchanged ? (
                                <Button
                                    type='submit'
                                    className='btn flex-fill btn-light-success py-2 fs-5 text-uppercase px-5 w-100'>
                                    <i className='icofont-check-circled'></i> Done!
                                </Button>
                            ) : (
                                <Button
                                    type='submit'
                                    disabled={!exchangeEnabled || isCryptoCalculateError}
                                    className='btn flex-fill btn-light-success py-2 fs-5 text-uppercase px-5 w-100'>
                                    Buy
                                </Button>
                            )}
                        </>
                    )}
                </Form>
            </div>
        </div>
    )
}

export default ExchangeCardBuy
