import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import * as yup from 'yup'

import { useForm, Controller } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { useGetUserQuery } from '../../redux/services/authService'
import {useCopyTradingMutation} from "../../redux/services/copyTradingService";

const CopyTradingModal = (props) => {
    const {
        copyTradingModalOpen,
        setCopyTradingModalOpen,
        traderName,
        traderLastMonthReturn,
        traderTopTradingCurrencies,
        traderId,
        copyTradingOpened,
    } = props

    const [openCopyTradingSuccess, setOpenCopyTradingSuccess] = useState(false)
    const [openCopyTrading, { isLoading }] = useCopyTradingMutation()
    const { currentData: userData } = useGetUserQuery()

    const userMainCurrency = userData?.user?.main_fiat_currency || 'USD'
    const userMainCurrencyBalance = parseFloat(userData?.balances[userMainCurrency] || 0).toFixed(2)

    const defaultValues = {
        amount: '1000',
        stop_loss: '0',
    }

    const FormValidationSchema = yup.object().shape({
        amount: yup
            .number()
            .typeError('Investment amount must be a number')
            .required('Investment amount field is required.')
            .min(100, `Amount must be greater than or equal to 100`),
        stop_loss: yup
            .number()
            .typeError('Stop loss must be a number')
            .min(0, 'Stop loss must be greater than or equal to 0')
    })

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
        watch,
        setValue,
        clearErrors
    } = useForm({ defaultValues, resolver: yupResolver(FormValidationSchema) })

    const watchAmount = watch('amount')
    const watchStopLoss = watch('stop_loss')

    // Modal functions
    const changeAmount = (e, amount) => {
        e.preventDefault()
        setValue('amount', String(amount))
    }

    const handleCloseModal = () => {
        setCopyTradingModalOpen(false)
    }

    const isAbleToSubmit = () => {
        return (
            !isLoading &&
            !errors.amount &&
            !errors.stop_loss &&
            parseFloat(watchAmount) <= userMainCurrencyBalance
        )
    }

    const renderLastMonthReturn = () => {
        if (parseFloat(traderLastMonthReturn) > 0) {
            return <span className='color-price-up'>+{parseFloat(traderLastMonthReturn)}%</span>
        } else if (parseFloat(traderLastMonthReturn) < 0) {
            return <span className='color-price-down'>{parseFloat(traderLastMonthReturn)}%</span>
        } else {
            return <span>{parseFloat(traderLastMonthReturn) || 0}%</span>
        }
    }

    const renderTopTradingCurrencies = (row) => {
        const top5TradingCurrencies = traderTopTradingCurrencies ? traderTopTradingCurrencies.slice(0, 5) : []
        return top5TradingCurrencies.map(currency => (
            <img
                key={currency}
                src={require(`../../assets/images/coin/${currency}.png`)}
                alt={currency}
                className='img-fluid avatar mx-1 cc-small-img'
            />
        ));
    }

    const onSubmit = async (data) => {
        try {
            clearErrors('amount')
            clearErrors('stop_loss')
            await openCopyTrading({
                trader_id: traderId,
                investment_amount: watchAmount,
                investment_currency: userMainCurrency,
                stop_loss: parseFloat(watchStopLoss) > 0 ? watchStopLoss : null
            }).unwrap()
            setOpenCopyTradingSuccess(true)
            setTimeout(() => handleCloseModal(), 2000)
            setTimeout(() => setOpenCopyTradingSuccess(false), 3000)
            copyTradingOpened();
        } catch (err) {
            console.log(err?.data)
            if (err?.data?.errors && Object.keys(err.data.errors).length > 0) {
                Object.keys(err.data.errors).forEach(field => {
                    setError(field, {
                        type: 'manual',
                        message: err.data.errors[field]
                    })
                })
            } else if (err.data && err.data.message) {
                setError('amount', {
                    type: 'manual',
                    message: err.data.message
                })
            } else {
                setError('amount', {
                    type: 'manual',
                    message: 'Something went wrong. Please try again later.'
                })
            }
        }
    }

    return (
        <Modal
            className='modal fade'
            id='icoModal'
            show={copyTradingModalOpen}
            onHide={() => {
                handleCloseModal()
            }}
            fullscreen='sm-down'>
            <Modal.Dialog className='' style={{ maxWidth: '900px' }}>
                <div className='modal-content'>
                    <Modal.Header className='modal-header' closeButton>
                        <h5 className='modal-title'>Copy trading: {traderName}</h5>
                    </Modal.Header>
                    <Modal.Body className='modal-body custom_setting'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className='col-lg-6 col-xl-6'>
                                    <div className='col-md-12'>

                                        <Form.Group className='mb-3 mt-3' controlId='name'>
                                            <label className='form-label'>Investment amount ({userMainCurrency})</label>
                                            <Controller
                                                control={control}
                                                name='amount'
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control
                                                        onChange={onChange}
                                                        value={value.replace(/,/g, '.')}
                                                        isInvalid={errors.amount}
                                                        placeholder={`Investment amount (${userMainCurrency})`}
                                                        type='text'
                                                    />
                                                )}
                                            />
                                            {errors?.amount && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.amount.message}
                                                </Form.Control.Feedback>
                                            )}
                                            {userData && (
                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                    <span className='small text-muted'>Available balance</span>
                                                    <span className=''>
                                                        <a
                                                            href='!#'
                                                            className='text-secondary'
                                                            onClick={(e) => changeAmount(e, userMainCurrencyBalance)}>
                                                            {userMainCurrencyBalance} USD
                                                        </a>
                                                    </span>
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className='mb-3 mt-3' controlId='name'>
                                            <label className='form-label'>Stop copying if your losses reach ({userMainCurrency})</label>
                                            <Controller
                                                control={control}
                                                name='stop_loss'
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control
                                                        onChange={onChange}
                                                        value={value.replace(/,/g, '.')}
                                                        isInvalid={errors.stop_loss}
                                                        placeholder={`Stop copying if your losses reach (${userMainCurrency})`}
                                                        type='text'
                                                    />
                                                )}
                                            />
                                            {errors?.stop_loss && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.stop_loss.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-xl-6'>
                                    <div className='card mb-3'>
                                        <div className='card-body'>
                                            <div className='checkout-sidebar'>
                                                <div className='checkout-sidebar-price-table '>
                                                    <table className='table'>
                                                        <tbody>
                                                        <tr>
                                                            <td>Return (last mo.)</td>
                                                            <td className='text-end'>
                                                                {renderLastMonthReturn()}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Top trading currencies (last mo.)</td>
                                                            <td className='text-end'>
                                                                {renderTopTradingCurrencies()}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Stop copying if your losses reach</td>
                                                            <td className='text-end'>
                                                                {watchStopLoss} {userMainCurrency}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Investment amount</td>
                                                            <td className='text-end'>
                                                                {watchAmount} {userMainCurrency}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            {isLoading ? (
                                                <Button
                                                    type='submit'
                                                    className='btn btn-primary py-2 fs-5 w-100 mt-2 btn-loading'
                                                    disabled>
                                                    Processing...
                                                    <div className='spinner-border text-light' role='status'></div>
                                                </Button>
                                            ) : (
                                                <>
                                                    {openCopyTradingSuccess ? (
                                                        <Button
                                                            type='submit'
                                                            className='btn flex-fill btn-light-success py-2 fs-5 px-5 w-100'>
                                                            <i className='icofont-check-circled'></i> Done!
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type='submit'
                                                            className='btn btn-primary py-2 fs-5 w-100 mt-2'
                                                            disabled={!isAbleToSubmit()}>
                                                            Start copying
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal.Dialog>
        </Modal>
    )
}

export default CopyTradingModal
