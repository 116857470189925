import * as yup from 'yup'

const NICKNAME = process.env.REACT_APP_FEATURE_NICKNAME

export const LoginSchema = yup.object().shape({
    email: yup.string().email('Please enter a valid email address.').required('Email field is required.'),
    password: yup.string().required('Password field is required.')
})

export const VerificationSchema = yup.object().shape({
    code: yup.string().required('Verification code field is required.')
})

export const RegisterSchema = yup.object().shape({
    name: yup.string().required('Name field is required.').max(64, 'Name should have 64 characters or less.'),
    surname: yup.string().required('Surname field is required.').max(64, 'Surname should have 64 characters or less.'),
    email: yup.string().email('Please enter a valid email address.').required('Email field is required.'),
    password: yup.string().required('Password field is required.').min(8, 'Password should have 8 characters or more.'),
    password_confirmation: yup
        .string()
        .required('Password field is required.')
        .oneOf([yup.ref(`password`), null], 'Passwords must match'),
    phone_number: yup.string().required('Phone number field is required.'),
    terms: yup.bool().oneOf([true], 'Consent to Terms & Conditions is required.'),
    ...(NICKNAME === 'true' && {
        nickname: yup.string().required('Nickname field is required.').max(64, 'Nickname should have 64 characters or less.')
    })
})

export const ForgotPasswordSchema = yup.object().shape({
    email: yup.string().email('Please enter a valid email address.').required('Email field is required.')
})

export const ResetPasswordSchema = yup.object().shape({
    email: yup.string().email('Please enter a valid email address.').required('Email field is required.'),
    password: yup.string().required('Password field is required.').min(8, 'Password should have 8 characters or more.'),
    password_confirmation: yup
        .string()
        .required('Password field is required.')
        .oneOf([yup.ref(`password`), null], 'Passwords must match')
})
