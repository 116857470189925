import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const sellCryptoApi = createApi({
    reducerPath: 'sellCryptoApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        sellCrypto: build.mutation({
            query: (data) => ({
                url: 'exchange/sell-crypto',
                method: 'POST',
                body: data
            })
        }),
        sellCryptoCalculate: build.mutation({
            query: (data) => ({
                url: 'exchange/sell-crypto/calculate',
                method: 'POST',
                body: data
            })
        })
    })
})

export const { useSellCryptoMutation, useSellCryptoCalculateMutation } = sellCryptoApi
