import React from 'react'
import { Form, Tab, Button, FloatingLabel } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useGetUserQuery, useLoginMutation } from '../../redux/services/authService'
import AuthLoadingButton from './AuthLoadingButton'
import { VerificationSchema } from '../../helpers/forms/schema/auth'

const Verification = (props) => {
    const { watchEmail, watchPassword, handleResetForm } = props

    const [userLogin, { isLoading }] = useLoginMutation()
    const { refetch } = useGetUserQuery()

    const navigate = useNavigate()

    const defaultValues = {
        code: ''
    }

    const {
        control,
        setError,
        handleSubmit,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(VerificationSchema) })

    const onSubmit = async (data) => {
        try {
            const result = await userLogin({
                email: watchEmail,
                password: watchPassword,
                '2faCode': data.code
            })
            if (!result.error) {
                refetch()
                navigate('/')
            } else {
                setError('code', {
                    type: 'manual',
                    message: result.error.data.error
                })
            }
        } catch (err) {
            Object.keys(err.response.data.errors).forEach((key) => {
                setError(key, {
                    type: 'manual',
                    message: err.response.data.message
                })
            })
        }
    }

    return (
        <div className='body d-flex p-0 p-xl-5'>
            <div className='container'>
                <div className='row g-3 justify-content-center'>
                    <div className='col-lg-4 d-flex justify-content-center align-items-center auth-h100'>
                        <div className='d-flex flex-column'>
                            <h1>Verify it's you</h1>
                            <span className='text-muted'>
                                We sent a verification code to your email. Enter the code from the email in the field below.
                            </span>
                            <Tab.Container defaultActiveKey='first'>
                                <Tab.Content className='tab-content mt-4 mb-3'>
                                    <Tab.Pane className='tab-pane fade' id='Email' eventKey='first'>
                                        <div className='card'>
                                            <div className='card-body p-4'>
                                                <Form onSubmit={handleSubmit(onSubmit)}>
                                                    <Form.Group className='mb-3' controlId='code'>
                                                        <Controller
                                                            control={control}
                                                            name='code'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='code'
                                                                    label='Enter verification code'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.code}
                                                                        placeholder='Enter verification code'
                                                                        type='text'
                                                                    />
                                                                    {errors?.code && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.code.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    {isLoading ? (
                                                        <AuthLoadingButton />
                                                    ) : (
                                                        <Button
                                                            type='submit'
                                                            className='btn btn-primary py-2 fs-5 w-100 mt-2'>
                                                            Verify Code
                                                        </Button>
                                                    )}
                                                </Form>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            <div className='text-center'>
                                <span>Any problem? </span>
                                <Link
                                    onClick={() => handleResetForm()}
                                    to='/login'
                                    title='#'
                                    className='text-primary text-decoration-underline'>
                                    Try again.
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Verification
