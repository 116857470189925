import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { ChangeEmailSchema } from '../../helpers/forms/schema/base'
import { useChangeEmailMutation } from '../../redux/services/userService'

import { useGetUserQuery } from '../../redux/services/authService'

import { yupResolver } from '@hookform/resolvers/yup'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const ChangeEmail = () => {
    const [changeUserEmail] = useChangeEmailMutation()

    const { currentData: userData, refetch: refreshUserData } = useGetUserQuery({}, { refetchOnMountOrArgChange: true })

    const defaultValues = {
        current_email: '',
        email: '',
        password: ''
    }

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
        setValue
    } = useForm({ defaultValues, resolver: yupResolver(ChangeEmailSchema) })

    useEffect(() => {
        if (userData) {
            setValue('current_email', userData?.user.email)
        }
    }, [userData])

    const handleSuccess = () => {
        return MySwal.fire({
            title: 'Success',
            text: 'Email has been changed',
            icon: 'success',
            customClass: {
                confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
    }

    const onSubmit = async (data) => {
        try {
            const result = await changeUserEmail({
                email: data.email,
                password: data.password
            })
            if (result.error) {
                setError('password', {
                    type: 'manual',
                    message: result.error.data.message
                })
            } else {
                handleSuccess()
                reset()
                refreshUserData()
            }
        } catch (error) {
            Object.keys(error.response.data.errors).forEach((key) => {
                setError(key, {
                    type: 'manual',
                    message: error.response.data.message
                })
            })
        }
    }

    return (
        <div className='col-xl-9'>
            <div className='card'>
                <div className='card-header py-3 d-flex justify-content-between bg-transparent align-items-center '>
                    <h6 className='mb-0 fw-bold'>Change Email Address</h6>
                </div>
                <div className='card-body'>
                    <div className='col-sm-12'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className='mb-3' controlId='current_email'>
                                <label className='form-label'>Email</label>
                                <Controller
                                    control={control}
                                    name='current_email'
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            isInvalid={errors.current_email}
                                            type='text'
                                            disabled
                                        />
                                    )}
                                />
                                {errors?.current_email && (
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.current_email.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='email'>
                                <label className='form-label'>New Email Address</label>
                                <Controller
                                    control={control}
                                    name='email'
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            isInvalid={errors.email}
                                            placeholder='New Email Address'
                                            type='email'
                                        />
                                    )}
                                />
                                {errors?.email && (
                                    <Form.Control.Feedback type='invalid'>{errors.email.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='password'>
                                <label className='form-label'>Password</label>
                                <Controller
                                    control={control}
                                    name='password'
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            isInvalid={errors.password}
                                            placeholder='Password'
                                            type='password'
                                        />
                                    )}
                                />
                                {errors?.password && (
                                    <Form.Control.Feedback type='invalid'>{errors.password.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Button type='submit' className='btn btn-primary py-2 fs-5 w-100 mt-2'>
                                Save
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeEmail
