import { useEffect, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import {usePub} from "./usePubSub";

const usePriceWebSocket = () => {
    const publish = usePub();

    const socketUrl = 'wss://api.coiniy.com:2054/'
    const message = JSON.stringify({ channel: 'pricesV1' })

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl)

    const [getPrices, setGetPrices] = useState('')

    useEffect(() => {
        if (readyState === WebSocket.OPEN) {
            sendMessage(message)
        }
        // eslint-disable-next-line
    }, [readyState])

    useEffect(() => {
        if (lastMessage) {
            const payload = JSON.parse(lastMessage.data)
            if (!payload.event) {
                setGetPrices(payload)
            }
            if (payload.event === 'pricesV1') {
                setGetPrices(payload.data)
                publish(payload.event, payload.data);
            }
        }
        // eslint-disable-next-line
    }, [lastMessage])

    return { getPrices, setGetPrices }
}

export default usePriceWebSocket
