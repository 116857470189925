import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'

import { useCryptoExchangeCalculateMutation, useCryptoExchangeMutation } from '../../redux/services/exchangeCryptoService'

import ExchangeCardBuy from './ExchangeCardBuy'
import ExchangeCardExchange from './ExchangeCardExchange'
import ExchangeCardSell from './ExchangeCardSell'

const ExchangeCard = (props) => {
    const { mainFiatCurrency } = props

    const [cryptoExchange, { isLoading: isCryptoExchangeLoading }] = useCryptoExchangeMutation()
    const [cryptoExchangeCalculate, { isLoading: isCryptoCalculateLoading }] = useCryptoExchangeCalculateMutation()
    const [selectedTab, setSelectedTab] = useState('buy')

    return (
        <div className='card mb-3 exchange-card'>
            <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                <h6 className='mb-0 fw-bold '>Exchange</h6>
            </div>
            <div className='card-body'>
                <Tab.Container defaultActiveKey='first'>
                    <Tabs
                        onSelect={(selectedKey) => setSelectedTab(selectedKey)}
                        defaultActiveKey='buy'
                        transition={false}
                        id='noanim-tab-example1'
                        className='tab-body-header rounded d-inline-flex mb-3 exchange-tab'>
                        <Tab eventKey='buy' title='Buy'>
                            <div className='card mb-3'>
                                <div className='card-body'>
                                    <ExchangeCardBuy mainFiatCurrency={mainFiatCurrency} selectedTab={selectedTab} />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey='sell' title='Sell'>
                            <div className='card mb-3'>
                                <div className='card-body'>
                                    <ExchangeCardSell mainFiatCurrency={mainFiatCurrency} selectedTab={selectedTab} />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey='exchange' title='Convert'>
                            <div className='card mb-3'>
                                <div className='card-body'>
                                    <ExchangeCardExchange
                                        cryptoExchange={cryptoExchange}
                                        isCryptoExchangeLoading={isCryptoExchangeLoading}
                                        cryptoExchangeCalculate={cryptoExchangeCalculate}
                                        isCryptoCalculateLoading={isCryptoCalculateLoading}
                                        selectedTab={selectedTab}
                                    />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </Tab.Container>
            </div>
        </div>
    )
}
export default ExchangeCard
