import React, { useState, useEffect, useRef } from 'react';
import Avatar4 from '../../assets/images/lg/avatar4.png';
import { useGetUserQuery } from '../../redux/services/authService'
import { useUploadAvatarMutation } from '../../redux/services/userService'
function ChangeAvatar() {
    const { currentData: userData, refetch: refreshUserData } = useGetUserQuery({}, { refetchOnMountOrArgChange: true })
    const fileInputRef = useRef(null);
    const [uploadAvatar] = useUploadAvatarMutation()

    const [avatar, setAvatar] = useState(
        userData && userData.user.avatar_url ? userData.user.avatar_url : Avatar4
    )

    useEffect(() => {
        if (userData && userData.user.avatar_url) {
            setAvatar(userData.user.avatar_url)
        } else {
            setAvatar(Avatar4)
        }
    }, [userData])

    const handleUpload = async (file) => {
        if (!file) {
          return;
        }
    
        const formData = new FormData();
        formData.append('file', file)

        await uploadAvatar(formData)
    }

    const handleFileSelect = async (event) => {
        setAvatar(URL.createObjectURL(event.target.files[0]))
        await handleUpload(event.target.files[0]);
        window.location.reload();

    };

    if (!userData || !userData.user) {
        return (
            <> </>
        )
    }

    return (
        <div className='col-xl-3'>
            <div className="card profile-card flex-column mb-3">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                    <h6 className="mb-0 fw-bold ">Profile</h6>
                </div>
                <div className="card-body d-flex profile-fulldeatil flex-column">
                    <div className="profile-block text-center w220 mx-auto">
                        <a href="#!">
                            <img src={avatar} alt="" className="avatar xl rounded img-thumbnail shadow-sm" />
                        </a>
                        <input
                            accept="image/*" 
                            type="file"
                            onChange={handleFileSelect}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                        />
                        <button className="btn btn-primary" onClick={() => fileInputRef.current?.click()} style={{ position: 'absolute', top: '15px', right: '15px' }}><i className="icofont-upload-alt"></i></button>
                    </div>
                    <div className="profile-info w-100">
                    <h6 className="mb-0 mt-2  fw-bold d-block fs-6 text-center">{userData.user.name} {userData.user.surname}</h6>
                    <span className="py-1 fw-bold small-11 mb-0 mt-1 text-muted text-center mx-auto d-block">{userData.user.nickname}</span>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeAvatar;