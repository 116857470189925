import React, { useState, useEffect } from 'react';
import SinglePost from "./components/SinglePost";
import { useGetPostQuery } from '../../redux/services/newsfeedService';
import { useParams } from 'react-router-dom';

const Post = () => {
    const [post, setPost] = useState(null);
    const { postId } = useParams();

    // Fetch posts using a Redux query
    const { data: result, isLoading } = useGetPostQuery({ postId });

    // Effect to set posts when the query data changes
    useEffect(() => {
        if (result?.data?.post) {
            setPost(result.data.post);
        }
    }, [result]);

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className='newsfeed-wrapper'>
            <div className='container-xxl bs-container-reset pd-left-xsmall'>
                <div className='row g-3 mb-1'>
                    <div className='col-xxl-6 order-2 order-xxl-1'>
                        {post ? <SinglePost key={post.id} post={post}/> : <div>Loading...</div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Post;
