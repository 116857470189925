import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Sidebar from './component/Comman/Sidebar'

import menu from '../src/component/Data/Menu/Menu.json'
import menu2 from '../src/component/Data/Menu/Menu2.json'
import AuthIndex from './screen/AuthIndex'
import MainIndex from './screen/MainIndex'
import {ZohoChat} from "zoho-chat";

const App = () => {

    const isFeatureFlagEnabled = (featureFlag) => {
        const featureEnabled = window.localStorage.getItem(`feature-${featureFlag}`);

        return featureEnabled === 'true';
    }
    const getMenuFilteredByFeatureFlags = () => {
        const menuData = [...menu.menu];
        return menuData.filter((menuItem) => {
            if (menuItem.featureFlag) {
                return isFeatureFlagEnabled(menuItem.featureFlag);
            }
            return true;
        });
    }

    const [menuData, setMenuData] = useState([...getMenuFilteredByFeatureFlags()])
    const navigate = useNavigate()

    let baseUrl = process.env.PUBLIC_URL

    const activekey = () => {
        let res = window.location.pathname
        let baseUrl = process.env.PUBLIC_URL
        baseUrl = baseUrl.split('/')
        res = res.split('/')
        res = res.length > 0 ? res[baseUrl.length] : '/'
        res = res ? '/' + res : '/'
        const activeKey1 = res
        return activeKey1
    }

    if (
        activekey() === '/login' ||
        activekey() === '/register' ||
        activekey() === '/forgot-password' ||
        activekey() === '/reset-password' ||
        activekey() === '/error-404'
    ) {
        return (
            <div id='cryptoon-layout' className='theme-orange'>
                <AuthIndex />
                <ZohoChat
                    url={"https://salesiq.zoho.com/widget"}
                    widgetCode={"1586562194e1677410a92cb21375b2a70046bee9c4dfc558b4995dff4622d1c2"}
                    position={"bottomright"}
                />
            </div>
        )
    }

    const GotoChangeMenu = (val) => {
        if (val === 'UI Components') {
            navigate(baseUrl + '/ui-alerts')
            setMenuData([...menu2.menu2])
        } else if (val === 'changelog') {
            navigate(baseUrl + '/ui-alerts')
            setMenuData([...menu2.menu2])
        } else if (val === 'documentation') {
            navigate(baseUrl + '/ui-alerts')
            setMenuData([...menu2.menu2])
        } else {
            navigate(baseUrl + '/')
            setMenuData([...menu.menu])
        }
    }

    return (
        <div id='cryptoon-layout' className='theme-orange'>
            <Sidebar
                activekey={activekey()}
                menuData={menuData}
                GotoChangeMenu={(val) => {
                    GotoChangeMenu(val)
                }}
            />
            <MainIndex
                activekey={activekey()}
                GotoChangeMenu={(val) => {
                    GotoChangeMenu(val)
                }}
            />
            <ZohoChat
                url={"https://salesiq.zoho.com/widget"}
                widgetCode={"1586562194e1677410a92cb21375b2a70046bee9c4dfc558b4995dff4622d1c2"}
                position={"bottomright"}
            />
        </div>
    )
}

export default App
