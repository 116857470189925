import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { ChangePasswordSchema } from '../../helpers/forms/schema/base'
import { useChangePasswordMutation } from '../../redux/services/userService'

import { yupResolver } from '@hookform/resolvers/yup'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const ChangePassword = () => {
    const [changeUserPassword] = useChangePasswordMutation()

    const defaultValues = {
        password: '',
        new_password: '',
        password_confirmation: ''
    }

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(ChangePasswordSchema) })

    const handleSuccess = () => {
        return MySwal.fire({
            title: 'Success',
            text: 'Password has been changed',
            icon: 'success',
            customClass: {
                confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
    }

    const onSubmit = async (data) => {
        try {
            const result = await changeUserPassword({
                current_password: data.password,
                new_password: data.new_password,
                new_password_confirmation: data.password_confirmation
            })
            if (result.error) {
                setError('password_confirmation', {
                    type: 'manual',
                    message: result.error.data.message
                })
            } else {
                handleSuccess()
                reset()
            }
        } catch (error) {
            Object.keys(error.response.data.errors).forEach((key) => {
                setError(key, {
                    type: 'manual',
                    message: error.response.data.message
                })
            })
        }
    }

    return (
        <div className='col-xl-9'>
            <div className='card'>
                <div className='card-header py-3 d-flex justify-content-between bg-transparent align-items-center '>
                    <h6 className='mb-0 fw-bold'>Change Password</h6>
                </div>
                <div className='card-body'>
                    <div className='col-sm-12'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className='mb-3' controlId='password'>
                                <label className='form-label'>Password</label>
                                <Controller
                                    control={control}
                                    name='password'
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            isInvalid={errors.password}
                                            placeholder='Password'
                                            type='password'
                                        />
                                    )}
                                />
                                {errors?.password && (
                                    <Form.Control.Feedback type='invalid'>{errors.password.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='new_password'>
                                <label className='form-label'>New Password</label>
                                <Controller
                                    control={control}
                                    name='new_password'
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            isInvalid={errors.new_password}
                                            placeholder='New Password'
                                            type='password'
                                        />
                                    )}
                                />
                                {errors?.new_password && (
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.new_password.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='password_confirmation'>
                                <label className='form-label'>Confirm new password</label>
                                <Controller
                                    control={control}
                                    name='password_confirmation'
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            isInvalid={errors.password_confirmation}
                                            placeholder='Confirm password'
                                            type='password'
                                        />
                                    )}
                                />
                                {errors?.password_confirmation && (
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.password_confirmation.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Button type='submit' className='btn btn-primary py-2 fs-5 w-100 mt-2'>
                                Save
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
