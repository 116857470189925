import React, {useEffect} from 'react'

import EmptyStateImage from "../../assets/images/no-data.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Tooltip} from "react-tooltip";
import {connect} from "react-redux";
import {useGetCryptoDepositsQuery} from "../../redux/services/userService";

const AccountDepositList = (isBalanceChanged) => {

    const { currentData: cryptoDeposits, refetch: refetchCryptoDeposits, isLoading } = useGetCryptoDepositsQuery({}, { refetchOnMountOrArgChange: true })

    useEffect(() => {
        if (isBalanceChanged) {
            refetchCryptoDeposits()
        }
    }, [cryptoDeposits, isBalanceChanged, refetchCryptoDeposits]);

    const truncate = (input, length = 50) => {
        if (input.length > length) {
            return input.substring(0, length) + '...';
        }
        return input;
    };

    const addressWithTag = (address, tag) => {
        return tag ? `${address} (tag: ${tag})` : address;
    }

    const renderStatus = (status) => {
        if (status === 'pending') {
            return (
                <span className='badge bg-info'>Waiting for deposit</span>
            )
        }

        if (status === 'expired') {
            return (
                <span className='badge bg-warning'>No deposit received</span>
            )
        }

        if (status === 'failed') {
            return (
                <span className='badge bg-danger'>Deposit failed</span>
            )
        }

        if (status === 'completed') {
            return (
                <span className='badge bg-success'>Completed</span>
            )
        }
    }

    const getStatusTooltipContent = (deposit) => {

        if (deposit.status === 'pending') {
            const expireDateWithoutSeconds = deposit.expires_at.substring(0, deposit.expires_at.length - 3);
            return `We are awaiting payment until ${expireDateWithoutSeconds}. The status will be updated once the deposit is credited to our account.`;
        }

        if (deposit.status === 'expired') {
            return 'The time for making the deposit has expired. If you have made the deposit and the time has passed, please contact our support.';
        }

        if (deposit.status === 'failed') {
            return 'We have not received the deposit';
        }

        if (deposit.status === 'completed') {
            return 'The deposit has been credited. The funds should now be in your account.';
        }
    }

    return (
        <div className='col-xl-12 col-xxl-12'>
            <div className='card card-balance-wrapper'>
                <div className='card-header py-3 d-flex flex-wrap justify-content-between align-items-center bg-transparent border-bottom-0'>
                    <h6 className='mb-0 fw-bold'>Deposits</h6>
                </div>
                {!cryptoDeposits?.data?.length ? (
                    <>
                        {isLoading ? (
                            <div className='d-flex justify-content-center mb-4'>Loading...</div>
                        ) : (
                            <div className='card-body text-center p-5'>
                                <img src={EmptyStateImage} className='img-fluid mx-size' alt='Your account has no deposits' />
                                <div className='mt-4 mb-2'>
                                    <span className='text-muted'>Your account has no deposits</span>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className='card-body' style={{ paddingTop: '0px' }}>
                        <PerfectScrollbar className='table-fixed-height'>
                            <div className='table-responsive'>
                                <table className='table table-nowrap mb-0'>
                                    <thead>
                                    <tr>
                                        <th>Currency</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Wallet</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {cryptoDeposits?.data.map((deposit, index) => (
                                        <tr key={index}>
                                            <td style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: '20px', marginRight: '5px' }}
                                                    src={require(`../../assets/images/coin/${deposit.currency.symbol}.png`)}
                                                    alt={deposit.currency.name}
                                                />
                                                {deposit.currency.name}
                                            </td>
                                            <td>
                                                <span className='text-muted'>{parseFloat(deposit.amount)} {deposit.currency.symbol}</span>
                                            </td>
                                            <td>
                                                <Tooltip id={`cryptoDepositsStatus-${index}`} key={`cryptoDepositsStatus-${index}`} />
                                                <span
                                                    data-tooltip-id={`cryptoDepositsStatus-${index}`}
                                                    data-tooltip-content={getStatusTooltipContent(deposit)}
                                                    className='text-muted'>{renderStatus(deposit.status)}</span>
                                            </td>
                                            <td>
                                                <span className='text-muted'>{deposit.created_at}</span>
                                            </td>
                                            <td>
                                                <Tooltip id={`cryptoDepositsAddress-${index}`} key={`cryptoDepositsAddress-${index}`} />
                                                <span className='text-muted'
                                                      data-tooltip-id={`cryptoDepositsAddress-${index}`}
                                                      data-tooltip-content={addressWithTag(deposit.address.address, deposit.address.tag)}>
                                                    {truncate(deposit.address.address, 20)}</span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </PerfectScrollbar>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isBalanceChanged: state.accountReducer.isBalanceChanged,
});

export default connect(mapStateToProps)(AccountDepositList);
