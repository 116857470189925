import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import CommentListItem from "./CommentListItem";
import {useGetLastPostCommentsQuery} from "../../../redux/services/newsfeedService";

const CommentList = ({ postId, comments, hasNextPage }) => {

    const [localComments, setLocalComments] = useState(comments);
    const [cursor, setCursor] = useState(null);
    const [fetchNext, setFetchNext] = useState(false);
    const [localHasNextPage, setLocalHasNextPage] = useState(hasNextPage);

    const { data: fetchedComments, isFetching } = useGetLastPostCommentsQuery({ postId, count: 20, cursor }, {
        skip: !fetchNext,
    });

    useEffect(() => {
        if (fetchedComments?.data?.comments) {
            setLocalComments(prev => [...prev, ...fetchedComments.data.comments]);
            setCursor(fetchedComments.data.comments[fetchedComments.data.comments.length - 1]?.id);
            setFetchNext(false);
            setLocalHasNextPage(fetchedComments?.data?.hasNextPage);
        }
    }, [fetchedComments]);

    useEffect(() => {
        setLocalComments(comments);
        setCursor(comments[comments.length - 1]?.id);
        setLocalHasNextPage(hasNextPage);
    }, [comments, hasNextPage]);

    const handleLoadMore = () => {
        if (!isFetching) {
            setFetchNext(true);
        }
    };

    const renderLoadMoreButton = () => {
        if (comments.length === 0) {
            return (<></>);
        }

        if (!localHasNextPage) {
            return (<></>);
        }

        return (
            <ListGroup.Item>
                <span onClick={handleLoadMore} className="text-muted" style={{'cursor': 'pointer'}}>
                    Load previous comments
                </span>
            </ListGroup.Item>
        );
    }

    return (
        <ListGroup variant="flush">
            {localComments.map(comment => (
                <ListGroup.Item key={comment.id}>
                    <CommentListItem comment={comment} depth={0} />
                </ListGroup.Item>
            ))}
            {renderLoadMoreButton()}
        </ListGroup>
    );
};

export default CommentList;
