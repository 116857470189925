import { combineReducers } from 'redux'

import templateReducer from './templateReducer'

import { authApi } from '../services/authService'
import { userApi } from '../services/userService'
import { stakingApi } from '../services/stakingService'
import { affiliateApi } from '../services/affiliateService'
import { exchangeCryptoApi } from '../services/exchangeCryptoService'
import { cfdApi } from '../services/cfdService'
import { autoTrading } from '../services/autoTradingService'
import { buyCryptoApi } from '../services/buyCryptoService'
import { sellCryptoApi } from '../services/sellCryptoService'
import { tradeApi } from '../services/tradeService'
import {copyTradingApi} from "../services/copyTradingService";
import accountReducer from "./accountReducer";
import {newsfeedApi} from "../services/newsfeedService";
import {balanceApi} from "../services/balanceService";

const appReducer = combineReducers({
    templateReducer,
    accountReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [stakingApi.reducerPath]: stakingApi.reducer,
    [affiliateApi.reducerPath]: affiliateApi.reducer,
    [exchangeCryptoApi.reducerPath]: exchangeCryptoApi.reducer,
    [cfdApi.reducerPath]: cfdApi.reducer,
    [autoTrading.reducerPath]: autoTrading.reducer,
    [buyCryptoApi.reducerPath]: buyCryptoApi.reducer,
    [sellCryptoApi.reducerPath]: sellCryptoApi.reducer,
    [tradeApi.reducerPath]: tradeApi.reducer,
    [copyTradingApi.reducerPath]: copyTradingApi.reducer,
    [newsfeedApi.reducerPath]: newsfeedApi.reducer,
    [balanceApi.reducerPath]: balanceApi.reducer
})

export default appReducer
