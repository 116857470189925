import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const balanceApi = createApi({
    reducerPath: 'balanceApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        transferBalance: build.mutation({
            query: (data) => ({
                url: 'balance/transfer',
                method: 'POST',
                body: data
            })
        }),
        getTransferBalanceHistory: build.query({
            query: () => ({
                url: 'balance/transfer/history',
                method: 'GET'
            })
        })
    })
})

export const { useTransferBalanceMutation, useGetTransferBalanceHistoryQuery } = balanceApi
