import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Tooltip } from 'react-tooltip'

import appLogo from '../../assets/images/logo/coiniy_logo.png'
import appLogoMobile from '../../assets/images/logo/coiniy_yellow.png'

const Sidebar = ({ activekey, menuData }) => {
    const [isSidebarMini, setIsSidebarMini] = useState(false)

    const baseUrl = process.env.PUBLIC_URL

    const openChildren1 = (id) => {
        var otherTabs = document.getElementsByClassName('has-children')
        if (otherTabs) {
            for (var i = 0; i < otherTabs.length; i++) {
                otherTabs[i].className = otherTabs[i].className.replace(' show', '')
            }
        }
        var menutab = document.getElementById(id)
        if (menutab) {
            menutab.classList.add('show')
            if (menutab.parentElement.children.length > 1) {
                menutab.parentElement.children[0].setAttribute('aria-expanded', 'true')
            }
        }
    }

    const openChildren = (id) => {
        var otherTabs = document.getElementsByClassName('has-children')
        if (otherTabs) {
            for (var i = 0; i < otherTabs.length; i++) {
                if (otherTabs[i].id !== id) {
                    otherTabs[i].className = otherTabs[i].className.replace(' show', '')
                    if (otherTabs[i].parentElement.children.length > 1) {
                        otherTabs[i].parentElement.children[0].setAttribute('aria-expanded', 'false')
                    }
                }
            }
        }
        var menutab = document.getElementById(id)
        if (menutab) {
            if (menutab.classList.contains('show')) {
                menutab.classList.remove('show')
                if (menutab.parentElement.children.length > 1) {
                    menutab.parentElement.children[0].setAttribute('aria-expanded', 'false')
                }
            } else {
                menutab.classList.add('show')
                if (menutab.parentElement.children.length > 1) {
                    menutab.parentElement.children[0].setAttribute('aria-expanded', 'true')
                }
            }
        }
    }

    const handleMobileSidebarOpen = () => {
        const sidebar = document.getElementById('mainsidemenu')
        if (sidebar) {
            if (sidebar.classList.contains('open')) {
                sidebar.classList.remove('open')
            } else {
                sidebar.classList.add('open')
            }
        }
    }

    return (
        <div id='mainsidemenu' className={`sidebar py-2 py-md-2 me-0 border-end ${isSidebarMini ? 'sidebar-mini' : ''}`}>
            <div className='d-flex flex-column h-100'>
                <div className='d-flex align-items-center justify-content-between'>
                    <Link
                        to={process.env.PUBLIC_URL + '/'}
                        className={`mb-0 brand-icon ${isSidebarMini && 'sidebar-toggled'}`}>
                        {isSidebarMini ? (
                            <img src={appLogoMobile} className='img-fluid sidebar-toggled-logo' alt='app logo' />
                        ) : (
                            <img src={appLogo} className='img-fluid app-logo-auth' alt='app logo' />
                        )}
                    </Link>
                    <i className='fa fa-times mobile-close' onClick={handleMobileSidebarOpen}></i>
                </div>
                <ul className='menu-list flex-grow-1 mt-4 px-1'>
                    {menuData.map((data, index) => (
                        <li
                            key={'menuDataList' + index}
                            className={`collapsed${data.isToggled ? ' show' : ''} ${data.disabled ? 'disabled-link' : ''}`}
                            onClick={() => {
                                if (!data.children.length > 0) {
                                    handleMobileSidebarOpen()
                                }
                            }}
                            data-tooltip-id={data.name}
                            data-tooltip-content={data.name}>
                            {data.children.length === 0 ? (
                                <Link
                                    to={`${baseUrl}/${data.routerLink[0]}`}
                                    className={`m-link${'/' + data.routerLink[0] === activekey ? ' active' : ''}`}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        x='0px'
                                        y='0px'
                                        width='24px'
                                        height='24px'
                                        viewBox={data.viewBox}>
                                        <path
                                            xmlns='http://www.w3.org/2000/svg'
                                            d={data.iconClasss}
                                            style={{ fill: 'var(--primary-color)' }}
                                            data-st='fill:var(--chart-color4);'></path>
                                        <path xmlns='http://www.w3.org/2000/svg' className='st0' d={data.iconClass}></path>
                                    </svg>
                                    <div>
                                        <h6 className='mb-0'>{data.name}</h6>
                                        <small className='text-muted'>{data.subject}</small>
                                    </div>
                                </Link>
                            ) : (
                                <>
                                    <Link
                                        to='#!'
                                        className={`m-link${
                                            data.children.filter(
                                                (child) => `${baseUrl}/${child.routerLink[0]}` === activekey
                                            ).length > 0
                                                ? ' active'
                                                : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            openChildren('menu-Pages' + index)
                                        }}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            x='0px'
                                            y='0px'
                                            width='24px'
                                            height='24px'
                                            viewBox={data.viewBox}>
                                            <path
                                                xmlns='http://www.w3.org/2000/svg'
                                                d={data.iconClasss}
                                                style={{ fill: 'var(--primary-color)' }}
                                                data-st='fill:var(--chart-color4);'></path>
                                            <path
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='st0'
                                                d={data.iconClass}></path>
                                        </svg>
                                        <div>
                                            <h6 className='mb-0'>{data.name}</h6>
                                            <small className='text-muted'>{data.subject}</small>
                                        </div>
                                        <span className='arrow icofont-rounded-down ms-auto text-end fs-5'></span>
                                    </Link>
                                    {data.children.length > 0 ? (
                                        <ul className='sub-menu collapse has-children' id={'menu-Pages' + index}>
                                            {data.children.map((data, ind) => {
                                                if (data.children.length > 0) {
                                                    if (activekey === '/' + data.routerLink[0]) {
                                                        setTimeout(() => {
                                                            openChildren1('menu-Pages')
                                                        }, 500)
                                                    }
                                                }
                                                return (
                                                    <li
                                                        key={'011' + ind}
                                                        className={data.disabled ? 'disabled-link' : ''}
                                                        onClick={() => {
                                                            if (!data.children.length > 0) {
                                                                handleMobileSidebarOpen()
                                                            }
                                                        }}>
                                                        <Link
                                                            className={`${
                                                                activekey === '/' + data.routerLink[0]
                                                                    ? 'ms-link active'
                                                                    : 'ms-link'
                                                            }`}
                                                            to={baseUrl + '/' + data.routerLink[0]}>
                                                            <span>{data.name}</span>
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    ) : null}
                                    {data.isToggled && (
                                        <ul className='sub-menu collapse has-children' id={`menu-Pages${index}`}>
                                            {data.children.map((child, ind) => (
                                                <li key={ind}>
                                                    <Link
                                                        className={
                                                            activekey === `/${child.routerLink[0]}`
                                                                ? 'ms-link active'
                                                                : 'ms-link'
                                                        }
                                                        to={`${baseUrl}/${child.routerLink[0]}`}>
                                                        <span>{child.name}</span>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </>
                            )}
                        </li>
                    ))}
                </ul>
                {isSidebarMini &&
                    menuData.map((data, index) => {
                        return <Tooltip id={data.name} key={`tooltip-${index}`} />
                    })}
                <button
                    type='button'
                    className='btn btn-link sidebar-mini-btn text-muted'
                    onClick={() => setIsSidebarMini(!isSidebarMini)}>
                    <span className='ms-2'>
                        <i className='icofont-bubble-right'></i>
                    </span>
                </button>
            </div>
        </div>
    )
}

export default Sidebar
