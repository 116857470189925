import React from 'react'

import { Tab } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useGetCryptoExchangeHistoryQuery } from '../../redux/services/exchangeCryptoService'

const ExchangeHistory = (props) => {
    const { mainFiatCurrency } = props
    const { currentData: cryptoExchangeHistoryData } = useGetCryptoExchangeHistoryQuery()

    const columns = () => {
        return [
            {
                name: 'Date',
                selector: (row) => row.created_at,
                sortable: true,
                cell: (row) => (
                    <>
                        <span>{row.created_at}</span>
                    </>
                ),
                minWidth: '170px'
            },
            {
                name: 'From currency',
                selector: (row) => row.input_currency,
                cell: (row) => (
                    <>
                        <img
                            src={require(`../../assets/images/coin/${row.input_currency}.png`)}
                            alt={row.coin}
                            className='img-fluid avatar mx-1'
                        />{' '}
                        <span style={{ marginRight: '5px' }}>{row.input_currency} </span>
                        {row.input_currency === mainFiatCurrency ? (
                            <span className='text-muted'> {parseFloat(row.input_amount).toFixed(2)}</span>
                        ) : (
                            <span className='text-muted'> {row.input_amount}</span>
                        )}
                    </>
                ),
                sortable: true,
                minWidth: '250px'
            },
            {
                name: 'To currency',
                selector: (row) => row.output_currency,
                cell: (row) => (
                    <>
                        <img
                            src={require(`../../assets/images/coin/${row.output_currency}.png`)}
                            alt={row.coin}
                            className='img-fluid avatar mx-1'
                        />{' '}
                        <span style={{ marginRight: '5px' }}>{row.output_currency} </span>
                        {row.output_currency === mainFiatCurrency ? (
                            <span className='text-muted'> {parseFloat(row.output_amount).toFixed(2)}</span>
                        ) : (
                            <span className='text-muted'> {row.output_amount}</span>
                        )}
                    </>
                ),
                sortable: true,
                minWidth: '250px'
            },
            {
                name: 'Rate',
                selector: (row) => row.rate,
                sortable: true,
                cell: (row) => (
                    <>
                        {row.output_currency === mainFiatCurrency ? (
                            <>
                                <span>1 {row.input_currency} = </span>
                                <span className='text-muted' style={{ marginLeft: '3px' }}>
                                    {' '}
                                    {parseFloat(row.rate).toFixed(2)}{' '}
                                </span>
                                <span style={{ marginLeft: '3px' }}> {row.output_currency}</span>
                            </>
                        ) : (
                            <>
                                <span>1 {row.input_currency} = </span>
                                <span className='text-muted' style={{ marginLeft: '3px' }}>
                                    {' '}
                                    {row.rate}{' '}
                                </span>
                                <span style={{ marginLeft: '3px' }}> {row.output_currency}</span>
                            </>
                        )}
                    </>
                ),
                minWidth: '170px'
            }
        ]
    }

    return (
        <div className='card'>
            <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                <h6 className='mb-0 fw-bold '>Transaction history</h6>
            </div>
            <div className='card-body'>
                <Tab.Container defaultActiveKey='first'>
                    <Tab.Content className='tab-content'>
                        <Tab.Pane className='tab-pane fade ' id='OpenOrder' eventKey='first'>
                            <DataTable
                                title='Transaction history'
                                columns={columns()}
                                data={cryptoExchangeHistoryData}
                                defaultSortField='title'
                                selectableRows={false}
                                highlightOnHover={true}
                                pagination
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    )
}

export default ExchangeHistory
