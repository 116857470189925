import * as yup from 'yup'

export const BaseEmailSchema = yup.object().shape({
    email: yup.string().email('Please enter a valid email address.').required('Email field is required.')
})

export const CryptoExchangeSchema = yup.object().shape({
    amount: yup.number().typeError('Amount must be a number').required('Amount field is required.')
})

export const CryptoBuySchema = yup.object().shape({
    amount: yup.number().typeError('Amount must be a number').required('Amount field is required.')
})

export const CFDformSchema = yup.object().shape({
    amount: yup.number().typeError('Amount must be a number').required('Amount field is required.'),
    stop_loss_price: yup.number().typeError('Stop loss price must be a number').nullable(),
    take_profit_price: yup.number().typeError('Take profit price must be a number').nullable(),
    stop_loss: yup.number().typeError('Stop loss must be a number').nullable(),
    take_profit: yup.number().typeError('Take profit must be a number').nullable()
})

export const ChangePasswordSchema = yup.object().shape({
    password: yup.string().required('Password field is required.').min(8, 'Password should have 8 characters or more.'),
    new_password: yup
        .string()
        .required('New Password field is required.')
        .min(8, 'New Password should have 8 characters or more.'),
    password_confirmation: yup
        .string()
        .required('Password Confirmation field is required.')
        .oneOf([yup.ref(`new_password`), null], 'Passwords must match')
})

export const ChangeEmailSchema = yup.object().shape({
    email: yup.string().email('Please enter a valid email address.').required('Email field is required.'),
    password: yup.string().required('Password field is required.')
})

export const AutoTradingSchema = yup.object().shape({
    amount: yup.number().typeError('Amount must be a number').required('Amount field is required.')
})

export const UpdateTradingSchema = yup.object().shape({
    amount: yup.number().typeError('Amount must be a number').required('Amount field is required.')
})

export const OpenSignalSchema = yup.object().shape({
    amount: yup.number().typeError('Amount must be a number').required('Amount field is required.')
})

export const WithdrawFormSchema = yup.object().shape({
    amount: yup.number().typeError('Amount must be a number').required('Amount field is required.'),
    address: yup.string().required('Address field is required.')
})

export const TransferFormSchema = yup.object().shape({
    amount: yup.number().typeError('Amount must be a number').required('Amount field is required.'),
    receiver: yup.string().required('Receiver field is required.'),
    currency: yup.string().required('Currency field is required.')
})

export const DepositFormSchema = yup.object().shape({
    amount: yup.number().required('Amount field is required.').typeError('Amount must be a number')
})
