import React, { useState } from 'react'

import { Button, Form, Modal } from 'react-bootstrap'
import Select, { components } from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { DepositFormSchema } from '../../helpers/forms/schema/base'

import { cryptocurrenciesData } from '../../helpers/cryptocurrency'
import { useDepositCryptoMutation } from '../../redux/services/userService'

import { useDispatch } from "react-redux";
import { accountBalanceChanged } from "../../redux/reducer/actions";

const DepositModal = (props) => {
    const { depositModalOpen, setDepositModalOpen } = props

    const cryptocurrencies = cryptocurrenciesData

    const [depositCrypto] = useDepositCryptoMutation()

    const [selectedCrypto, setSelectedCrypto] = useState(cryptocurrencies.find((currency) => currency.value === 'BTC'))
    const [showWalletAddress, setShowWalletAddress] = useState('')

    const defaultValues = {
        amount: ''
    }

    const dispatch = useDispatch();

    const OptionComponent = ({ data, ...props }) => {
        const icon = data.icon
        return (
            <components.Option {...props}>
                {icon}
                {data.label}
            </components.Option>
        )
    }

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(DepositFormSchema) })

    const setDefaultValues = () => {
        setSelectedCrypto(cryptocurrencies.find((currency) => currency.value === 'BTC'))
        setShowWalletAddress('')
        reset()
    }

    const handleCloseModal = () => {
        setDepositModalOpen(false)
        setDefaultValues()
    }

    const onSubmit = async (data) => {
        try {
            const result = await depositCrypto({
                currency: selectedCrypto.value,
                amount: data.amount
            })
            if (result.error) {
                setError('apiError', {
                    type: 'manual',
                    message: result.error.data.message
                })
            } else {
                setShowWalletAddress(result.data.data)
                dispatch(accountBalanceChanged());
            }
        } catch (error) {
            setError('apiError', {
                type: 'manual',
                message: error.data.message
            })
        }
    }

    return (
        <Modal
            className='modal fade'
            id='icoModal'
            show={depositModalOpen}
            onHide={() => {
                handleCloseModal()
            }}
            fullscreen='sm-down bs-modal-xs'>
            <Modal.Dialog className='' style={{ maxWidth: '900px' }}>
                <div className='modal-content'>
                    <Modal.Header className='modal-header' closeButton>
                        <h5 className='modal-title'>Deposit</h5>
                    </Modal.Header>
                    <Modal.Body className='modal-body custom_setting'>
                        {showWalletAddress ? (
                            <div>
                                <div className='d-flex justify-content-center mb-3'>
                                    <span>Please, deposit {selectedCrypto.label} to below address:</span>
                                </div>
                                <div className='d-flex justify-content-center mb-3'>
                                    <span className='fw-bold'>{showWalletAddress}</span>
                                </div>
                            </div>
                        ) : (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='col-md-12'>
                                            <Form.Group className='mb-3'>
                                                <label className='form-label'>Cryptocurrency</label>
                                                <Select
                                                    name='cryptocurrency'
                                                    options={cryptocurrencies}
                                                    value={selectedCrypto}
                                                    onChange={setSelectedCrypto}
                                                    isClearable={false}
                                                    components={{
                                                        Option: OptionComponent
                                                    }}
                                                />
                                                {errors?.cryptocurrency && (
                                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                                        {errors.cryptocurrency.message}
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className='mb-3' controlId='amount'>
                                                <label className='form-label'>Amount</label>
                                                <Controller
                                                    control={control}
                                                    name='amount'
                                                    render={({ field: { onChange, value } }) => (
                                                        <Form.Control
                                                            onChange={onChange}
                                                            value={value.replace(/,/g, '.')}
                                                            isInvalid={errors.amount}
                                                            placeholder='Enter the amount'
                                                            type='text'
                                                        />
                                                    )}
                                                />
                                                {errors?.amount && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.amount.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            {errors?.apiError && (
                                                <div
                                                    className='invalid-feedback mb-3 text-center'
                                                    style={{ display: 'block' }}>
                                                    {errors.apiError.message}
                                                </div>
                                            )}
                                            <Button type='submit' className='btn flex-fill fs-5 w-100'>
                                                Show address
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Modal.Body>
                </div>
            </Modal.Dialog>
        </Modal>
    )
}

export default DepositModal
