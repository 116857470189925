import React from 'react'
import DataTable from 'react-data-table-component'
import EmptyTable from '../EmptyStates/EmptyTable'

const AffiliatesList = (props) => {
    const { affiliationsData } = props

    const columns = () => {
        return [
            {
                name: 'Name',
                selector: (row) => row.name,
                sortable: true,
                cell: (row) => (
                    <>
                        <span className='fw-bold ms-1'>{row.invited_user_name}</span>
                    </>
                ),
                minWidth: '250px'
            },
            {
                name: 'Registration date',
                selector: (row) => row.created_at,
                sortable: true
            },
            {
                name: 'Status',
                selector: (row) => {},
                sortable: true,
                cell: (row) => (
                    <>
                        {row.reward_paid_amount === null ? (
                            <span className='badge bg-info'>Registered</span>
                        ) : (
                            <span className='badge bg-success'>Paid</span>
                        )}
                    </>
                )
            },
            {
                name: 'Reward',
                selector: (row) => row.reward_paid_amount,
                sortable: true,
                cell: (row) => (
                    <>
                        {row.reward_paid_amount === null ? (
                            <span className='ms-1'>0 USD</span>
                        ) : (
                            <span className='ms-1'>
                                {row.reward_paid_amount} {row.reward_paid_currency}
                            </span>
                        )}
                    </>
                )
            }
        ]
    }

    return (
        <div className='card mb-3'>
            <DataTable
                title='Datatable'
                columns={columns()}
                data={affiliationsData || []}
                noDataComponent={EmptyTable()}
                pagination
                subHeaderComponent={() => {
                    return <input type='text' />
                }}
                selectableRows={false}
                className='table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline'
                highlightOnHover={true}
            />
        </div>
    )
}

export default AffiliatesList
