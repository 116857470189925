import React, {useEffect, useState} from 'react'

import { Button, ProgressBar } from 'react-bootstrap'
import { Tooltip } from 'react-tooltip'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { useGetUserCryptoBalanceQuery, useGetUserFiatBalanceQuery } from '../../redux/services/userService'

import WithdrawModal from './WithdrawModal'
import DepositModal from './DepositModal'

import EmptyStateImage from '../../assets/images/no-data.svg'
import 'react-perfect-scrollbar/dist/css/styles.css'
import {connect} from "react-redux";
import AccountBalanceTransferModal from "./AccountBalanceTransferModal";

const AccountBalance = (isBalanceChanged) => {
    const { currentData: cryptoBalance, refetch: refetchCryptoBalance, isLoading } = useGetUserCryptoBalanceQuery({}, { refetchOnMountOrArgChange: true })
    const { currentData: fiatBalance } = useGetUserFiatBalanceQuery({}, { refetchOnMountOrArgChange: true })

    const [withdrawModalOpen, setWithdrawModalOpen] = useState(false)
    const [depositModalOpen, setDepositModalOpen] = useState(false)
    const [accountBalanceTransferModalOpen, setAccountBalanceTransferModalOpen] = useState(false)

    useEffect(() => {
        if (isBalanceChanged) {
            refetchCryptoBalance()
        }
    }, [cryptoBalance, isBalanceChanged, refetchCryptoBalance]);

    return (
        <div className='col-xl-6 col-xxl-6'>
            <div className='card card-balance-wrapper'>
                <div className='card-header py-3 d-flex flex-wrap justify-content-between align-items-center bg-transparent border-bottom-0'>
                    <h6 className='mb-0 fw-bold'>Balance</h6>
                    <div>
                        <button
                            onClick={() => setAccountBalanceTransferModalOpen(true)}
                            type='button'
                            className='btn btn-white border lift mt-1'
                            style={{marginRight: '10px'}}>
                            Transfer
                        </button>
                        <AccountBalanceTransferModal
                            modalOpen={accountBalanceTransferModalOpen}
                            setModalOpen={setAccountBalanceTransferModalOpen}
                        />
                        <button
                            onClick={() => setWithdrawModalOpen(true)}
                            type='button'
                            className='btn btn-white border lift mt-1'
                            style={{marginRight: '10px'}}>
                            Withdraw
                        </button>
                        <Button
                            onClick={() => setDepositModalOpen(true)}
                            type='button'
                            className='btn btn-white border lift mt-1'
                            style={{marginRight: '10px'}}>
                            Deposit
                        </Button>
                        <WithdrawModal
                            withdrawModalOpen={withdrawModalOpen}
                            setWithdrawModalOpen={setWithdrawModalOpen}
                        />
                        <DepositModal depositModalOpen={depositModalOpen} setDepositModalOpen={setDepositModalOpen}/>
                    </div>
                </div>
                {!cryptoBalance ? (
                    <>
                        {isLoading ? (
                            <div className='d-flex justify-content-center'>Loading...</div>
                        ) : (
                            <div className='card-body text-center p-5'>
                                <img src={EmptyStateImage} className='img-fluid mx-size' alt='Your account has no funds' />
                                <div className='mt-4 mb-2'>
                                    <span className='text-muted'>Your account has no funds</span>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className='card-body' style={{ paddingTop: '0px' }}>
                        <span className='text-muted'>Available cash:</span>
                        {fiatBalance?.data?.balance > 0 ? (
                            <div className='fw-bolder d-block mb-2'>
                                <img
                                    style={{ width: '20px', marginRight: '5px' }}
                                    src={require(`../../assets/images/coin/${fiatBalance.data.currency}.png`)}
                                    alt={fiatBalance.data.currency}
                                />
                                {parseFloat(fiatBalance.data.balance).toFixed(2)} {fiatBalance.data.currency}
                            </div>
                        ) : (
                            <div className='mb-2'>
                                <span>You don't have any funds.</span>
                            </div>
                        )}
                        <span className='text-muted'>Total value:</span>
                        <span className='h3 d-block mb-2'>
                            {parseFloat(cryptoBalance.data.total_value).toFixed(2)} {cryptoBalance.data.total_value_currency}
                        </span>
                        <ProgressBar style={{ height: '5px' }} className='progress rounded-pill mb-1'>
                            {Object.keys(cryptoBalance.data.balances).map((item, index) => {
                                const balanceData = cryptoBalance.data.balances[item]
                                return (
                                    <ProgressBar
                                        data-tooltip-id={item}
                                        data-tooltip-content={item}
                                        className={`progress-bar chart-color${index + 1}`}
                                        now={parseFloat((balanceData.value_percent * 100).toFixed(2))}
                                        key={`progress-${index}`}
                                    />
                                )
                            })}
                        </ProgressBar>
                        {Object.keys(cryptoBalance.data.balances).map((item, index) => {
                            return <Tooltip id={item} key={`tooltip-${index}`} />
                        })}
                        <div className='d-flex justify-content-between mb-4'>
                            <span>0%</span>
                            <span>100%</span>
                        </div>
                        <PerfectScrollbar className='table-fixed-height'>
                            <div className='table-responsive'>
                                <table className='table table-nowrap mb-0'>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Amount</th>
                                            <th>Value</th>
                                            <th>Portfolio</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cryptoBalance && (
                                            <>
                                                {Object.keys(cryptoBalance.data.balances).map((item, index) => {
                                                    const balanceData = cryptoBalance.data.balances[item]

                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    style={{ width: '20px', marginRight: '5px' }}
                                                                    src={require(`../../assets/images/coin/${item}.png`)}
                                                                    alt={item}
                                                                />
                                                                {item}
                                                            </td>
                                                            <td>{balanceData.balance}</td>
                                                            <td>
                                                                {parseFloat(balanceData.value).toFixed(2)}{' '}
                                                                {balanceData.value_currency}
                                                            </td>
                                                            <td>
                                                                <span className='badge bg-success'>
                                                                    {parseFloat(
                                                                        (balanceData.value_percent * 100).toFixed(2)
                                                                    )}{' '}
                                                                    %
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </PerfectScrollbar>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isBalanceChanged: state.accountReducer.isBalanceChanged,
});

export default connect(mapStateToProps)(AccountBalance);
