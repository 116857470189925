import React from 'react'
import DataTable from 'react-data-table-component'
import { formatDate } from '../../helpers/utils'

import { useGetStakingRewardedQuery } from '../../redux/services/stakingService'

const StakingRewardAssets = () => {
    const { currentData: stakingRewardedAssets } = useGetStakingRewardedQuery()

    const columns = () => {
        return [
            {
                name: 'Coin',
                selector: (row) => row.coin,
                cell: (row) => (
                    <>
                        <img
                            src={require(`../../assets/images/coin/${row.coin}.png`)}
                            alt={row.coin}
                            className='img-fluid avatar mx-1'
                        />{' '}
                        {row.coin}
                        <span className='text-muted'>{row.title}</span>
                    </>
                ),
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'APY & Amount',
                selector: (row) => row.apy,
                sortable: true,
                maxWidth: '150px',
                minWidth: '150px',
                cell: (row) => (
                    <div style={{ flexDirection: 'column' }}>
                        <div>
                            <span className='text-muted'>APY: </span>
                            <span className='color-price-up'>{row.apy}</span>
                        </div>
                        <div>
                            <span className='text-muted'>Amount: </span>
                            <span>{row.amount}</span>
                            <span className='ml-1'>{row.coin}</span>
                        </div>
                    </div>
                )
            },
            {
                name: 'Duration',
                selector: (row) => row.duration_in_days,
                sortable: true,
                cell: (row) => (
                    <>
                        <span>{row.duration_in_days} Days</span>
                    </>
                )
            },
            {
                name: 'Started',
                selector: (row) => row.started_at,
                sortable: true,
                cell: (row) => {
                    const formattedDate = formatDate(row.started_at)
                    return <span>{formattedDate}</span>
                }
            }
        ]
    }

    return (
        <div className='row g-3 mb-3'>
            <div className='col-lg-12 col-md-12'>
                <div id='DataTables_Table_11_wrapper' className='dataTables_wrapper dt-bootstrap5 no-footer'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <DataTable
                                title='Rewarded Staking'
                                columns={columns()}
                                data={stakingRewardedAssets?.data || []}
                                defaultSortField='title'
                                selectableRows={false}
                                highlightOnHover={true}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StakingRewardAssets
