import React from 'react'

import AffiliatesList from './AffiliatesList'
import AffiliateSummaryCard from './AffiliateSummaryCard'

import { useGetAffiliationsQuery, useGetAffiliateTokenQuery } from '../../redux/services/affiliateService'
import { useGetUserQuery } from '../../redux/services/authService'
import EmptyReferralProgram from '../EmptyStates/EmptyReferralProgram'

const Affiliate = () => {
    const { currentData: affiliationsData } = useGetAffiliationsQuery()
    const { currentData: affiliationToken, error: affiliationTokenError } = useGetAffiliateTokenQuery()
    const { currentData: userData } = useGetUserQuery()

    return (
        <div className='container-xxl bs-container-reset pd-left-xsmall'>
            <div className='row g-3 mb-3 row-deck'>
                {userData?.user.demo_enabled ? (
                    <>
                        <EmptyReferralProgram />
                    </>
                ) : (
                    <>
                        <div className='col-xl-12 col-xxl-8'>
                            <AffiliatesList affiliationsData={affiliationsData} />
                        </div>
                        <div className='col-xl-12 col-xxl-4'>
                            <AffiliateSummaryCard
                                affiliationsData={affiliationsData || []}
                                affiliationToken={affiliationToken}
                                affiliationTokenError={affiliationTokenError}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Affiliate
