import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const cfdApi = createApi({
    reducerPath: 'cfdApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        openPositions: build.mutation({
            query: (data) => ({
                url: 'cfd/positions',
                method: 'POST',
                body: {
                    ...data,
                    stop_loss_price: data.stop_loss_price,
                    take_profit_price: data.take_profit_price
                }
            })
        }),
        updatePosition: build.mutation({
            query: ({id, data}) => ({
                url: `cfd/positions/${id}`,
                method: 'PUT',
                body: {
                    ...data
                }
            })
        }),
        getClosedPositions: build.query({
            query: () => ({
                url: 'cfd/closed-positions',
                method: 'GET'
            })
        }),
        getOpenPositions: build.query({
            query: () => ({
                url: 'cfd/open-positions',
                method: 'GET'
            })
        }),
        getPositionsProfitLoss: build.query({
            query: () => ({
                url: 'cfd/open-positions/profit-loss',
                method: 'GET'
            })
        }),
        deletePosition: build.mutation({
            query: (positionId) => ({
                url: `cfd/positions/${positionId}`,
                method: 'DELETE'
            })
        }),
        getCFDOpenPositionCalculation: build.query({
            query: (data) => ({
                url: 'cfd/open-position/calculate',
                method: 'GET',
                params: data
            })
        }),
    })
})

export const {
    useOpenPositionsMutation,
    useGetClosedPositionsQuery,
    useGetOpenPositionsQuery,
    useGetPositionsProfitLossQuery,
    useDeletePositionMutation,
    useGetCFDOpenPositionCalculationQuery,
    useUpdatePositionMutation
} = cfdApi
