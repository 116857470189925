import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useCreatePostMutation, useUploadMediaMutation } from '../../../redux/services/newsfeedService';

const MAX_CHARACTERS = 5000;

const CreatePostForm = ({onPostCreated, showUpload}) => {
    const [postText, setPostText] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [charactersCount, setCharactersCount] = useState(0);
    const [createPostMutation] = useCreatePostMutation();
    const [uploadMedia] = useUploadMediaMutation();
    const [isPublishing, setIsPublishing] = useState(false);
    const [isPublished, setIsPublished] = useState(false);


    const onPostTextChange = (event) => {
        setCharactersCount(event.target.value.length)
        setPostText(event.target.value)
    }

    const handleSubmit = async (event) => {
        setIsPublishing(true);

        event.preventDefault();
        setPostText('');
        setCharactersCount(0);

        let coverUrl = null;

        if (selectedFile) {
            const uploadResult = await handleUpload(selectedFile);
            if (uploadResult?.data?.url) {
                coverUrl = uploadResult?.data?.url;
            }
        }

        const result = await createPostMutation({content: postText, coverUrl});
        setSelectedFile(null);

        if (onPostCreated) {
            onPostCreated(result.data.data.post);
        }

        setIsPublishing(false);
        setIsPublished(true);

        setTimeout(() => {
            setIsPublished(false);
        }, 3000);
    };

    const handleFileChange = async (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async (file) => {
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file)

        return await uploadMedia(formData)
    }

    const renderImage = () => {
        if (selectedFile) {
            return (
                <img
                    src={URL.createObjectURL(selectedFile)}
                    alt=""
                    style={{width: '100%', height: 'auto', objectFit: 'cover', marginBottom: '10px'}}
                />
            );
        }
        return '';
    }

    const renderPublishButton = () => {
        if (isPublishing) {
            return (
                <Button disabled={true} type="submit" size="sm">Publishing...</Button>
            );
        }

        if (isPublished) {
            return (
                <Button disabled={true} type="submit" size="sm">Published</Button>
            );
        }

        return (
            <Button disabled={charactersCount === 0} type="submit" size="sm">Publish</Button>
        );
    }

    return (
        <div className="card mb-3">
            <div className="card-body">
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>What's on your mind?</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            maxLength={MAX_CHARACTERS}
                            onChange={(e) => onPostTextChange(e)}
                            placeholder="Share your thoughts. You can use #tags to categorize your post."
                            value={postText}
                        />
                    </Form.Group>
                    {showUpload && (
                        <Form.Group className="mb-3" style={{display: 'none'}}>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                id={'upload-newsfeed'}
                            />
                        </Form.Group>
                    )}
                    {renderImage()}
                    <div className="d-flex justify-content-between">
                        <button
                            type='button'
                            className='btn btn-white border lift'
                            onClick={() => document.querySelector('#upload-newsfeed').click()}
                        >
                            <i className='fa fa-image me-1' aria-hidden='true'></i> Upload
                        </button>
                        <div className="d-flex justify-content-end align-items-center">
                            <span className="me-3 small text-muted">{charactersCount}/{MAX_CHARACTERS}</span>
                            {renderPublishButton()}
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default CreatePostForm;
