export const APIUrl = `${process.env.REACT_APP_API_URL}`
export const APPUrl = `${process.env.REACT_APP_APP_URL}`

export const APIHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
}

export const setTokens = (token) => {
    localStorage.setItem('accessToken', token.accessToken)
}

export const removeTokens = () => {
    localStorage.removeItem('accessToken')
}

/**
 * @returns {string|null}
 */
export const getToken = () => {
    return localStorage.getItem('accessToken')
}
