import React, { useState, useEffect } from 'react';
import CreatePostForm from "./components/CreatePostForm";
import SinglePost from "./components/SinglePost";
import { useGetPostsQuery } from '../../redux/services/newsfeedService';
import { useSub } from '../../helpers/usePubSub';

const Posts = () => {
    const [posts, setPosts] = useState([]);
    const [cursor, setCursor] = useState(null);

    // Subscription to a PubSub service when a new post is created
    useSub('post_created', (post) => {
        const tag = getTagFromUrlQuery();
        if (!tag || post.tags.includes(tag)) {
            post.isLiked = false;
            onPostCreated(post);
        }
    });

    const getTagFromUrlQuery = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('tag');
    }

    // Fetch posts using a Redux query
    const { data: result, isLoading } = useGetPostsQuery({ limit: 10, cursor, tag: getTagFromUrlQuery() });

    // Effect to set posts when the query data changes
    useEffect(() => {
        if (result?.data?.posts) {
            setPosts(prevPosts => {
                const newPosts = result.data.posts.filter(newPost => !prevPosts.some(post => post.id === newPost.id));
                return [...prevPosts, ...newPosts];
            });
        }
    }, [result]);

    // Update posts state by prepending the new post
    const onPostCreated = (post) => {
        setPosts(prevPosts => [post, ...prevPosts]);
    };

    if (isLoading) return <div>Loading...</div>;

    const loadRecentPosts = () => {
        if (result?.data?.posts?.length > 0) {
            setCursor(result.data.posts[result.data.posts.length - 1]?.id);
        }
    }

    return (
        <div className='newsfeed-wrapper'>
            <div className='container-xxl bs-container-reset pd-left-xsmall'>
                <div className='row g-3 mb-1'>
                    <div className='col-xxl-6 order-2 order-xxl-1'>
                        <CreatePostForm showUpload={true}/>
                        {posts.map((post) => (
                            <SinglePost key={post.id} post={post}/>
                        ))}
                    </div>
                </div>
            </div>
            <div className='row g-3 mb-1'>
                <div className='col-xxl-6 order-2 order-xxl-1 text-center' style={{'cursor': 'pointer'}} onClick={loadRecentPosts}>
                    <span className={'text-muted'}>Load recent posts</span>
                </div>
            </div>
        </div>
    );
}

export default Posts;
