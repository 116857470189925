import React, { useState } from 'react'

import Select, { components } from 'react-select'
import { useForm } from 'react-hook-form'
import { Form, Modal } from 'react-bootstrap'

import { useGetUserQuery } from '../../redux/services/authService'
import { useChangeMainCurrencyMutation } from '../../redux/services/userService'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export const currencies = [
    {
        value: '1',
        label: 'USD',
        icon: <img src={require(`../../assets/images/coin/USD.png`)} alt='USD' className='img-fluid avatar mx-1' />
    },
    {
        value: '2',
        label: 'USDT',
        icon: <img src={require(`../../assets/images/coin/USDT.png`)} alt='USDT' className='img-fluid avatar mx-1' />
    }
]

const AccountCurrency = () => {
    const [openModal, setOpenModal] = useState(false)
    const [selectedCurrency, setSelectedCurrency] = useState('')

    const { currentData: userData, isLoading, refetch } = useGetUserQuery()
    const [changeMainCurrency] = useChangeMainCurrencyMutation()

    const mainCurrency = userData?.user?.main_fiat_currency

    const OptionComponent = ({ data, ...props }) => {
        const icon = data.icon
        return (
            <components.Option {...props}>
                {icon}
                {data.label}
            </components.Option>
        )
    }

    const {
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm()

    const handleSuccess = () => {
        return MySwal.fire({
            title: 'Success',
            text: 'Account main currency has been changed',
            icon: 'success',
            customClass: {
                confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
    }

    const onSubmit = async () => {
        try {
            const result = await changeMainCurrency({
                currency: selectedCurrency.label
            })
            if (result.error) {
                setError('currency', {
                    type: 'manual',
                    message: result.error.data.message
                })
            } else {
                setOpenModal(false)
                refetch()
                handleSuccess()
            }
        } catch (error) {
            Object.keys(error.response.data.errors).forEach((key) => {
                setError(key, {
                    type: 'manual',
                    message: error.response.data.message
                })
            })
        }
    }

    return (
        <>
            <div className='py-2 mb-2'>
                <div className='row justify-content-between'>
                    <div className='col-lg-5 col-xl-5'>
                        <div className='d-flex'>
                            <i className='icofont-coins fs-5 text-primary'></i>
                            <div className='d-flex flex-column px-2'>
                                <span className='fw-bold'>Account Currency</span>
                                <span className='text-muted small'>This is your account's main currency. All new transactions will be executed using the wallet you have selected.</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-xl-3 d-flex align-items-center'>
                        <div className='d-flex align-items-center'>
                            {isLoading ? (
                                <>Loading...</>
                            ) : (
                                <>
                                    <img
                                        style={{ width: '20px', marginRight: '5px' }}
                                        src={require(`../../assets/images/coin/${mainCurrency}.png`)}
                                        alt={mainCurrency}
                                    />
                                    <span>{mainCurrency}</span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <button
                            type='button'
                            className='btn flex-fill btn-light-success py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end mx-2'
                            onClick={() => {
                                setOpenModal(true)
                            }}>
                            Change
                        </button>
                    </div>
                </div>
                <Modal
                    className='modal'
                    id='ChangeModal'
                    style={{ display: 'block' }}
                    show={openModal}
                    onHide={() => {
                        setOpenModal(true)
                    }}>
                    <div className='modal-content'>
                        <Modal.Header className='modal-header'>
                            <h5 className='modal-title'>Change Main Currency</h5>
                        </Modal.Header>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Body className='modal-body'>
                                <Form.Group className='mb-3 mt-3'>
                                    <label className='form-label'>Select main currency</label>
                                    <Select
                                        name='currency'
                                        options={currencies}
                                        value={selectedCurrency}
                                        onChange={setSelectedCurrency}
                                        isClearable={false}
                                        components={{
                                            Option: OptionComponent
                                        }}
                                    />
                                    {errors?.currency && (
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.currency.message}
                                        </div>
                                    )}
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer className='modal-footer'>
                                <button
                                    type='button'
                                    className='btn btn-secondary'
                                    data-bs-dismiss='modal'
                                    onClick={() => {
                                        setOpenModal(false)
                                        setSelectedCurrency('')
                                    }}>
                                    Cancel
                                </button>
                                <button type='submit' className='btn btn-primary'>
                                    Save
                                </button>
                            </Modal.Footer>
                        </Form>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default AccountCurrency
