import React from 'react'

import AutoTradingCard from './AutoTradingCard'
import AutoTradingChart from './AutoTradingChart'

import { useGetUserQuery } from '../../redux/services/authService'
import { useGetAutoTradingListQuery } from '../../redux/services/autoTradingService'
import AutoTradingSingleTrade from './AutroTradingSingleTrade'

const AutoTrading = () => {
    const { currentData: userData } = useGetUserQuery()
    const { currentData: autoTradingData, refetch: refetchTradinglist } = useGetAutoTradingListQuery()

    return (
        <div className='container-xxl bs-container-reset pd-left-xsmall'>
            <div className='row g-3 mb-3'>
                <div className='col-xxl-8 order-2 order-xxl-1'>
                    <AutoTradingChart />
                </div>
                <div className='col-xxl-4 order-1'>
                    <AutoTradingCard mainFiatCurrency={userData?.user?.main_fiat_currency} />
                </div>
            </div>
            <div className='row g-3 mb-3'>
                <div className='col-xxl-8'>
                    {autoTradingData?.data.length === 0 && (
                        <>
                            <div className='d-flex justify-content-center'>No started trades...</div>
                        </>
                    )}
                    {autoTradingData?.data &&
                        autoTradingData.data.map((trade) => {
                            return (
                                <AutoTradingSingleTrade
                                    key={`single-trade-${trade.id}`}
                                    tradeId={trade.id}
                                    trade={trade}
                                    refetchTradinglist={refetchTradinglist}
                                />
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export default AutoTrading
