import React, {useEffect, useState} from 'react'

import Select, { components } from 'react-select'
import {Controller, useForm} from 'react-hook-form'
import { Form, Modal } from 'react-bootstrap'

import { useGetUserQuery } from '../../redux/services/authService'
import {
    useChangeCopyTradingFeeMutation,
    useChangeMainCurrencyMutation,
    useGetCopyTradingFeeQuery
} from '../../redux/services/userService'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
const MySwal = withReactContent(Swal)

const CopyTradingSettings = () => {
    const [openModal, setOpenModal] = useState(false)

    const { currentData: copyTradingFee, isLoading, refetch } = useGetCopyTradingFeeQuery(
        {}, { refetchOnMountOrArgChange: true }
    )
    const [changeCopyTradingFee] = useChangeCopyTradingFeeMutation()

    const MINIMUM_FEE = 0;
    const MAXIMUM_FEE = 70;
    const FEE_STEP = 0.5;

    const defaultValues = {
        amount: '0'
    }

    const FormSchema = yup.object().shape({
        amount: yup
            .number()
            .required('Fee is required')
            .min(MINIMUM_FEE, `Minimum fee is ${MINIMUM_FEE}%`)
            .max(MAXIMUM_FEE, `Maximum fee is ${MAXIMUM_FEE}%`)
            .typeError('Fee must be a number'),
    });

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
        setValue
    } = useForm({ defaultValues, resolver: yupResolver(FormSchema) })

    useEffect(() => {

        if (copyTradingFee?.data) {
            setValue('amount', copyTradingFee?.data?.fee_percent ? String(copyTradingFee?.data?.fee_percent): '0')
        }
    }, [changeCopyTradingFee, copyTradingFee?.data, setValue])


    const handleSuccess = () => {
        return MySwal.fire({
            title: 'Success',
            text: 'The fee has been changed. The new fee will be applied to all new positions.',
            icon: 'success',
            customClass: {
                confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
    }

    const showAmountError = () => {
        setError('amount', {
            type: 'manual',
            message: 'Invalid fee amount.'
        })
    }

    const onSubmit = async (data) => {
        try {
            const result = await changeCopyTradingFee({
                fee_percent: data.amount
            })
            if (result.error) {
                showAmountError()
            } else {
                setOpenModal(false)
                refetch()
                await handleSuccess()
            }
        } catch (error) {
            showAmountError()
        }
    }

    return (
        <>
            <div className='py-2 mb-2'>
                <div className='row justify-content-between'>
                    <div className='col-lg-5 col-xl-5'>
                        <div className='d-flex'>
                            <i className='icofont-copy fs-5 text-primary'></i>
                            <div className='d-flex flex-column px-2'>
                                <span className='fw-bold'>Copy Trading Fee</span>
                                <span className='text-muted small'>Specify the fee amount you wish to charge on winning positions for all copiers.</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-xl-3 d-flex align-items-center'>
                        <div className='d-flex align-items-center'>
                            {isLoading ? (
                                <>Loading...</>
                            ) : (
                                <>
                                    {copyTradingFee?.data?.fee_percent && copyTradingFee?.data?.fee_percent > 0 ? (
                                        <>
                                            <div className="d-flex align-items-center">
                                                <i className="icofont-check-circled fs-5 text-success"></i>
                                                <span className="px-1">{copyTradingFee?.data?.fee_percent}%</span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <i className="icofont-close-circled fs-5 text-danger"></i>
                                            <span className="px-1">No fee</span>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <button
                            type='button'
                            className='btn flex-fill btn-light-success py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end mx-2'
                            onClick={() => {
                                setOpenModal(true)
                            }}>
                            Change
                        </button>
                    </div>
                </div>
                <Modal
                    className='modal'
                    id='ChangeModal'
                    style={{ display: 'block' }}
                    show={openModal}
                    onHide={() => {
                        setOpenModal(true)
                    }}>
                    <div className='modal-content'>
                        <Modal.Header className='modal-header'>
                            <h5 className='modal-title'>Copy Trading Fee</h5>
                        </Modal.Header>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Body className='modal-body'>
                                <Form.Group className='mb-3 mt-3' controlId='amount'>
                                    <label className='form-label'>Fee (%)</label>
                                    <Controller
                                        control={control}
                                        name='amount'
                                        render={({field: {onChange, value}}) => (
                                            <Form.Control
                                                onChange={onChange}
                                                value={value.replace(/,/g, '.')}
                                                isInvalid={errors.amount}
                                                placeholder='Enter the fee percent'
                                                type='number'
                                                min={0}
                                                max={MAXIMUM_FEE}
                                                step={FEE_STEP}
                                            />
                                        )}
                                    />
                                    <div>
                                        <div className='info-wrapper'>
                                            <span className='text-muted'>Charges are applied at a minimum rate of {MINIMUM_FEE}%, increasing in {FEE_STEP}% increments, up to a maximum charge of {MAXIMUM_FEE}%.</span>
                                        </div>
                                    </div>
                                    {errors?.amount && (
                                        <Form.Control.Feedback
                                            type='invalid'>{errors.amount.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer className='modal-footer'>
                                <button
                                    type='button'
                                    className='btn btn-secondary'
                                    data-bs-dismiss='modal'
                                    onClick={() => {
                                        setOpenModal(false)
                                    }}>
                                    Cancel
                                </button>
                                <button type='submit' className='btn btn-primary'>
                                    Save
                                </button>
                            </Modal.Footer>
                        </Form>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default CopyTradingSettings
