import React from 'react'
import ActiveCopyTrading from "./ActiveCopyTrading";
import TradersList from "./TradersList";
import {
    useGetCopyTradingFollowedTradersQuery,
    useGetCopyTradingTradersQuery
} from "../../redux/services/copyTradingService";
import { useGetUserQuery } from '../../redux/services/authService';

const CopyTrading = () => {
    const {currentData: followedTraders, refetch: refetchFollowedTraders} = useGetCopyTradingFollowedTradersQuery()
    const {currentData: traders, refetch: refetchTraders} = useGetCopyTradingTradersQuery()
    const {currentData: userData} = useGetUserQuery();
    const refreshData = () => {
        refetchFollowedTraders()
        refetchTraders();
    }

    const userFeatures = userData?.features || [];

    if (!userFeatures.includes('copy-trading')) {
        return (
            <div className='container-xxl bs-container-reset pd-left-xsmall'>
                <div className='row g-3 mb-3'>
                    <div className='col-xxl-12'>
                        <div className='card'>
                            <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                                <h6 className='mb-0 fw-bold '>Coming soon</h6>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='container-xxl bs-container-reset pd-left-xsmall'>
            <div className='row g-3 mb-3'>
                <div className='col-xxl-12'>
                    <div className='card'>
                        <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                            <h6 className='mb-0 fw-bold '>Active copy trading</h6>
                        </div>
                        <ActiveCopyTrading followedTraders={followedTraders} copyTradingCancelled={refreshData}/>
                    </div>
                </div>
                <div className='col-xxl-12'>
                    <div className='card'>
                        <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                            <h6 className='mb-0 fw-bold '>Top traders</h6>
                        </div>
                        <TradersList followedTraders={followedTraders} traders={traders} copyTradingOpened={refreshData}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CopyTrading
