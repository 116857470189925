import React from 'react';
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap';

const facebookLogo = require('../../../assets/images/social-media/facebook-96.png');
const instagramLogo = require('../../../assets/images/social-media/instagram-96.png');
const twitterXLogo = require('../../../assets/images/social-media/twitterx-96.png');
const linkedinLogo = require('../../../assets/images/social-media/linkedin-96.png');

const ShareModal = ({ show, handleClose, postUrl }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(postUrl);
    alert('Link copied to clipboard!');
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Share this post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-around mb-3">
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${postUrl}`} target="_blank" rel="noopener noreferrer">
            <img src={facebookLogo} width={48} height={48} />
          </a>
          <a href={`https://twitter.com/intent/tweet?url=${postUrl}`} target="_blank" rel="noopener noreferrer">
          <img src={twitterXLogo} width={48} height={48} />
          </a>
          <a href={`https://www.linkedin.com/shareArticle?url=${postUrl}`} target="_blank" rel="noopener noreferrer">
            <img src={linkedinLogo} width={48} height={48} />
          </a>
        </div>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Post URL"
            aria-label="Post URL"
            value={postUrl}
            readOnly
          />
          <Button variant="outline-secondary" onClick={copyToClipboard}>
            Copy
          </Button>
        </InputGroup>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
