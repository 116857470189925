import React, {useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Select, {components} from 'react-select'
import toast from 'react-hot-toast'

import Swal from 'sweetalert2'

import {CFDformSchema} from '../../helpers/forms/schema/base'
import {yupResolver} from '@hookform/resolvers/yup'

import {useGetCFDOpenPositionCalculationQuery, useOpenPositionsMutation} from '../../redux/services/cfdService'
import {cryptocurrenciesData} from '../../helpers/cryptocurrency'
import {useGetUserCryptoBalanceQuery, useGetUserFiatBalanceQuery} from '../../redux/services/userService'
import LimitAmountSwitch from "./components/LimitAmountSwitch";
import {useSub} from "../../helpers/usePubSub";

import './LongTermTableCFD.css'
import {isStopLossPriceInvalid, isTakeProfitPriceInvalid} from "./helpers/StopLoss";


const LongTermTableCFD = (props) => {
    const {refetchOpenPositions, cfdOpenData, cfdClosedDAta} = props

    const {currentData: cryptoBalance} = useGetUserCryptoBalanceQuery()
    const {currentData: fiatBalance, refetch: refetchUserFiatBalance} = useGetUserFiatBalanceQuery(
        {},
        {refetchOnMountOrArgChange: true}
    )


    const mainFiatCurrency = props.mainFiatCurrency;

    const leverageValueToObj = (value) => ({
        value: value,
        label: `x${value}`
    })

    const leveragesSelectData = props.leverageValues.map((value) => leverageValueToObj(value));

    const updatedCryptoData = cryptocurrenciesData.map((obj) => ({
        value: `${obj.value}-${mainFiatCurrency}`,
        label: `${obj.value} - ${mainFiatCurrency}`,
        currency: `${obj.value}`,
        currency_full_name: `${obj.label}`,
        to_currency: `${mainFiatCurrency}`,
        currency_icon: (
            <img src={require(`../../assets/images/coin/${obj.value}.png`)} alt='BTC'
                 className='img-fluid img-pair mx-1'/>
        ),
        to_currency_icon: (
            <img
                src={require(`../../assets/images/coin/${mainFiatCurrency}.png`)}
                alt={mainFiatCurrency}
                className='img-fluid img-pair mx-1'
            />
        )
    }))

    const [leverageLabel, setLeverageLabel] = useState(leveragesSelectData[0])
    const [selectedDirection, setSelectedDirection] = useState('long')
    const [selectedPair, setSelectedPair] = useState(updatedCryptoData.find((pair) => pair.value === 'BTC-USD'))

    const getOpenClosedPositionsLength = cfdOpenData?.data.length + cfdClosedDAta?.data.length || 0

    const [openPosition] = useOpenPositionsMutation()

    const [takeProfitMode, setTakeProfitMode] = useState('Amount')
    const [stopLossMode, setStopLossMode] = useState('Amount')
    const [stopLossLongTargetPrice, setStopLossLongTargetPrice] = useState(0)
    const [takeProfitLongTargetPrice, setTakeProfitLongTargetPrice] = useState(null)
    const [lastPricing, setLastPricing] = useState(null)
    const [stopLossPrice, setStopLossPrice] = useState(null)
    const [takeProfitPrice, setTakeProfitPrice] = useState(null)



    const defaultValues = {
        amount: '100',
        leverage: leverageLabel,
    }
    const [amount, setAmount] = useState(parseFloat(defaultValues.amount))

    const shouldFetchCalculation = () => {
        return selectedPair && selectedDirection && amount > 0 && (stopLossMode === 'Limit' || takeProfitMode === 'Limit')
    }

    const {currentData: cfdOpenCalculation, refetch: refetchCalculation} = useGetCFDOpenPositionCalculationQuery({
        margin: amount,
        asset_symbol: selectedPair?.value,
        direction: selectedDirection,
        leverage: 1
    }, {skip: !shouldFetchCalculation()})

    const {
        control,
        handleSubmit,
        setError,
        formState: {errors},
        setValue,
        getValues
    } = useForm({defaultValues, resolver: yupResolver(CFDformSchema)})

    const isTakeProfitPriceIncorrect = (takeProfitPrice, takeProfitLongTargetPrice) => {
        return isTakeProfitPriceInvalid(takeProfitPrice, takeProfitLongTargetPrice, selectedDirection);
    }

    const isStopLossPriceIncorrect = (stopLossPrice, stopLossLongTargetPrice) => {
        return isStopLossPriceInvalid(stopLossPrice, stopLossLongTargetPrice, selectedDirection);
    }

    useEffect(() => {
        if (cfdOpenCalculation?.data) {
            setStopLossLongTargetPrice(cfdOpenCalculation.data.stopLossTargetPrice);
            setTakeProfitLongTargetPrice(cfdOpenCalculation.data.takeProfitTargetPrice);

            if (isTakeProfitPriceIncorrect(takeProfitPrice, cfdOpenCalculation.data.takeProfitTargetPrice)) {
                setTakeProfitPrice(parseFloat(cfdOpenCalculation.data.takeProfitTargetPrice).toFixed(2));
            }

            if (isStopLossPriceIncorrect(stopLossPrice, cfdOpenCalculation.data.stopLossTargetPrice)) {
                setStopLossPrice(parseFloat(cfdOpenCalculation.data.stopLossTargetPrice).toFixed(2));
            }



        }
    }, [cfdOpenCalculation]);


    const PairOptionComponent = ({data, ...props}) => {
        return (
            <components.Option {...props}>
                {data.currency_icon}
                {data.currency}
                {'  -'}
                {data.to_currency_icon}
                {data.to_currency}
            </components.Option>
        )
    }


    const LeverageSelectItem = ({data, ...props}) => {
        return (
            <components.Option {...props}>
                {data.label}
            </components.Option>
        )
    }

    const changeAmount = (amount) => {
        setValue('amount', amount)
        setAmount(amount)
    }

    useSub('pricesV1', (prices) => {
        setLastPricing(prices)
    });

    useEffect(() => {
        if (shouldFetchCalculation()) {
            refetchCalculation();
        }
    }, [selectedPair, selectedDirection, amount, stopLossMode, takeProfitMode, lastPricing]);

    const stopLossPriceChanged = (e) => {
        setValue('stop_loss_price', String(e.target.value))
        setStopLossPrice(parseFloat(e.target.value))
    }

    const takeProfitPriceChanged = (e) => {
        if (e.target.value === '') {
            setTakeProfitPrice(null)
            return;
        }
        setTakeProfitPrice(parseFloat(e.target.value))
    }

    const onSubmit = async (data) => {
        const confirmationOptions = {
            title: 'Are you sure?',
            text: `You selected: ${data.amount} ${cryptoBalance.data.total_value_currency} on ${leverageLabel.label} leverage.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `${selectedDirection === 'long' ? 'BUY/LONG' : 'SELL/SHORT'}`,
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: `btn py-2 text-uppercase ${
                    selectedDirection === 'long' ? 'btn-light-success' : 'btn-light-danger'
                }`,
                cancelButton: 'btn py-2 text-uppercase btn-outline-secondary ms-1'
            },
            buttonsStyling: false
        }

        if (getOpenClosedPositionsLength === 0) {
            const leverageOptions = {
                title: 'High Leverage Trading',
                showCancelButton: true,
                html: `
              <p style='color: #6b7078; font-size: 1rem'>
              <i class='icofont-warning' style='color: #ffc047'></i>
              <span style='color: #ffc047'>Reminder:</span> High-leverage trading carries a substantial risk and the possibility of both significant profits and losses. Past gains are not indicative of future returns. All of your margin balance may be liquidated in the event of extreme price movements. The information here should not be regarded as financial or investment advice from Coiniy. All trading strategies are used at your discretion and at your own risk. Coiniy will not be liable to you for any loss that might arise from your use of High leverage.
              </p>`,
                confirmButtonText: 'CONTINUE',
                cancelButtonText: 'No, thanks',
                customClass: {
                    confirmButton: 'btn py-2 text-uppercase btn-primary',
                    cancelButton: 'btn py-2 text-uppercase btn-outline-secondary ms-1'
                },
                buttonsStyling: false
            }

            Swal.fire(leverageOptions).then(async (result) => {
                if (!result.value) {
                    return null
                }
                Swal.fire(confirmationOptions).then(async (result) => {
                    if (!result.value) {
                        return null
                    }

                    const stopLossData = {
                        stop_loss: data.stop_loss ? Number(data.stop_loss) : null,
                        stop_loss_price: stopLossPrice ? Number(stopLossPrice) : null
                    }

                    if (stopLossMode === 'Limit') {
                        stopLossData.stop_loss = null
                    } else {
                        stopLossData.stop_loss_price = null
                    }

                    const takeProfitData = {
                        take_profit: data.take_profit ? Number(data.take_profit) : null,
                        take_profit_price: takeProfitPrice ? Number(takeProfitPrice) : null
                    }

                    if (takeProfitMode === 'Limit') {
                        takeProfitData.take_profit = null
                    } else {
                        takeProfitData.take_profit_price = null
                    }

                    try {
                        const result = await openPosition({
                            asset_symbol: selectedPair.value,
                            margin: data.amount,
                            direction: selectedDirection,
                            leverage: Number(leverageLabel.value),
                            ...stopLossData,
                            ...takeProfitData
                        })
                        setError('stop_loss', {
                            type: 'manual',
                            message: 'invalid'
                        })
                        if (result.error) {
                            setError('apiError', {
                                type: 'manual',
                                message: result.error.data.message
                            })
                        } else {
                            toast.success('Position opened!')
                            refetchOpenPositions()
                            refetchUserFiatBalance()
                        }
                    } catch (err) {
                        if (err.response?.data?.errors) {
                            setError('amount', {
                                type: 'manual',
                                message: err.response.data.message
                            })
                        } else {
                            setError('amount', {
                                type: 'manual',
                                message: 'Something went wrong. Please try again later.'
                            })
                        }
                    }
                })
            })
        } else {
            Swal.fire(confirmationOptions).then(async (result) => {
                if (!result.value) {
                    return null
                }

                const stopLossData = {
                    stop_loss: data.stop_loss ? Number(data.stop_loss) : null,
                    stop_loss_price: stopLossPrice ? Number(stopLossPrice) : null
                }

                if (stopLossMode === 'Limit') {
                    stopLossData.stop_loss = null
                } else {
                    stopLossData.stop_loss_price = null
                }

                const takeProfitData = {
                    take_profit: data.take_profit ? Number(data.take_profit) : null,
                    take_profit_price: takeProfitPrice ? Number(takeProfitPrice) : null
                }

                if (takeProfitMode === 'Limit') {
                    takeProfitData.take_profit = null
                } else {
                    takeProfitData.take_profit_price = null
                }

                try {
                    const result = await openPosition({
                        asset_symbol: selectedPair?.value,
                        margin: data.amount,
                        direction: selectedDirection,
                        leverage: Number(leverageLabel.value),
                        ...stopLossData,
                        ...takeProfitData
                    })

                    if (result?.error?.data?.errors) {
                        Object.keys(
                            result?.error?.data?.errors,
                        ).forEach((key) => {
                                setError(key, {
                                    type: 'manual',
                                    message: result?.error?.data?.errors[key][0]
                                })
                            }
                        )
                    }
                    if (result.error) {
                        setError('apiError', {
                            type: 'manual',
                            message: result.error.data.message
                        })
                    } else {
                        toast.success('Position opened!')
                        refetchOpenPositions()
                        refetchUserFiatBalance()
                    }
                } catch (err) {
                    setError('amount', {
                        type: 'manual',
                        message: 'Something went wrong. Please try again later.'
                    })
                }
            })
        }
    }

    const onTakeProfitPriceInputBlur = () => {
        if (isTakeProfitPriceIncorrect(takeProfitPrice, takeProfitLongTargetPrice)) {
            setTakeProfitPrice(parseFloat(takeProfitLongTargetPrice).toFixed(2));
        }
    }

    const onStopLossPriceInputBlur = () => {
        if (isStopLossPriceIncorrect(stopLossPrice, stopLossLongTargetPrice)) {
            setStopLossPrice(parseFloat(stopLossLongTargetPrice).toFixed(2));
        }
    }

    return (
        <div className='card cfd-card'>
            <div className='card-body'>
                <div className='col-md-12'>
                    <div className='cfd-card-header'>
                        {selectedPair && (
                            <div className='cfd-header-wrapper'>
                                <div className='image-wrapper'>
                                    <img
                                        src={require(`../../assets/images/coin/${selectedPair.currency}.png`)}
                                        alt={selectedPair.currency}
                                        className='img-fluid avatar mx-1'
                                    />
                                </div>
                                <div>
                                    <div className='info-wrapper'>
                                        <span>{selectedPair.currency}</span>
                                        <span className='text-muted name'>{selectedPair.currency_full_name}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className="btn-group mt-3 justify-content-center d-flex" role="group">
                                <button onClick={() => setSelectedDirection('long')} type="button"
                                        className={`btn p-3 ${selectedDirection === 'long' ? 'btn-success direction-active' : 'btn-outline-success'}`}>BUY/LONG
                                </button>
                                <button onClick={() => setSelectedDirection('short')} type="button"
                                        className={`btn p-3 ${selectedDirection === 'short' ? 'btn-danger direction-active' : 'btn-outline-danger'}`}>SELL/SHORT
                                </button>
                            </div>
                            <Form.Group className='mt-3' controlId='name'>
                                <label className='form-label'>
                                    Amount {cryptoBalance ? `(${cryptoBalance.data.total_value_currency})` : ''}
                                </label>
                                <Controller
                                    control={control}
                                    name='amount'
                                    render={({field: {onChange, value}}) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value.replace(/,/g, '.')}
                                            isInvalid={errors.amount}
                                            placeholder='Amount'
                                            type='text'
                                        />
                                    )}
                                />
                                {errors?.amount && (
                                    <Form.Control.Feedback
                                        type='invalid'>{errors.amount.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                width: '100%'
                            }}>
                                <span className='text-muted'>
                                    Cash Available:
                                    {fiatBalance?.data?.balance ? (
                                        <>
                                            <span
                                                onClick={() => changeAmount(parseFloat(fiatBalance?.data?.balance).toFixed(2))}
                                                className='text-secondary a-link'>
                                                {' '}{parseFloat(fiatBalance?.data?.balance).toFixed(2)}{' '}
                                                {fiatBalance.data.currency}
                                            </span>
                                        </>
                                    ) : (
                                        <>{' '}0 {fiatBalance?.data?.currency || 'USD'}</>
                                    )}
                                </span>
                            </div>
                            <Form.Group className='mb-3'>
                                <label className='form-label'>Unit</label>
                                <Select
                                    name='selectedCurrency'
                                    options={updatedCryptoData}
                                    value={selectedPair ? {
                                        value: selectedPair?.value,
                                        label: selectedPair?.label
                                    } : null}
                                    onChange={setSelectedPair}
                                    components={{
                                        Option: PairOptionComponent
                                    }}
                                />
                                {errors?.selectedCurrency && (
                                    <div className='invalid-feedback' style={{display: 'block'}}>
                                        {errors.selectedCurrency.message}
                                    </div>
                                )}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <label className='form-label'>Leverage</label>
                                <Select
                                    onChange={setLeverageLabel}
                                    name='leverage'
                                    options={leveragesSelectData}
                                    defaultValue={leveragesSelectData[0]}
                                    isClearable={false}
                                    components={{
                                        Option: LeverageSelectItem
                                    }}
                                />
                                {errors?.leverage && (
                                    <div className='invalid-feedback' style={{display: 'block'}}>
                                        {errors?.leverage?.message}
                                    </div>
                                )}
                            </Form.Group>
                            <div className="mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <label className="form-label">Stop Loss</label>
                                    <LimitAmountSwitch onSwitch={value => setStopLossMode(value)}/>
                                </div>
                                {stopLossMode === 'Amount' && (
                                    <div>
                                        <div className="input-group ">
                                            <Controller
                                                control={control}
                                                name='stop_loss'
                                                render={({field: {onChange, value}}) => (
                                                    <Form.Control
                                                        onChange={e => {
                                                            let inputValue = e.target.value;
                                                            if (inputValue && inputValue[0] !== '-') {
                                                                inputValue = '-' + inputValue;
                                                            }
                                                            onChange(inputValue.replace(/,/g, '.'));
                                                        }}
                                                        value={value ? value.replace(/,/g, '.') : ''}
                                                        isInvalid={errors.stop_loss}
                                                        placeholder='Stop loss'
                                                        type='text'
                                                    />
                                                )}
                                            />
                                            <span
                                                className="input-group-text">{cryptoBalance ? cryptoBalance.data.total_value_currency : 'USD'}</span>

                                            {errors?.stop_loss && (
                                                <Form.Control.Feedback
                                                    type='invalid'>{errors.stop_loss.message}</Form.Control.Feedback>
                                            )}
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            width: '100%'
                                        }}>
                                                <span
                                                    className="text-muted">Min: <span
                                                    onClick={() => setValue('stop_loss', '-0.01')}
                                                    className="text-secondary a-link">-0.01 {mainFiatCurrency}</span></span>
                                        </div>
                                    </div>
                                )}
                                {stopLossMode === 'Limit' && (
                                    <div>
                                        <div className="input-group">
                                            <Controller
                                                control={control}
                                                name='stop_loss_price'
                                                render={({field: {onChange, value}}) => (
                                                    <Form.Control
                                                        onChange={stopLossPriceChanged}
                                                        value={stopLossPrice ? stopLossPrice : ''}
                                                        onBlur={() => onStopLossPriceInputBlur()}
                                                        isInvalid={errors.stop_loss_price}
                                                        placeholder='Stop loss price'
                                                        type='number'
                                                    />
                                                )}
                                            />
                                            <span
                                                className="input-group-text">{cryptoBalance ? cryptoBalance.data.total_value_currency : 'USD'}</span>
                                            {errors?.stop_loss_price && (
                                                <Form.Control.Feedback
                                                    type='invalid'>{errors.stop_loss_price.message}</Form.Control.Feedback>
                                            )}
                                        </div>
                                        {stopLossLongTargetPrice ? (
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                width: '100%'
                                            }}>
                                                <span
                                                    className="text-muted">{selectedDirection === 'long' ? 'Max' : 'Min'}: <span
                                                    onClick={() => setStopLossPrice(stopLossLongTargetPrice.toFixed(2))}
                                                    className="text-secondary a-link">{stopLossLongTargetPrice.toFixed(2)} {mainFiatCurrency}</span></span>
                                            </div>
                                        ) : (<></>)}
                                    </div>
                                )}
                            </div>
                            <div className="mb-1">
                                <div className="d-flex align-items-center justify-content-between">
                                    <label className="form-label">Take Profit</label>
                                    <LimitAmountSwitch onSwitch={value => setTakeProfitMode(value)}/>
                                </div>
                                {takeProfitMode === 'Amount' && (
                                    <div>
                                        <div className="input-group">
                                            <Controller
                                                control={control}
                                                name='take_profit'
                                                render={({field: {onChange, value}}) => (
                                                    <Form.Control
                                                        onChange={onChange}
                                                        value={value ? value.replace(/,/g, '.') : ''}
                                                        isInvalid={errors.take_profit}
                                                        placeholder='Take profit'
                                                        type='text'
                                                    />
                                                )}
                                            />
                                            <span
                                                className="input-group-text">{cryptoBalance ? cryptoBalance.data.total_value_currency : 'USD'}</span>
                                            {errors?.take_profit && (
                                                <Form.Control.Feedback
                                                    type='invalid'>{errors.take_profit.message}</Form.Control.Feedback>
                                            )}
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            width: '100%'
                                        }}>
                                                <span
                                                    className="text-muted">Min: <span
                                                    onClick={() => setValue('take_profit', '0.01')}
                                                    className="text-secondary a-link">1 {mainFiatCurrency}</span></span>
                                        </div>
                                    </div>
                                )}
                                {takeProfitMode === 'Limit' && (
                                    <div>
                                        <div className="input-group">
                                            <Controller
                                                control={control}
                                                name='take_profit_price'
                                                render={({field: {onChange, value}}) => (
                                                    <Form.Control
                                                        onChange={takeProfitPriceChanged}
                                                        onBlur={() => onTakeProfitPriceInputBlur()}
                                                        value={takeProfitPrice ? takeProfitPrice : ''}
                                                        isInvalid={errors.take_profit_price}
                                                        placeholder='Take profit price'
                                                        type='number'
                                                    />
                                                )}
                                            />
                                            <span
                                                className="input-group-text">{cryptoBalance ? cryptoBalance.data.total_value_currency : 'USD'}</span>
                                            {errors?.take_profit_price && (
                                                <Form.Control.Feedback
                                                    type='invalid'>{errors.take_profit_price.message}</Form.Control.Feedback>
                                            )}
                                        </div>
                                        {takeProfitLongTargetPrice && (
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                width: '100%'
                                            }}>
                                                <span
                                                    className="text-muted">{selectedDirection === 'long' ? 'Min' : 'Max'}: <span
                                                    onClick={() => setTakeProfitPrice(takeProfitLongTargetPrice.toFixed(2))}
                                                    className="text-secondary a-link">{takeProfitLongTargetPrice.toFixed(2)} {mainFiatCurrency}</span></span>
                                            </div>
                                        )}

                                    </div>
                                )}
                            </div>
                            <div className='mb-1 mt-3 d-flex justify-content-between '>
                                <button
                                    type="submit"
                                    className={`btn flex-fill ${selectedDirection === 'long' ? 'btn-light-success' : 'btn-light-danger'} py-2 fs-5 px-5 w-100 btn btn-primary`}>Open {selectedDirection === 'long' ? 'Long' : 'Short'} Position
                                </button>
                            </div>
                            {errors?.apiError && (
                                <div className='invalid-feedback mb-3 text-center' style={{display: 'block'}}>
                                    {errors.apiError.message}
                                </div>
                            )}
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default LongTermTableCFD
