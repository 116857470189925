import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import EmptyTable from '../EmptyStates/EmptyTable'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useDeletePositionMutation } from '../../redux/services/cfdService'

import { PriceComponent } from '../../helpers/utils'
import {Tooltip} from "react-tooltip";
import EditOrderModal from "./components/EditOrderModal";

const MySwal = withReactContent(Swal)
const LongTermTableOpen = (props) => {
    const { refetchClosedPositions, refetchOpenPositions, cfdOpenData, refetchUserFiatBalance, userEnabledFeatures } = props

    const [currentSocketPrices, setCurrentSocketPrices] = useState('')
    const [editedPosition, setEditedPosition] = useState(null)

    const [deleteActivePosition] = useDeletePositionMutation()

    const socketData = (data) => {
        setCurrentSocketPrices(data)
    }

    const refreshCurrentPositionsData = () => {
        refetchClosedPositions()
        refetchOpenPositions()
        refetchUserFiatBalance()
    }

    useEffect(() => {
        const refetchTime = setTimeout(() => {
            refetchOpenPositions()
        }, 1000)

        return () => clearTimeout(refetchTime)
        // eslint-disable-next-line
    }, [currentSocketPrices])

    const renderLeverage = (row) => {
        return (
            <>
                <Tooltip id={`leverage-tooltip-${row.id}`} />
                <span
                    className="text-muted d-block m-1"
                    data-tooltip-id={`leverage-tooltip-${row.id}`}
                    data-tooltip-content='Leverage'>{parseFloat(row.leverage).toFixed(0)} X</span>
            </>
        )
    }

    const toggleEditOrderModal = (row) => {
        setEditedPosition(null)
        refetchOpenPositions()
    }

    const redenerLiquidationPrice = (row) => {
        return (
            <>
                <Tooltip id={`liquidation-price-tooltip-${row.id}`} />
                <span
                    className="text-muted d-block"
                    data-tooltip-id={`leverage-tooltip-${row.id}`}
                    data-tooltip-content='Liqidation price - the price at which the position will be liquidated'>
                    Liq. price: ~ {parseFloat(row.liquidation_price).toFixed(2)} {row.quote_currency}
                </span>
            </>
        )
    }

    const handleDeletePosition = async (selectedPosition) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: `Once you cancel this position, the action cannot be undone.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Close position',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false
        }).then(async function (result) {
            if (!result.value) {
                return null
            }
            const responseResult = await deleteActivePosition(selectedPosition.id)
            if (!responseResult.error) {
                MySwal.fire({
                    icon: 'success',
                    title: 'Closed',
                    text: 'Selected position has been closed',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                })
                refreshCurrentPositionsData()
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `${responseResult.error.data.message}`,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                })
            }
        })
    }

    const columns = () => {
        return [
            {
                name: 'Pair',
                selector: (row) => row.asset,
                cell: (row) => {
                    const pairValue = row.asset
                    const pairValues = pairValue.split('-')
                    return (
                        <div className='price-block'>
                            <span className='fs-6 fw-bold pair-wrapper'>
                                <img
                                    src={require(`../../assets/images/coin/${pairValues[0]}.png`)}
                                    alt={pairValues[0]}
                                    className='img-fluid avatar pair-img'
                                    style={{ marginRight: '0.25rem' }}
                                />
                                {pairValues[0]} -
                                <img
                                    src={require(`../../assets/images/coin/${pairValues[1]}.png`)}
                                    alt={pairValues[1]}
                                    className='img-fluid avatar mx-1 pair-img'
                                />
                                {pairValues[1]}
                            </span>
                        </div>
                    )
                },
                sortable: true,
                minWidth: '180px',
                maxWidth: '220px'
            },
            {
                name: 'Position size',
                selector: (row) => row.position_size,
                cell: (row) => {
                    const pairValue = row.asset
                    const pairValues = pairValue.split('-')
                    return (
                        <>
                            {parseFloat(row.position_size).toFixed(10)} {pairValues[0]}
                        </>
                    )
                },
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Open date',
                selector: (row) => row.position_opened_at,
                cell: (row) => <>{row.position_opened_at}</>,
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Entry price',
                selector: (row) => row.entry_price,
                cell: (row) => (
                    <>
                        {parseFloat(row.entry_price).toFixed(2)} {row.quote_currency}
                    </>
                ),
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Direction',
                selector: (row) => row.direction,
                cell: (row) => {
                    if (row.direction === 'long') {
                        return <span className='color-price-up'>Buy / Long</span>
                    } else if (row.direction === 'short') {
                        return <span className='color-price-down'>Sell / Short</span>
                    } else {
                        return <>{row.direction}</>
                    }
                },
                sortable: true,
                minWidth: '100px',
                maxWidth: '100px'
            },
            {
                name: 'Margin',
                selector: (row) => row.margin,
                cell: (row) => {
                    return (
                        <>
                            {renderLeverage(row)}
                            {parseFloat(row.margin).toFixed(2)} {row.quote_currency}
                        </>
                    )
                },
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Current price',
                selector: (row) => row.current_price,
                cell: (row) => (
                    <>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span className="d-block">{parseFloat(row.current_price).toFixed(2)} {row.quote_currency}</span>
                            {redenerLiquidationPrice(row)}
                        </div>
                    </>
                ),
                sortable: true,
                minWidth: '200px'
            },
            {
                name: 'Stop loss',
                selector: (row) => row.stop_loss,
                cell: (row) => {
                    if (row.stop_loss_price) {
                        return (
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span className="text-muted d-block">Limit:</span>
                                <span className='color-price-down'>{parseFloat(row.stop_loss_price).toFixed(2)} {row.quote_currency}</span>
                            </div>
                        )
                    }

                    if (row.stop_loss) {
                        return (
                            <>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span className="text-muted d-block">Amount:</span>
                                    <span
                                        className='color-price-down'>{parseFloat(row.stop_loss).toFixed(2)} {row.quote_currency}</span>
                                </div>
                            </>
                        );
                    }

                    return (
                        <>
                            -
                        </>
                    )
                },
                sortable: false,
                minWidth: '150px'
            },
            {
                name: 'Take profit',
                selector: (row) => row.take_profit,
                cell: (row) => {
                    if (row.take_profit_price) {
                        return (
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span className="text-muted d-block">Limit:</span>
                                <span className='color-price-up'>{parseFloat(row.take_profit_price).toFixed(2)} {row.quote_currency}</span>
                            </div>
                        )
                    }

                    if (row.take_profit) {
                        return (
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span className="text-muted d-block">Amount:</span>
                                <span className='color-price-up'>+{parseFloat(row.take_profit).toFixed(2)} {row.quote_currency}</span>
                            </div>
                        )
                    }

                    return (
                        <>
                            -
                        </>
                    )
                },
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Profit/loss',
                selector: (row) => row.profit_loss,
                cell: (row) => {
                    if (row.profit_loss < 0) {
                        return (
                            <>
                                <span className='color-price-down'>
                                    {parseFloat(row.profit_loss).toFixed(2)} {row.quote_currency}
                                </span>
                            </>
                        )
                    } else if (row.profit_loss === 0) {
                        return (
                            <>
                                <span>
                                    {parseFloat(row.profit_loss).toFixed(2)} {row.quote_currency}
                                </span>
                            </>
                        )
                    } else if (row.profit_loss > 0) {
                        return (
                            <>
                                <span className='color-price-up'>
                                    +{parseFloat(row.profit_loss).toFixed(2)} {row.quote_currency}
                                </span>
                            </>
                        )
                    } else {
                        return (
                            <>
                                {parseFloat(row.profit_loss).toFixed(2)} {row.quote_currency}
                            </>
                        )
                    }
                },
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Copy trading',
                selector: (row) => row.following_trader_name,
                cell: (row) => {
                    if (row.following_trader_name) {
                        return (
                            <>
                                <span className="badge bg-light text-dark mx-1">{row.following_trader_name}</span>
                            </>
                        )
                    }

                    return (
                        <>
                            -
                        </>
                    )
                },
                sortable: false,
                minWidth: '150px'
            },
            {
                name: 'Position',
                selector: (row) => 'Delete',
                sortable: true,
                minWidth: '150px',
                cell: (row) => {
                    if (row.following_trader_name) {
                        return (<>-</>);
                    }

                    return (
                        <div style={{display: 'flex', gap: '8px'}}>
                            <button type="button" className="btn btn-outline-danger btn-sm"
                                    onClick={() => handleDeletePosition(row)}>
                                Close
                            </button>
                            <button type="button" className="btn btn-outline-primary btn-sm"
                                    onClick={() => setEditedPosition(row)}>
                                Edit
                            </button>
                        </div>
                    );
                }
            }
        ]
    }

    return (
        <div className='row g-3 mb-3'>
            {editedPosition ? <EditOrderModal
                position={editedPosition}
                isModalOpen={editedPosition !== null}
                setIsModalOpen={() => toggleEditOrderModal()}
                userEnabledFeatures={userEnabledFeatures}
            /> : <></>}
            <PriceComponent getPricesData={socketData}/>
            <div className='col-lg-12 col-md-12'>
            <div id='DataTables_Table_11_wrapper' className='dataTables_wrapper dt-bootstrap5 no-footer'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <DataTable
                                title='Open Positions'
                                columns={columns()}
                                data={cfdOpenData?.data || []}
                                noDataComponent={EmptyTable()}
                                defaultSortField='title'
                                selectableRows={false}
                                highlightOnHover={true}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LongTermTableOpen
