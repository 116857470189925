import React from 'react'
import DataTable from 'react-data-table-component'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { formatDate } from '../../helpers/utils'
import {
    useDeleteStakingMutation,
    useGetStakingActiveQuery,
    useGetStakingAmountsQuery
} from '../../redux/services/stakingService'

const MySwal = withReactContent(Swal)

const StakingActiveAssets = () => {
    const [deleteActiveStaking] = useDeleteStakingMutation()

    const { currentData: stakingActiveAssets, refetch: refetchActiveData } = useGetStakingActiveQuery()
    const { refetch: refetchAmountsData } = useGetStakingAmountsQuery()

    const refreshCurrentStakingData = () => {
        refetchActiveData()
        refetchAmountsData()
    }

    const handleDeleteStaking = async (selectedStaking) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: `Once you cancel this position, the action cannot be undone. This means you will forgot your ${selectedStaking.coin} reward.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete staking',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false
        }).then(async function (result) {
            if (!result.value) {
                return null
            }
            const responseResult = await deleteActiveStaking(selectedStaking.id)
            if (!responseResult.error) {
                MySwal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Selected staking has been deleted',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                })
                refreshCurrentStakingData()
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `${responseResult.error.data.message}`,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                })
            }
        })
    }

    const columns = () => {
        return [
            {
                name: 'Coin',
                selector: (row) => row.coin,
                cell: (row) => (
                    <>
                        <img
                            src={require(`../../assets/images/coin/${row.coin}.png`)}
                            alt={row.coin}
                            className='img-fluid avatar mx-1'
                        />{' '}
                        {row.coin}
                        <span className='text-muted'>{row.title}</span>
                    </>
                ),
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'APY & Amount',
                selector: (row) => row.apy,
                sortable: true,
                maxWidth: '150px',
                minWidth: '150px',
                cell: (row) => (
                    <div style={{ flexDirection: 'column' }}>
                        <div>
                            <span className='text-muted'>APY: </span>
                            <span className='color-price-up'>{row.apy}</span>
                        </div>
                        <div>
                            <span className='text-muted'>Amount: </span>
                            <span>{row.amount}</span>
                            <span className='ml-1'>{row.coin}</span>
                        </div>
                    </div>
                )
            },
            {
                name: 'Duration',
                selector: (row) => row.duration_in_days,
                sortable: true,
                cell: (row) => (
                    <>
                        <span>{row.duration_in_days} Days</span>
                    </>
                )
            },
            {
                name: 'Created Date',
                selector: (row) => row.created_at,
                sortable: true,
                cell: (row) => {
                    const formattedCreatedDate = formatDate(row.created_at)
                    return (
                        <div className='d-flex-column'>
                            <div className='d-flex-column'>
                                <span>{formattedCreatedDate}</span>
                            </div>
                        </div>
                    )
                }
            },
            {
                name: 'Close Date',
                selector: (row) => row.created_at,
                sortable: true,
                cell: (row) => {
                    const formattedCloseDate = formatDate(row.close_at)
                    return (
                        <div className='d-flex-column'>
                            <div className='d-flex-column mt-1'>
                                <span>{formattedCloseDate}</span>
                            </div>
                        </div>
                    )
                }
            },
            {
                name: 'Stake',
                selector: (row) => 'Stake',
                sortable: true,
                cell: (row) => (
                    <span className='text-secondary delete-link' onClick={() => handleDeleteStaking(row)}>
                        Delete
                    </span>
                )
            }
        ]
    }
    return (
        <DataTable
            title='Staking'
            columns={columns()}
            data={stakingActiveAssets?.data || []}
            defaultSortField='title'
            selectableRows={false}
            highlightOnHover={true}
            pagination
        />
    )
}

export default StakingActiveAssets
