import React from 'react'
import P1 from '../../assets/images/profile_av.svg'

import { useGetUserQuery } from '../../redux/services/authService'

const UserDetailcard = () => {
    const { currentData: userData } = useGetUserQuery()

    const userAvatar = userData?.user.avatar_url ? userData?.user.avatar_url : P1

    const getUserVerificationStatus = () => {
        return userData?.documentVerificationStatus || 'unverified';
    }

    const userVerificationAlert = () => {
        const status = getUserVerificationStatus();

        if (!status) {
            return (<></>);
        }

        if (status === 'verified') {
            return (<></>);
        }

        if (status === 'rejected') {
            return (
                <div className='alert alert-danger d-flex justify-content-between align-items-center' role='alert'>
                    <span><strong>Account Verification Failed!</strong> To ensure the security and integrity of our platform, please complete the verification process.</span>
                    <a href="https://zfrmz.com/KM73O9F7J83lqbOui7J2" className='btn btn-primary btn-sm'>Verify now</a>
                </div>
            );
        }

        if (status === 'unverified') {
            return (
                <div className='alert alert-warning d-flex justify-content-between align-items-center' role='alert'>
                    <span><strong>Account Verification Required!</strong> To ensure the security and integrity of our platform, please complete the verification process.</span>
                    <a href="https://zfrmz.com/KM73O9F7J83lqbOui7J2" className='btn btn-primary btn-sm'>Verify now</a>
                </div>
            );
        }

        return (<></>);
    };

    return (
        <div className='row g-3 mb-3'>
            <div className='col-lg-12'>
                {userVerificationAlert()}
                <div className='card'>
                    <div className='card-body'>
                        <div className='row g-3 align-items-center'>
                            <div className='col-md-6 col-lg-6 col-xl-4'>
                                <div className='d-flex'>
                                    <img className='avatar rounded-circle' src={userAvatar} alt='profile' />
                                    <div className='flex-fill ms-3'>
                                        <p className='mb-0'>
                                            <span className='font-weight-bold'>{userData?.user.name}</span>
                                        </p>
                                        <small className=''>{userData?.user.email}</small>
                                    </div>
                                </div>
                            </div>
                            {userData?.user.demo_enabled ? (
                                <div className='col-md-6 col-lg-6 col-xl-4'>
                                    <div className='d-flex-column'>
                                        <span className='badge bg-careys-pink mb-1' style={{ width: '80px' }}>
                                            Demo
                                        </span>
                                        <span className='small text-muted flex-fill text-truncate'>
                                            Demo is available for the next:{' '}
                                            <span className='color-price-up'>{userData?.user.demo_left_for_humans}</span>
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className='col-md-6 col-lg-6 col-xl-4'>
                                    <div className='d-flex-column'>
                                        <span className='small text-muted flex-fill text-truncate'>Your status:</span>
                                        <span className='badge bg-success mb-1' style={{ width: '80px' }}>
                                            Online
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div className='col-md-6 col-lg-6 col-xl-4'>
                                <a href='/affiliate' title='invite' className='btn btn-primary text-dark mb-1'>
                                    25 USD Reward: Invite friends now!
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDetailcard
