export const timeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    const minute = 60;
    const hour = 60 * minute;
    const day = 24 * hour;
    const month = 30 * day;  // Approximation for a month

    if (diffInSeconds < minute) {
        return 'just now';
    } else if (diffInSeconds < hour) {
        const mins = Math.floor(diffInSeconds / minute);
        return `${mins} minute${mins > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < day) {
        const hrs = Math.floor(diffInSeconds / hour);
        return `${hrs} hour${hrs > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < month) {
        const days = Math.floor(diffInSeconds / day);
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else {
        // If the difference is more than 30 days, return the exact date in the local format
        return date.toLocaleDateString();
    }
}
