import Post from "./Post";
import {useEffect} from "react";
import {usePub} from "../../helpers/usePubSub";

// cusom styles:
import './NewsFeed.css';
const NewsFeed = () => {

    const publish = usePub();

    const onSocketMessage = (event) => {
        const eventPayload = JSON.parse(event.data);
        publish(eventPayload.event, eventPayload.data);
    }

    useEffect(() => {
        const ws = new WebSocket('wss://api.coiniy.com:2054/');

        ws.onopen = () => {
            console.log('WebSocket connected');
        };

        ws.onmessage = (event) => {
            onSocketMessage(event);

        };

        ws.onerror = (error) => {
            console.error('WebSocket Error: ', error);
        };

        ws.onclose = () => {
            console.log('WebSocket disconnected');
        };

        return () => {
            ws.close();
        };
    }, []);
    return (
        <Post/>
    )
}

export default NewsFeed
