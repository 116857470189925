import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import toast from 'react-hot-toast'

import { useRunAutoTradingMutation, useDeleteAutoTradingMutation } from '../../redux/services/autoTradingService'
import AutoTradingEditModal from './AutoTradingEditModal'

const AutoTradingSingleTrade = (props) => {
    const { tradeId, trade, refetchTradinglist } = props

    const [isActiveTrade, setIsActiveTrade] = useState(trade.running === 0 ? false : true)
    const [tradingModalOpen, setTradingModalOpen] = useState(false)
    const [selectedTrading, setSelectedTrading] = useState(null)

    const [toggleAutoTrading] = useRunAutoTradingMutation()
    const [deleteAutoTrading] = useDeleteAutoTradingMutation()

    const pairValue = trade.pair
    const pairValues = pairValue.split('-')

    const handleToggleAutoTrading = async () => {
        try {
            const result = await toggleAutoTrading({ run: !isActiveTrade, tradeId })
            if (result.error) {
                toast.error(result.error.data.message)
            } else {
                if (isActiveTrade) {
                    setIsActiveTrade((prev) => !prev)
                    refetchTradinglist()
                    toast.success('Auto trading paused!')
                } else {
                    setIsActiveTrade((prev) => !prev)
                    refetchTradinglist()
                    toast.success('Auto trading started!')
                }
            }
        } catch (error) {
            toast.error(error.data.message)
        }
    }

    const handleDeleteAutoTrading = async (tradeId) => {
        try {
            const result = await deleteAutoTrading({ tradeId })
            if (result.error) {
                toast.error(result.error.data.message)
            } else {
                toast.success('Trade deleted!')
                refetchTradinglist()
            }
        } catch (error) {
            toast.error(error.data.message)
        }
    }

    const handleShowModal = (trade) => {
        setSelectedTrading(trade)
        setTradingModalOpen(true)
    }

    return (
        <div className='col col-summary-wrapper mb-3'>
            <div className='card'>
                <div className='card-body d-flex align-items-center'>
                    <div className='flex-fill text-truncate'>
                        <div className='trading-wrapper'>
                            <div className='ml-1'>
                                <span className='h6 fw-bold d-block' style={{ marginBottom: '0' }}>
                                    Pair
                                </span>
                                <div className='d-flex justify-content-between'>
                                    <div className='price-block'>
                                        <span className='fs-6 fw-bold pair-wrapper'>
                                            <img
                                                src={require(`../../assets/images/coin/${pairValues[0]}.png`)}
                                                alt={pairValues[0]}
                                                className='img-fluid avatar pair-img'
                                                style={{ marginRight: '0.25rem' }}
                                            />
                                            {pairValues[0]} -
                                            <img
                                                src={require(`../../assets/images/coin/${pairValues[1]}.png`)}
                                                alt={pairValues[1]}
                                                className='img-fluid avatar mx-1 pair-img'
                                            />
                                            {pairValues[1]}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span className='h6 fw-bold d-block' style={{ marginBottom: '0' }}>
                                    Trading amount
                                </span>
                                <div className='d-flex justify-content-between'>
                                    <span className='color-price-up' style={{ marginRight: '5px' }}>
                                        {trade.max_simultaneous_trades} x
                                    </span>
                                    {trade.max_amount} {trade.base_currency}
                                </div>
                            </div>
                            <div>
                                {trade.running === 1 ? (
                                    <div className='d-flex justify-content-between' style={{ alignItems: 'center' }}>
                                        <span
                                            className='spinner-border spinner-border-sm trading-spinner'
                                            role='status'
                                            aria-hidden='true'></span>
                                        Trading...
                                    </div>
                                ) : (
                                    <div className='d-flex justify-content-between' style={{ alignItems: 'center' }}>
                                        <span className='color-price-down'>Stopped</span>
                                    </div>
                                )}
                            </div>
                            <div className='trading-icons-wrapper'>
                                {trade.running === 1 ? (
                                    <i
                                        onClick={handleToggleAutoTrading}
                                        className='fa fa-pause pair-pause'
                                        data-tooltip-id='pause-tooltip'
                                        data-tooltip-content='Pause trading'></i>
                                ) : (
                                    <i
                                        onClick={handleToggleAutoTrading}
                                        className='fa fa-play pair-play color-price-up'
                                        data-tooltip-id='play-tooltip'
                                        data-tooltip-content='Start trading'></i>
                                )}
                                <i
                                    onClick={() => handleShowModal(trade)}
                                    className='fa fa-edit pair-edit'
                                    data-tooltip-id='edit-tooltip'
                                    data-tooltip-content='Edit'></i>
                                <i
                                    onClick={() => handleDeleteAutoTrading(tradeId)}
                                    className='fa fa-trash pair-delete'
                                    data-tooltip-id='delete-tooltip'
                                    data-tooltip-content='Delete'></i>
                            </div>
                            <Tooltip id='pause-tooltip' />
                            <Tooltip id='edit-tooltip' />
                            <Tooltip id='delete-tooltip' />
                            <Tooltip id='play-tooltip' />
                        </div>
                        <AutoTradingEditModal
                            tradingModalOpen={tradingModalOpen}
                            setTradingModalOpen={setTradingModalOpen}
                            selectedTrading={selectedTrading}
                            refetchTradinglist={refetchTradinglist}
                            maxAmount={trade.max_amount}
                            maxSimultaneousTrades={trade.max_simultaneous_trades}
                            maxRiskLevel={trade.max_risk_level}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AutoTradingSingleTrade
