import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import StakingTableListModal from './StakingTableListModal'
import { useGetStakingQuery } from '../../redux/services/stakingService'

const StakingAssets = () => {
    const [stakingModalOpen, setStakingModalOpen] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)
    const [selectedStaking, setSelectedStaking] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState('')

    const { currentData: stakingAssets } = useGetStakingQuery()

    const handleShowModal = (selectedStaking) => {
        setFullscreen(selectedStaking)
        setStakingModalOpen(true)
        setSelectedStaking(selectedStaking)
    }

    const columns = () => {
        return [
            {
                name: 'Coin',
                selector: (row) => row.coin,
                cell: (row) => (
                    <>
                        <img
                            src={require(`../../assets/images/coin/${row.coin}.png`)}
                            alt={row.coin}
                            className='img-fluid avatar mx-1'
                        />{' '}
                        {row.title}
                    </>
                ),
                sortable: true,
                minWidth: '200px'
            },
            {
                name: 'Est. APY',
                selector: (row) => row.apy,
                sortable: true,
                maxWidth: '50px',
                minWidth: '100px',
                cell: (row) => (
                    <>
                        <span className='color-price-up'>{parseFloat(row.apy)}%</span>
                    </>
                )
            },
            {
                name: 'Duration (days)',
                selector: (row) => row.durations,
                sortable: true,
                cell: (row) => (
                    <div className='durations-wrapper'>
                        <ul className='durations-list'>{row?.durations.join(' / ')}</ul>
                    </div>
                )
            },
            {
                name: 'Min. Amount',
                selector: (row) => `${row.min_amount} ${row.coin}`,
                sortable: true
            },
            {
                name: 'Actions',
                selector: (row) => 'Stake',
                sortable: true,
                cell: (row) => (
                    <button
                        data-bs-toggle='modal'
                        data-bs-target='#icoModal'
                        type='button'
                        className='btn btn-primary border lift mt-1'
                        onClick={() => handleShowModal(row)}>
                        Stake
                    </button>
                )
            }
        ]
    }

    return (
        <div className='row g-3 mb-3'>
            <div className='col-lg-12 col-md-12'>
                <div id='DataTables_Table_11_wrapper' className='dataTables_wrapper dt-bootstrap5 no-footer'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <DataTable
                                title='Staking'
                                columns={columns()}
                                data={stakingAssets?.data ? stakingAssets.data : []}
                                defaultSortField='title'
                                selectableRows={false}
                                highlightOnHover={true}
                                pagination
                            />
                        </div>
                        {selectedStaking && (
                            <StakingTableListModal
                                fullscreen={fullscreen}
                                stakingModalOpen={stakingModalOpen}
                                setStakingModalOpen={setStakingModalOpen}
                                selectedStaking={selectedStaking}
                                selectedDuration={selectedDuration}
                                setSelectedDuration={setSelectedDuration}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StakingAssets
