import React from 'react'
import SignalsCard from './SignalsCard'

const Signals = () => {
    return (
        <div className='container-xxl bs-container-reset pd-left-xsmall'>
            <div className='row g-3 mb-3'>
                <SignalsCard />
            </div>
        </div>
    )
}

export default Signals
