import React from "react";
import DataTable from "react-data-table-component";
import {
    useCancelCopyTradingMutation,
    useGetCopyTradingFollowedTradersQuery
} from "../../redux/services/copyTradingService";
import Swal from "sweetalert2";

const ActiveCopyTrading = props => {
    const {followedTraders, copyTradingCancelled} = props;
    const [cancelCopyTrading] = useCancelCopyTradingMutation();

    const columns = () => {

        const renderProfitLoss = (row) => {
            if (parseFloat(row.profitLoss.amount) > 0) {
                return <span className='color-price-up'>+{parseFloat(row.profitLoss.amount).toFixed(2)} {row.profitLoss.currency}</span>
            } else if (parseFloat(row.profitLoss.amount) < 0) {
                return <span className='color-price-down'>{parseFloat(row.profitLoss.amount).toFixed(2)} {row.profitLoss.currency}</span>
            } else {
                return <span>0 {row.profitLoss.currency}</span>
            }
        }

        const cancelSwalOptions = {
            title: 'Cancel following',
            showCancelButton: true,
            html: `
              <p style='color: #6b7078; font-size: 1rem'>
              Are you sure you want to cancel following this trader?
              </p>
              <p style='color: #6b7078; font-size: 1rem'>
              <i class='icofont-warning' style='color: #ffc047'></i>
              Open positions will be closed automatically.
              </p>`,
            confirmButtonText: 'Yes, cancel it',
            cancelButtonText: 'No, keep it',
            customClass: {
                confirmButton: 'btn py-2 text-uppercase btn-primary',
                cancelButton: 'btn py-2 text-uppercase btn-outline-secondary ms-1'
            },
            buttonsStyling: false
        }

        const handleCancelFollowing = (row) => {
            Swal.fire(cancelSwalOptions).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await cancelCopyTrading({trader_id: row.traderId}).unwrap()
                        await Swal.fire(
                            'Cancelled!',
                            'Your following has been cancelled.',
                            'success'
                        )
                        copyTradingCancelled()
                    } catch (e) {
                        await Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong! Please try again later.'
                        })
                    }

                }
            });
        }

        return [
            {
                name: 'Trader',
                selector: (row) => row.traderName,
                cell: (row) => (
                    <>
                        {row.traderName}
                    </>
                ),
                sortable: true,
                minWidth: '200px'
            },
            {
                name: 'Investment',
                selector: (row) => row.investmentAmount,
                sortable: true,
                cell: (row) => (
                    <>
                        {parseFloat(row.investmentAmount).toFixed(2)} {row.investmentCurrency}
                    </>
                )
            },
            {
                name: 'Stop loss',
                selector: (row) => row.stopLoss,
                sortable: true,
                cell: (row) => (
                    <>
                        {row.stopLoss ?
                            <>{parseFloat(row.stopLoss).toFixed(2)} {row.investmentCurrency}</> :
                            <>-</>
                        }
                    </>
                )
            },
            {
                name: 'Profit / loss',
                selector: (row) => row.profitLoss.amount,
                sortable: true,
                cell: (row) => renderProfitLoss(row)
            },
            {
                name: 'Followed since',
                selector: (row) => row.followedAt,
                sortable: true,
                cell: (row) => (
                    <>
                        {row.followedAt}
                    </>
                )
            },
            {
                name: 'Fee',
                selector: (row) => row.winningFeePercent,
                sortable: true,
                cell: (row) => (
                    <>
                        {`${Number(parseFloat(row.winningFeePercent) * 100)}%`}
                    </>
                )
            },
            {
                name: 'Actions',
                selector: (row) => 'Copy',
                sortable: false,
                cell: (row) => (
                    <button
                        data-bs-toggle='modal'
                        data-bs-target='#icoModal'
                        type='button'
                        className='btn btn-primary border lift mt-1'
                        onClick={() => handleCancelFollowing(row)}
                    >
                        Cancel
                    </button>
                )
            }
        ]
    }

    return (
        <div className='row g-3 mb-3'>
            <div className='col-lg-12 col-md-12'>
                <div id='DataTables_Table_11_wrapper' className='dataTables_wrapper dt-bootstrap5 no-footer'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <DataTable
                                title='Staking'
                                columns={columns()}
                                data={followedTraders ?? []}
                                defaultSortField='followedAt'
                                defaultSortAsc={false}
                                selectableRows={false}
                                highlightOnHover={true}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveCopyTrading
