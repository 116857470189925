import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'

import LongTermTableClosed from './LongTermsTableClosed'
import LongTermTableOpen from './LongTermTableOpen'
import LongTermTableCFD from './LongTermTableCFD'

import { useGetClosedPositionsQuery, useGetOpenPositionsQuery } from '../../redux/services/cfdService'
import { useGetUserFiatBalanceQuery } from '../../redux/services/userService'

import LongTermChart from './LongTermTableChart'
import { useGetUserQuery } from '../../redux/services/authService'
import { cryptocurrenciesData } from '../../helpers/cryptocurrency'

const OPEN_TAB = 'open'
const CLOSED_TAB = 'closed'

const LongTermTable = () => {
    const [selectedTab, setSelectedTab] = useState(OPEN_TAB)

    const { currentData: cfdOpenData, refetch: refetchOpenPositions } = useGetOpenPositionsQuery()
    const { currentData: cfdClosedDAta, refetch: refetchClosedPositions } = useGetClosedPositionsQuery()
    const { refetch: refetchUserFiatBalance } = useGetUserFiatBalanceQuery()
    const { currentData: userData } = useGetUserQuery()

    useEffect(() => {
        if (selectedTab === CLOSED_TAB) {
            refetchClosedPositions()
        }
        // eslint-disable-next-line
    }, [selectedTab])

    const mainFiatCurrency = userData?.user?.main_fiat_currency
    const leverageValues = userData?.user?.enabled_cfd_leverages ? userData?.user?.enabled_cfd_leverages.split(',') : [1];

    const renderLongTermForm = () => {
        if (!mainFiatCurrency) {
            return (<></>)
        }

        if (typeof cryptocurrenciesData === 'undefined' || cryptocurrenciesData.length === 0) {
            return (<></>)
        }

        return (
            <LongTermTableCFD
                refetchOpenPositions={refetchOpenPositions}
                cfdOpenData={cfdOpenData}
                cfdClosedDAta={cfdClosedDAta}
                mainFiatCurrency={mainFiatCurrency}
                leverageValues={leverageValues || []}
            />
        )
    }

    return (
        <>
            <Tab.Container defaultActiveKey='first'>
                <div className='row g-3'>
                    <div className='col-lg-12 '>
                        <div className='row'>
                            <div className='col-xxl-7 order-2 order-xxl-1'>
                                <LongTermChart />
                            </div>
                            <div className='col-xxl-5 order-1'>
                                {renderLongTermForm()}
                            </div>
                        </div>
                        <Tabs
                            onSelect={(selectedKey) => setSelectedTab(selectedKey)}
                            defaultActiveKey='open'
                            transition={false}
                            id='noanim-tab-example1'
                            className='tab-body-header rounded d-inline-flex mb-3 cfd-tab'>
                            <Tab eventKey='open' title='Open Positions'>
                                <div className='card mb-3'>
                                    <div className='card-body'>
                                        <LongTermTableOpen
                                            cfdOpenData={cfdOpenData}
                                            refetchOpenPositions={refetchOpenPositions}
                                            refetchClosedPositions={refetchClosedPositions}
                                            refetchUserFiatBalance={refetchUserFiatBalance}
                                            userEnabledFeatures={userData?.features || []}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey='closed' title='Closed Positions'>
                                <div className='card mb-3'>
                                    <div className='card-body'>
                                        <LongTermTableClosed cfdClosedDAta={cfdClosedDAta} />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </Tab.Container>
        </>
    )
}
export default LongTermTable
