import React from 'react'
import { Link } from 'react-router-dom'
const RewardCenter = () => {
    return (
        <div className='container-xxl bs-container-reset pd-left-xsmall'>
            <div className='row g-3 mb-3'>
                <div className='col-xxl-6 order-2 order-xxl-1'>
                    <div className="card text-center">
                        <div className="card-body">
                            <div className="p-4">
                                <div className="fs-1 text-uppercase">Gift a Voucher</div>
                                <span>Gift a voucher to another Coiniy user</span>
                            </div>
                            <div className="form-group mb-3 p-4 d-grid gap-2">
                                <Link to="https://zfrmz.com/bgVbtTeXWFLV9o4q1Mp2" className="btn btn-light-primary btn-block" type="button">CONTINUE</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xxl-6 order-1'>
                <div className="card text-center">
                        <div className="card-body">
                            <div className="p-4">
                                <div className="fs-1 text-uppercase">Claim Your Voucher</div>
                                <span>Click link below and fill out the form to claim your voucher</span>
                            </div>
                            <div className="form-group mb-3 p-4 d-grid gap-2">
                                <Link to="https://zfrmz.com/b1gX2YeWcFfXtfyi4NHh" className="btn btn-light-primary btn-block" type="button">CLAIM VOUCHER</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default RewardCenter
