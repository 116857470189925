import React from 'react'
import { renderRiskDots, getRiskLevelText } from '../../helpers/utils'

import { Button, Form } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { Tooltip } from 'react-tooltip'
import toast from 'react-hot-toast'

import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { OpenSignalSchema } from '../../helpers/forms/schema/base'
import { yupResolver } from '@hookform/resolvers/yup'

import { useOpenTradeMutation } from '../../redux/services/tradeService'
import { cryptocurrenciesData } from '../../helpers/cryptocurrency'

const SignalsCardSingle = (props) => {
    const { pair, riskLevel, signalType, signalId, signalExpires, signalCreated, refetchTradesData } = props

    const [openTradeSignal] = useOpenTradeMutation()

    const [baseCurrency, quoteCurrency] = pair.split('-')

    const matchingCurrency = cryptocurrenciesData.find((currency) => currency.value === baseCurrency)

    const defaultValues = {
        amount: matchingCurrency.default_amount
    }

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(OpenSignalSchema) })

    const createdDate = new Date(signalCreated)
    const expiresDate = new Date(signalExpires)

    const remainingTime = Math.floor((expiresDate - createdDate) / 1000)

    const secondsLeftToExpire = () => {
        const now = Math.round(new Date().getTime() / 1000)
        return parseInt(String(expiresDate - now))
    }

    const renderTime = () => {
        const secondsLeft = secondsLeftToExpire()
        if (secondsLeft <= 0) {
            return <span className='text-muted'>Expired</span>
        }

        return (
            <div className='timer'>
                <div className='value'>{secondsLeft} s</div>
            </div>
        )
    }

    const refreshCurrentSignalsData = () => {
        refetchTradesData()
    }

    const onSubmit = async (data) => {
        try {
            const result = await openTradeSignal({
                amount: data.amount,
                signalId
            })
            if (result.error) {
                setError('amount', {
                    type: 'manual',
                    message: result.error.data.message
                })
            } else {
                toast.success('Signal added!')
                refreshCurrentSignalsData()
            }
        } catch (error) {
            setError('amount', {
                type: 'manual',
                message: error.data.message
            })
        }
    }

    return (
        <div className='card mb-3'>
            <div className='card-body d-flex align-items-center'>
                <div className='flex-fill text-truncate'>
                    <div className='trading-wrapper'>
                        <div className='currency-wrapper'>
                            {signalType === 0 ? (
                                <span className='fs-6 fw-bold color-price-up' style={{ marginBottom: '0' }}>
                                    Buy <i className='fa fa-level-up'></i>
                                </span>
                            ) : (
                                <span className='fs-6 fw-bold color-price-down' style={{ marginBottom: '0' }}>
                                    Sell <i className='fa fa-level-down'></i>
                                </span>
                            )}

                            <div className='d-flex justify-content-between'>
                                <div className='price-block'>
                                    <span className='fs-6 fw-bold pair-wrapper mt-2'>
                                        <img
                                            src={require(`../../assets/images/coin/${baseCurrency}.png`)}
                                            alt={baseCurrency}
                                            className='img-fluid avatar pair-img'
                                            style={{ marginRight: '0.25rem' }}
                                        />
                                        {baseCurrency} -
                                        <img
                                            src={require(`../../assets/images/coin/${quoteCurrency}.png`)}
                                            alt={quoteCurrency}
                                            className='img-fluid avatar mx-1 pair-img'
                                        />
                                        {quoteCurrency}
                                    </span>
                                    <div
                                        className='d-flex mt-2 dot-wrapper'
                                        data-tooltip-id={`tooltip-${riskLevel}`}
                                        data-tooltip-content={getRiskLevelText(riskLevel)}>
                                        {renderRiskDots(riskLevel)}
                                    </div>
                                    <Tooltip id={`tooltip-${riskLevel}`} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='d-flex justify-content-between' style={{ alignItems: 'center' }}>
                                <CountdownCircleTimer
                                    isPlaying
                                    duration={remainingTime}
                                    colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                    colorsTime={[remainingTime, remainingTime * 0.6, remainingTime * 0.3, 0]}
                                    size={80}
                                    strokeWidth={4}
                                    onComplete={() => ({ shouldRepeat: false, delay: 1 })}>
                                    {renderTime}
                                </CountdownCircleTimer>
                            </div>
                        </div>
                        <div className='signals-form-wrapper'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className='mb-3 mt-3' controlId='amount'>
                                    <Controller
                                        control={control}
                                        name='amount'
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Control
                                                className='signals-amount-input'
                                                onChange={onChange}
                                                value={value.replace(/,/g, '.')}
                                                placeholder='Amount'
                                                type='text'
                                            />
                                        )}
                                    />
                                </Form.Group>
                                <Button type='submit' className='btn flex-fill w-100'>
                                    Open
                                </Button>
                            </Form>
                        </div>
                    </div>
                    {errors?.amount && (
                        <div className='invalid-feedback mt-3' style={{ display: 'block' }}>
                            {errors.amount.message}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SignalsCardSingle
