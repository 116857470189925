import React, { useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import {useCreatePostCommentMutation} from "../../../redux/services/newsfeedService";

const MAX_CHARACTERS = 1000;

const CommentReplyForm = ({postId}) => {
    const [charactersCount, setCharactersCount] = useState(0);
    const [isPublishing, setIsPublishing] = useState(false);
    const [postText, setPostText] = useState('');
    const [createPostComment] = useCreatePostCommentMutation();

    const updatePostText = (event) => {
        setCharactersCount(event.target.value.length);
        setPostText(event.target.value);
    };

    const handleSubmit = async (event) => {
        setIsPublishing(true);

        event.preventDefault();
        setPostText('');
        setCharactersCount(0);

        await createPostComment({content: postText, postId});
        //
        // if (onPostCreated) {
        //     onPostCreated(result.data.data.post);
        // }

        setIsPublishing(false);
    };

    const renderPublishButton = () => {
        if (isPublishing) {
            return (
                <Button disabled={true} type="submit" size="sm">Publishing...</Button>
            );
        }

        return (
            <Button disabled={charactersCount === 0} type="submit" size="sm">Comment</Button>
        );
    }

    return (
        <Form className="mt-2" onSubmit={handleSubmit}>
            <div className="d-flex flex-column align-items-end">
                <FormControl
                    type="text"
                    placeholder="Write a reply..."
                    className="mb-2"
                    maxLength={MAX_CHARACTERS}
                    value={postText}
                    onChange={(e) => updatePostText(e)}
                />
                <div className="d-flex justify-content-end align-items-center">
                    <span className="me-3 small text-muted">{charactersCount}/{MAX_CHARACTERS}</span>
                    {renderPublishButton()}
                </div>
            </div>
        </Form>
    );
}

export default CommentReplyForm
