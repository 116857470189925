import { configureStore } from '@reduxjs/toolkit'
import appReducer from './redux/reducer'
import { authApi } from './redux/services/authService'
import { userApi } from './redux/services/userService'
import { stakingApi } from './redux/services/stakingService'
import { affiliateApi } from './redux/services/affiliateService'
import { exchangeCryptoApi } from './redux/services/exchangeCryptoService'
import { cfdApi } from './redux/services/cfdService'
import { autoTrading } from './redux/services/autoTradingService'
import { buyCryptoApi } from './redux/services/buyCryptoService'
import { sellCryptoApi } from './redux/services/sellCryptoService'
import { tradeApi } from './redux/services/tradeService'
import {copyTradingApi} from "./redux/services/copyTradingService";
import {newsfeedApi} from "./redux/services/newsfeedService";
import {balanceApi} from "./redux/services/balanceService";

const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            authApi.middleware,
            userApi.middleware,
            stakingApi.middleware,
            affiliateApi.middleware,
            exchangeCryptoApi.middleware,
            cfdApi.middleware,
            autoTrading.middleware,
            buyCryptoApi.middleware,
            sellCryptoApi.middleware,
            tradeApi.middleware,
            copyTradingApi.middleware,
            newsfeedApi.middleware,
            balanceApi.middleware
        )
})

export default store
