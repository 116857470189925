import React, {useEffect, useState} from 'react';
import { ListGroup, Image } from 'react-bootstrap';
import CommentActions from "./CommentActions";
import Profile from '../../../assets/images/profile_av.svg'
import { timeAgo } from '../../../helpers/dateFormater';
import {useGetLastPostCommentsQuery} from "../../../redux/services/newsfeedService";
import {useSub} from "../../../helpers/usePubSub";
import ContentWithTags from "./ContentWithTags";

const CommentListItem = ({comment, depth}) => {
    const [localComment, setLocalComment] = useState(comment);
    const [fetchComments, setFetchComments] = useState(false);

    // Use the query with manual control over fetching
    const { data } = useGetLastPostCommentsQuery({postId: localComment?.id, count: 10000}, {
        skip: !fetchComments
    });

    useSub('comment_reply', (post) => {
        if (post.postId === localComment.id) {
            post.isLiked = false;
            setLocalComment(prev => ({
                ...prev,
                comments: [post, ...prev.comments]
            }));
        }
    });

    useEffect(() => {
        if (data?.data?.comments) {
            setLocalComment(prev => ({
                ...prev,
                comments: data.data.comments
            }));
        }
    }, [data]); // Dependency array ensures this runs only when data changes

    useEffect(() => {
        setLocalComment(comment);
    }, [comment]);

    if (!comment) {
        return (<> </>);
    }


    const loadMoreComments = () => {
        setFetchComments(true);
    }

    return (
        <div className="d-flex align-items-start my-2 mx-2" style={{width: '80%'}}>
            <Image src={localComment.userAvatar || Profile} roundedCircle width="30" height="30" className="me-2 mt-1"/>
            <div style={{width: '100%'}}>
                <strong>{localComment.userNickname}</strong>
                <div className="text-muted text-light">{timeAgo(localComment.createdAt)}</div>
                <div>
                    <div className="mt-2">
                        <ContentWithTags text={localComment.content}/>
                    </div>
                   <CommentActions depth={depth} onLoadCommentsClicked={loadMoreComments} postId={localComment.id} isLiked={localComment.isLiked} likesCount={localComment.likesCount} commentsCount={localComment.commentsCount}/>
                    <ListGroup variant="flush" className="mt-2">
                        {localComment.comments.map(reply => (
                            <CommentListItem depth={depth + 1} comment={reply} key={reply.id}/>
                        ))}
                    </ListGroup>
                </div>
            </div>
        </div>
    );
}

export default CommentListItem;
