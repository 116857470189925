import CommentReplyForm from "./CommentReplyForm";
import React, { useState } from 'react';
import {useLikePostMutation, useDislikePostMutation} from "../../../redux/services/newsfeedService";
import {useSub} from "../../../helpers/usePubSub";

const CommentActions = ({postId, isLiked, likesCount, commentsCount, onLoadCommentsClicked, depth}) => {
    const [visibleReplyForm, setVisibleReplyForm] = useState(false);
    const [localIsLiked, setLocalIsLiked] = useState(isLiked);
    const [localLikesCount, setLocalLikesCount] = useState(likesCount);
    const [moreCommentsLoaded, setMoreCommentsLoaded] = useState(false);

    const [likePost] = useLikePostMutation();
    const [dislikePost] = useDislikePostMutation();

    useSub('post_liked', (post) => {
        if (post.id === postId) {
            setLocalLikesCount(post.likesCount);
        }
    });

    const toggleReplyForm = () => {
        setVisibleReplyForm(!visibleReplyForm);
    };

    const toggleLike = () => {
        if (localIsLiked) {
            dislikePost(postId);
            setLocalLikesCount(localLikesCount - 1)
        } else {
            likePost(postId);
            setLocalLikesCount(localLikesCount + 1)
        }

        setLocalIsLiked(!localIsLiked);
    }

    const renderLikes = () => {
        const text = localIsLiked ? 'Liked' : 'Like';
        if (localLikesCount > 0) {
            return `${text} (${localLikesCount})`
        }

        return text;
    }

    const renderReply = () => {
        if (depth > 0) {
            return (<></>);
        }

        return (
            <> · <span className="ms-1" onClick={() => toggleReplyForm()}>Reply</span></>
        );
    }

    const renderLoadMoreComments = () => {
        if (commentsCount > 0 && !moreCommentsLoaded) {
            return (
                <> · <span className="ms-1" onClick={() => loadMoreComments()}>View all {commentsCount || 0} replies</span></>
            )
        }

        return (<></>)
    }

    const loadMoreComments = () => {
        setMoreCommentsLoaded(true);
        onLoadCommentsClicked();
    }

    return (
        <>
            <div className="text-muted mt-1" style={{cursor: 'pointer'}}>
                <span className={`${localIsLiked ? 'font-weight-bold' : ''}`} onClick={() => toggleLike()}>{renderLikes()}</span>
                {renderReply()}{renderLoadMoreComments()}
            </div>
            {visibleReplyForm && (
                <CommentReplyForm postId={postId}/>
            )}
        </>
    )
}

export default CommentActions
