import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const buyCryptoApi = createApi({
    reducerPath: 'buyCryptoApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        buyCrypto: build.mutation({
            query: (data) => ({
                url: 'exchange/buy-crypto',
                method: 'POST',
                body: data
            })
        }),
        buyCryptoCalculate: build.mutation({
            query: (data) => ({
                url: 'exchange/buy-crypto/calculate',
                method: 'POST',
                body: data
            })
        })
    })
})

export const { useBuyCryptoMutation, useBuyCryptoCalculateMutation } = buyCryptoApi
