import React from 'react'

import AccountCurrency from './AccountCurrency'
import AccountRecoveryKey from './AccountRecoveryKey'
import ChangeEmail from './ChangeEmail'
import ChangeAvatar from './ChangeAvatar'

import ChangePassword from './ChangePassword'
import CopyTradingSettings from "./CopyTradingSettings";

const CHANGE_MAIN_CURRENCY = process.env.REACT_APP_FEATURE_CHANGE_MAIN_CURRENCY
const RECOVERY_KEY = process.env.REACT_APP_FEATURE_RECOVERY_KEY
const CHANGE_EMAIL = process.env.REACT_APP_FEATURE_CHANGE_EMAIL

const SecurityPage = () => {
    const AccountComponent = () => {
        if (CHANGE_MAIN_CURRENCY === 'true' || RECOVERY_KEY === 'true') {
            return (
                <div className='row align-item-center mb-3'>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className='card-header py-3 d-flex justify-content-between bg-transparent align-items-center '>
                                <h6 className='mb-0 fw-bold'>Account</h6>
                            </div>
                            <div className='card-body'>
                                {CHANGE_MAIN_CURRENCY === 'true' && <AccountCurrency />}
                                {RECOVERY_KEY === 'true' && <AccountRecoveryKey />}
                                <CopyTradingSettings/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    const EmailComponent = () => {
        if (CHANGE_EMAIL === 'true') {
            return (
                <div className='row align-item-center mb-3'>
                    <ChangeEmail />
                    <ChangeAvatar />
                </div>
            )
        }
    }
    return (
        <>
            <div className='container-xxl' style={{ paddingLeft: '0', marginLeft: '0' }}>
                <AccountComponent />
                <EmailComponent />                
                <div className='row align-item-center mb-3'>
                    <ChangePassword />
                </div>
            </div>
        </>
    )
}

export default SecurityPage
