import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Select, { components } from 'react-select'

import { yupResolver } from '@hookform/resolvers/yup'

import { useForm, Controller } from 'react-hook-form'
import { CryptoExchangeSchema } from '../../helpers/forms/schema/base'

import {
    useCryptoExchangeMutation,
    useCryptoExchangeCalculateMutation,
    useGetCryptoExchangeHistoryQuery
} from '../../redux/services/exchangeCryptoService'
import { useGetUserQuery } from '../../redux/services/authService'

import { cryptocurrenciesData } from '../../helpers/cryptocurrency'
import { PriceComponent } from '../../helpers/utils'

const ExchangeCardExchange = (props) => {
    const { selectedTab } = props
    const cryptocurrencies = cryptocurrenciesData

    const { refetch: refreshGetCryptoExchangeHistory } = useGetCryptoExchangeHistoryQuery()

    const [exchangeEnabled, setExchangeEnabled] = useState(true)
    const [fromCurrency, setFromCurrency] = useState(cryptocurrencies.find((currency) => currency.value === 'BTC'))
    const [toCurrency, setToCurrency] = useState(cryptocurrencies.find((currency) => currency.value === 'ETH'))
    const [cryptoCalculate, setCryptoCalculate] = useState(null)
    const [cryptoExchange, { isLoading: isCryptoExchangeLoading }] = useCryptoExchangeMutation()
    const [cryptoExchangeCalculate, { isCryptoCalculateError }] = useCryptoExchangeCalculateMutation()
    const [currentRate, setCurrentRate] = useState(null)
    const [successfullyExchanged, setSuccessfullyExchanged] = useState(false)
    const [currentSocketPrices, setCurrentSocketPrices] = useState('')

    const defaultValues = {
        amount: '0.001'
    }

    const { currentData: userData, refetch: refreshUserData } = useGetUserQuery()

    const OptionComponent = ({ data, ...props }) => {
        const icon = data.icon
        return (
            <components.Option {...props}>
                {icon}
                {data.label}
            </components.Option>
        )
    }

    const socketData = (data) => {
        setCurrentSocketPrices(data)
    }

    const {
        control,
        handleSubmit,
        setError,
        watch,
        clearErrors,
        formState: { errors },
        setValue
    } = useForm({ defaultValues, resolver: yupResolver(CryptoExchangeSchema) })

    const watchAmountInput = watch('amount')

    useEffect(() => {
        if (selectedTab === 'exchange' && watchAmountInput.length > 0) {
            const checkData = async () => {
                try {
                    const result = await cryptoExchangeCalculate({
                        amount: watchAmountInput,
                        input_currency: fromCurrency.value,
                        output_currency: toCurrency.value
                    }).unwrap()
                    setCryptoCalculate(result.data)
                    clearErrors('amount')
                    setExchangeEnabled(true)
                    setCurrentRate({
                        input_currency: fromCurrency.value,
                        output_currency: toCurrency.value,
                        rate: result.data.rate
                    })
                } catch (error) {
                    setExchangeEnabled(false)
                    setCryptoCalculate(null)
                    setError('amount', {
                        type: 'manual',
                        message: error.data?.message ? error.data.message : 'Something went wrong'
                    })
                }
            }
            const delay = setTimeout(() => checkData(watchAmountInput), 800)
            return () => clearTimeout(delay)
        }
        // eslint-disable-next-line
    }, [watchAmountInput, fromCurrency, toCurrency, currentSocketPrices, selectedTab])

    const onSubmit = async (data) => {
        if (!fromCurrency) {
            setError('fromCurrency', {
                type: 'manual',
                message: 'From currency field is required'
            })
        } else if (!toCurrency) {
            setError('toCurrency', {
                type: 'manual',
                message: 'To currency field is required'
            })
        } else {
            try {
                await cryptoExchange({
                    amount: data.amount,
                    input_currency: fromCurrency.value,
                    output_currency: toCurrency.value
                }).unwrap()
                setSuccessfullyExchanged(true)
                setTimeout(() => setSuccessfullyExchanged(false), 3000)
                refreshUserData()
                refreshGetCryptoExchangeHistory()
            } catch (error) {
                setError('amount', {
                    type: 'manual',
                    message: error.data.message
                })
            }
        }
    }

    const setAmountValueFromUserBalance = (e) => {
        e.preventDefault()
        setValue('amount', `${parseFloat(userData.balances[fromCurrency.value])}`)
    }

    return (
        <div className='row g-3'>
            <PriceComponent getPricesData={socketData} />
            <div className='col-lg-12'>
                {userData && (
                    <div className='d-flex align-items-center justify-content-between mu-3'>
                        <span className='small text-muted'>Available</span>
                        <span className=''>
                            <span onClick={setAmountValueFromUserBalance} className='text-secondary a-link'>
                                {parseFloat(userData.balances[fromCurrency.value])} {fromCurrency.value}
                            </span>
                        </span>
                    </div>
                )}
                <div className='d-flex align-items-center justify-content-between my-2'>
                    <span className='small text-muted'>Rate</span>
                    <span className=''>
                        {currentRate
                            ? `1 ${currentRate.input_currency} = ${parseFloat(currentRate.rate)} ${
                                  currentRate.output_currency
                              }`
                            : 'n/a'}
                    </span>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <label className='form-label'>From</label>
                        <Select
                            name='fromCurrency'
                            placeholder='Select currency'
                            options={cryptocurrencies}
                            value={fromCurrency}
                            onChange={setFromCurrency}
                            isClearable={false}
                            components={{
                                Option: OptionComponent
                            }}
                        />
                        {errors?.fromCurrency && (
                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                {errors.fromCurrency.message}
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group className='mb-3 mt-3'>
                        <label className='form-label'>To</label>
                        <Select
                            name='toCurrency'
                            placeholder='Select currency'
                            options={cryptocurrencies.filter((crypto) => crypto.value !== fromCurrency?.value)}
                            value={toCurrency}
                            onChange={setToCurrency}
                            isClearable={false}
                            components={{
                                Option: OptionComponent
                            }}
                        />
                        {errors?.toCurrency && (
                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                {errors.toCurrency.message}
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group className='mb-3 mt-3' controlId='amount'>
                        <label className='form-label'>Amount</label>
                        <Controller
                            control={control}
                            name='amount'
                            render={({ field: { onChange, value } }) => (
                                <Form.Control
                                    onChange={onChange}
                                    value={value.replace(/,/g, '.')}
                                    isInvalid={errors.amount}
                                    placeholder='Enter the amount'
                                    type='text'
                                />
                            )}
                        />
                        {errors?.amount && (
                            <Form.Control.Feedback type='invalid'>{errors.amount.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    {cryptoCalculate && (
                        <>
                            <div className=''>
                                <div className='mb-3'>
                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <div className='d-flex'>
                                            <div className='truncated'>You will exchange</div>
                                            <div className='text-muted  truncated px-1'>
                                                {cryptoCalculate
                                                    ? `${parseFloat(cryptoCalculate.input_amount)} ${
                                                          cryptoCalculate.input_currency
                                                      }`
                                                    : 'n/a'}
                                            </div>
                                            <div className='truncated'>to</div>
                                            <div className='text-muted  truncated px-1'>
                                                {cryptoCalculate
                                                    ? `${parseFloat(cryptoCalculate.amount)} ${
                                                          cryptoCalculate.output_currency
                                                      }`
                                                    : 'n/a'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {isCryptoExchangeLoading ? (
                        <Button
                            disabled
                            className='exchange-btn btn flex-fill py-2 fs-5 text-uppercase px-5 w-100 btn-loading-fixed'>
                            Processing...
                            <div className='spinner-border text-light' role='status'></div>
                        </Button>
                    ) : (
                        <>
                            {successfullyExchanged ? (
                                <Button type='submit' className='btn flex-fill btn-light-success py-2 fs-5 px-5 w-100'>
                                    <i className='icofont-check-circled'></i> Done!
                                </Button>
                            ) : (
                                <Button
                                    type='submit'
                                    disabled={!exchangeEnabled || isCryptoCalculateError}
                                    className='exchange-btn btn flex-fill py-2 fs-5 text-uppercase px-5 w-100'>
                                    Exchange
                                </Button>
                            )}
                        </>
                    )}
                </Form>
            </div>
        </div>
    )
}

export default ExchangeCardExchange
