import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import * as yup from 'yup'

import { useForm, Controller } from 'react-hook-form'
import {
    useGetStakingActiveQuery,
    useGetStakingAmountsQuery,
    useGetStakingQuery,
    useOpenStakingMutation
} from '../../redux/services/stakingService'

import { yupResolver } from '@hookform/resolvers/yup'
import { useGetUserQuery } from '../../redux/services/authService'

import { useCalculateStakingMutation } from '../../redux/services/stakingService'

const StakingTableListModal = (props) => {
    const { stakingModalOpen, setStakingModalOpen, selectedStaking } = props

    const [stakingOpen, { isLoading }] = useOpenStakingMutation()
    const { refetch: refetchActiveData } = useGetStakingActiveQuery()
    const { refetch: refetchAmountsData } = useGetStakingAmountsQuery()
    const { refetch } = useGetStakingQuery()

    const [selectedDuration, setSelectedDuration] = useState(selectedStaking.durations[0])
    const [successfullyExchanged, setSuccessfullyExchanged] = useState(false)
    const [calculatedStaking, setCalculatedStaking] = useState({
        expected_profit: 0,
        expected_profit_currency: selectedStaking.coin
    })

    useEffect(() => {
        setSelectedDuration(selectedStaking.durations[0])
    }, [selectedStaking])

    const defaultValues = {
        amount: selectedStaking.min_amount
    }

    const minAmount = selectedStaking.min_amount
    const maxAmount = selectedStaking.max_amount

    const { currentData: userData } = useGetUserQuery()
    const currentStakingCoinUserBalance = parseFloat(userData?.balances[selectedStaking.coin] || 0)
    const [calculateStaking] = useCalculateStakingMutation()

    const StakingLockAmountSchema = yup.object().shape({
        amount: yup
            .number()
            .typeError('Lock amount must be a number')
            .required('Lock amount field is required.')
            .min(minAmount, `Amount must be greater than or equal to ${minAmount}`)
            .max(maxAmount, `Amount must be less than or equal to ${maxAmount}`)
    })

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
        watch,
        setValue,
        clearErrors
    } = useForm({ defaultValues, resolver: yupResolver(StakingLockAmountSchema) })

    const watchAmount = watch('amount')
    useEffect(() => {
        const checkData = async () => {
            try {
                const result = await calculateStaking({
                    amount: watchAmount,
                    asset_id: selectedStaking.id,
                    duration_in_days: selectedDuration
                }).unwrap()
                setCalculatedStaking(result)
            } catch (error) {
                setCalculatedStaking({ expected_profit: 0, expected_profit_currency: selectedStaking.coin })
            }
        }
        const delay = setTimeout(() => checkData(), 400)
        return () => clearTimeout(delay)
        // eslint-disable-next-line
    }, [watchAmount, selectedDuration, selectedStaking])

    const handleCloseModal = () => {
        refetch()
        setStakingModalOpen(false)
        reset()
    }

    const changeAmount = (e, amount) => {
        e.preventDefault()
        setValue('amount', String(amount))
    }

    const isAbleToSubmit = () => {
        return (
            !isLoading &&
            !errors.amount &&
            parseFloat(watchAmount) >= parseFloat(selectedStaking.min_amount) &&
            parseFloat(watchAmount) <= parseFloat(selectedStaking.max_amount) &&
            parseFloat(watchAmount) <= currentStakingCoinUserBalance &&
            selectedDuration
        )
    }

    const refreshCurrentStakingData = () => {
        refetchActiveData()
        refetchAmountsData()
    }

    const onSubmit = async (data) => {
        try {
            clearErrors('amount')
            await stakingOpen({
                amount: data.amount,
                asset_id: selectedStaking.id,
                duration_in_days: selectedDuration
            }).unwrap()
            setSuccessfullyExchanged(true)
            setTimeout(() => handleCloseModal(), 2000)
            setTimeout(() => setSuccessfullyExchanged(false), 3000)
            refreshCurrentStakingData()
        } catch (err) {
            setError('amount', {
                type: 'manual',
                message: err.data.message
            })
        }
    }

    return (
        <Modal
            className='modal fade'
            id='icoModal'
            show={stakingModalOpen}
            onHide={() => {
                handleCloseModal()
            }}
            fullscreen='sm-down'>
            <Modal.Dialog className='' style={{ maxWidth: '900px' }}>
                <div className='modal-content'>
                    <Modal.Header className='modal-header' closeButton>
                        <h5 className='modal-title'>Staking</h5>
                    </Modal.Header>
                    <Modal.Body className='modal-body custom_setting'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className='col-lg-6 col-xl-6'>
                                    <div>
                                        <img
                                            src={require(`../../assets/images/coin/${selectedStaking.coin}.png`)}
                                            alt=''
                                            className='img-fluid avatar mx-1'
                                        />
                                        <span className='text-uppercase fw-bold'>{selectedStaking.coin}</span>{' '}
                                        <span className='text-muted'> {selectedStaking.title}</span>
                                    </div>
                                    <div className='col-md-12'>
                                        <Form.Group className='mb-3 mt-3' controlId='name'>
                                            <label className='form-label'>Activity duration (days)</label>
                                            <div className='durations-wrapper'>
                                                <ul className='durations-list'>
                                                    {selectedStaking.durations.map((duration, index) => (
                                                        <li
                                                            key={index}
                                                            className={`duration ${
                                                                selectedDuration === duration && 'active'
                                                            }`}
                                                            onClick={() => setSelectedDuration(duration)}>
                                                            {duration} Days
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Form.Group>

                                        <Form.Group className='mb-3 mt-3' controlId='name'>
                                            <label className='form-label'>Lock amount</label>
                                            <Controller
                                                control={control}
                                                name='amount'
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control
                                                        onChange={onChange}
                                                        value={value.replace(/,/g, '.')}
                                                        isInvalid={errors.amount}
                                                        placeholder='Lock amount'
                                                        type='text'
                                                    />
                                                )}
                                            />
                                            {errors?.amount && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.amount.message}
                                                </Form.Control.Feedback>
                                            )}
                                            {userData && (
                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                    <span className='small text-muted'>Available balance</span>
                                                    <span className=''>
                                                        <a
                                                            href='!#'
                                                            className='text-secondary'
                                                            onClick={(e) => changeAmount(e, currentStakingCoinUserBalance)}>
                                                            {currentStakingCoinUserBalance} {selectedStaking.coin}
                                                        </a>
                                                    </span>
                                                </div>
                                            )}
                                            <div className='d-flex align-items-center justify-content-between mu-3'>
                                                <span className='small text-muted'>Min. amount</span>
                                                <span className=''>
                                                    <a
                                                        href='!#'
                                                        className='text-secondary'
                                                        onClick={(e) =>
                                                            changeAmount(e, parseFloat(selectedStaking.min_amount))
                                                        }>
                                                        {parseFloat(selectedStaking.min_amount)} {selectedStaking.coin}
                                                    </a>
                                                </span>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between mu-3'>
                                                <span className='small text-muted'>Max. amount</span>
                                                <span className=''>
                                                    <a
                                                        href='!#'
                                                        className='text-secondary'
                                                        onClick={(e) =>
                                                            changeAmount(e, parseFloat(selectedStaking.max_amount))
                                                        }>
                                                        {parseFloat(selectedStaking.max_amount)} {selectedStaking.coin}
                                                    </a>
                                                </span>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-xl-6'>
                                    <div className='card mb-3'>
                                        <div className='card-body'>
                                            <div className='checkout-sidebar'>
                                                <div className='checkout-sidebar-price-table '>
                                                    <table className='table'>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lock amount</td>
                                                                <td className='text-end'>
                                                                    {parseFloat(watchAmount)} {selectedStaking.coin}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Duration</td>
                                                                <td className='text-end'>{selectedDuration} Days</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Interest</td>
                                                                <td className='text-end'>
                                                                    {parseFloat(selectedStaking.apy)}% Annualized
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Expected interest</td>
                                                                <td className='text-end'>
                                                                    <span className='color-price-up'>
                                                                        {calculatedStaking?.expected_profit &&
                                                                            `${calculatedStaking.expected_profit} ${calculatedStaking.expected_profit_currency}`}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {isLoading ? (
                                                <Button
                                                    type='submit'
                                                    className='btn btn-primary py-2 fs-5 w-100 mt-2 btn-loading'
                                                    disabled>
                                                    Processing...
                                                    <div className='spinner-border text-light' role='status'></div>
                                                </Button>
                                            ) : (
                                                <>
                                                    {successfullyExchanged ? (
                                                        <Button
                                                            type='submit'
                                                            className='btn flex-fill btn-light-success py-2 fs-5 px-5 w-100'>
                                                            <i className='icofont-check-circled'></i> Done!
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type='submit'
                                                            className='btn btn-primary py-2 fs-5 w-100 mt-2'
                                                            disabled={!isAbleToSubmit()}>
                                                            Confirm staking
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal.Dialog>
        </Modal>
    )
}

export default StakingTableListModal
