import React from 'react'

import ExchangeCard from './ExchangeCard'
import ExchangeChart from './ExchangeChart'
import ExchangeHistory from './ExchangeHistory'

import { useGetCryptoExchangeHistoryQuery } from '../../redux/services/exchangeCryptoService'
import { useGetUserQuery } from '../../redux/services/authService'

const Exchange = () => {
    const { currentData: cryptoExchangeHistoryData } = useGetCryptoExchangeHistoryQuery()
    const { currentData: userData } = useGetUserQuery()

    return (
        <div className='container-xxl bs-container-reset pd-left-xsmall'>
            <div className='row g-3 mb-3'>
                <div className='col-xxl-8 order-2 order-xxl-1'>
                    <ExchangeChart />
                </div>
                <div className='col-xxl-4 order-1'>
                    <ExchangeCard mainFiatCurrency={userData?.user?.main_fiat_currency} />
                </div>
            </div>
            <div className='row g-3 mb-3'>
                <div className='col-xxl-12'>
                    <ExchangeHistory
                        cryptoExchangeHistoryData={cryptoExchangeHistoryData}
                        mainFiatCurrency={userData?.user?.main_fiat_currency}
                    />
                </div>
            </div>
        </div>
    )
}

export default Exchange
