import React from 'react'
import LongTermTable from './LongTermsTable'

const LongTerm = () => {
    return (
        <div className='container-xxl bs-container-reset pd-left-xsmall'>
            <div className='row g-3 mb-3 row-deck'>
                <LongTermTable />
            </div>
        </div>
    )
}

export default LongTerm
