import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from "./apiBaseQueryFactory";

export const copyTradingApi = createApi({
    reducerPath: 'copyTradingApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        getCopyTradingTraders: build.query({
            query: () => ({
                url: 'copy-trading/traders',
                method: 'GET'
            })
        }),
        getCopyTradingFollowedTraders: build.query({
            query: () => ({
                url: 'copy-trading/followed-traders',
                method: 'GET'
            })
        }),
        copyTrading: build.mutation({
            query: (data) => ({
                url: 'copy-trading',
                method: 'POST',
                body: data
            })
        }),
        cancelCopyTrading: build.mutation({
            query: (data) => ({
                url: 'copy-trading/cancel',
                method: 'POST',
                body: data
            })
        }),
    })
})

export const {
    useGetCopyTradingTradersQuery,
    useCopyTradingMutation,
    useGetCopyTradingFollowedTradersQuery,
    useCancelCopyTradingMutation
} = copyTradingApi
