import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import Select, { components } from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { useUpdateAutoTradingMutation } from '../../redux/services/autoTradingService'
import { UpdateTradingSchema } from '../../helpers/forms/schema/base'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'

import { simultaneousTrades as trades, riskLevels as risks } from '../../helpers/cryptocurrency'

const AutoTradingEditModal = (props) => {
    const {
        tradingModalOpen,
        setTradingModalOpen,
        selectedTrading,
        refetchTradinglist,
        maxAmount,
        maxSimultaneousTrades,
        maxRiskLevel
    } = props

    const [updateAutoTrading] = useUpdateAutoTradingMutation()

    const [maxTrades, setMaxTrade] = useState(trades.find((trade) => trade.value === `${maxSimultaneousTrades}`))
    const [maxRisk, setMaxRisk] = useState(risks.find((risk) => risk.max_risk_level === `${maxRiskLevel}`))

    const defaultValues = {
        amount: maxAmount
    }
    const OptionComponent = ({ data, ...props }) => {
        const icon = data.icon
        return (
            <components.Option {...props}>
                {icon}
                {data.label}
            </components.Option>
        )
    }

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(UpdateTradingSchema) })

    const setDefaultValues = () => {
        reset()
        setMaxTrade(trades.find((trade) => trade.value === `${maxSimultaneousTrades}`))
        setMaxRisk(risks.find((risk) => risk.max_risk_level === `${maxRiskLevel}`))
    }

    const handleCloseModal = () => {
        setTradingModalOpen(false)
        setDefaultValues()
    }

    const onSubmit = async (data) => {
        try {
            const result = await updateAutoTrading({
                max_amount: data.amount,
                max_simultaneous_trades: maxTrades.value,
                max_risk_level: maxRisk.max_risk_level,
                tradeId: selectedTrading.id
            })
            if (result.error) {
                setError('apiError', {
                    type: 'manual',
                    message: result.error.data.message
                })
            } else {
                refetchTradinglist()
                toast.success('Trade updated!')
                handleCloseModal()
            }
        } catch (error) {
            setError('apiError', {
                type: 'manual',
                message: error.data.message
            })
        }
    }

    return (
        <Modal
            className='modal fade'
            id='icoModal'
            show={tradingModalOpen}
            onHide={() => {
                handleCloseModal()
            }}
            fullscreen='sm-down bs-modal-xs'>
            <Modal.Dialog className='' style={{ maxWidth: '900px' }}>
                <div className='modal-content'>
                    <Modal.Header className='modal-header' closeButton>
                        <h5 className='modal-title'>Auto trading</h5>
                    </Modal.Header>
                    <Modal.Body className='modal-body custom_setting'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className='col-md-12'>
                                        <Form.Group className='mb-3' controlId='amount'>
                                            <label className='form-label'>Max trade amount</label>
                                            <Controller
                                                control={control}
                                                name='amount'
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control
                                                        onChange={onChange}
                                                        value={value ? value.replace(/,/g, '.') : ''}
                                                        isInvalid={errors.amount}
                                                        placeholder='Enter the amount'
                                                        type='text'
                                                    />
                                                )}
                                            />
                                            {errors?.amount && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.amount.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group className='mb-3 mt-3'>
                                            <label className='form-label'>Max simultaneous trades</label>
                                            <Select
                                                name='maxTrades'
                                                options={trades}
                                                value={maxTrades}
                                                onChange={setMaxTrade}
                                                isClearable={false}
                                                components={{
                                                    Option: OptionComponent
                                                }}
                                            />
                                            {errors?.maxTrades && (
                                                <div className='invalid-feedback' style={{ display: 'block' }}>
                                                    {errors.maxTrades.message}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className='mb-3 mt-3'>
                                            <label className='form-label'>Max risk level</label>
                                            <Select
                                                name='maxRisk'
                                                options={risks}
                                                value={maxRisk}
                                                onChange={setMaxRisk}
                                                isClearable={false}
                                                components={{
                                                    Option: OptionComponent
                                                }}
                                            />
                                            {errors?.maxRisk && (
                                                <div className='invalid-feedback' style={{ display: 'block' }}>
                                                    {errors.maxRisk.message}
                                                </div>
                                            )}
                                        </Form.Group>
                                        {errors?.apiError && (
                                            <div className='invalid-feedback mb-3 text-center' style={{ display: 'block' }}>
                                                {errors.apiError.message}
                                            </div>
                                        )}
                                        <Button type='submit' className='btn flex-fill py-2 fs-5 px-5 w-100'>
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal.Dialog>
        </Modal>
    )
}

export default AutoTradingEditModal
