import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from "./apiBaseQueryFactory";

export const stakingApi = createApi({
    reducerPath: 'stakingApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        getStaking: build.query({
            query: () => ({
                url: 'staking-assets',
                method: 'GET'
            })
        }),
        openStaking: build.mutation({
            query: (data) => ({
                url: 'staking',
                method: 'POST',
                body: data
            })
        }),
        calculateStaking: build.mutation({
            query: (data) => ({
                url: 'staking/calculate',
                method: 'POST',
                body: data
            })
        }),
        getStakingActive: build.query({
            query: () => ({
                url: 'staking/active',
                method: 'GET'
            })
        }),
        getStakingProfit: build.query({
            query: () => ({
                url: 'staking/profit',
                method: 'GET'
            })
        }),
        getStakingAmounts: build.query({
            query: () => ({
                url: 'staking/amounts',
                method: 'GET'
            })
        }),
        getStakingRewarded: build.query({
            query: () => ({
                url: 'staking/rewarded',
                method: 'GET'
            })
        }),
        deleteStaking: build.mutation({
            query: (stakingId) => ({
                url: `staking/${stakingId}`,
                method: 'DELETE'
            })
        })
    })
})

export const {
    useGetStakingActiveQuery,
    useGetStakingQuery,
    useOpenStakingMutation,
    useGetStakingProfitQuery,
    useGetStakingAmountsQuery,
    useGetStakingRewardedQuery,
    useDeleteStakingMutation,
    useCalculateStakingMutation
} = stakingApi
