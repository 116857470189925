import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Profile from '../../assets/images/profile_av.svg'
import { HeaderText } from '../../helpers/utils'
import { removeTokens } from '../../redux/helpers/utils'
import { useGetUserQuery } from '../../redux/services/authService'

import { clearApiState } from '../../helpers/queryCache'
import { useDispatch } from 'react-redux'

const Header = () => {
    const { currentData: userData } = useGetUserQuery()

    const dispatch = useDispatch()

    const handleUserLogout = () => {
        removeTokens()
        clearApiState(dispatch)
    }

    const userAvatar = () => {
        if (userData?.user.avatar_url) {
            return userData?.user.avatar_url
        } else {
            return Profile
        }
    }

    const handleMobileSidebarOpen = () => {
        const sidebar = document.getElementById('mainsidemenu')
        if (sidebar) {
            if (sidebar.classList.contains('open')) {
                sidebar.classList.remove('open')
            } else {
                sidebar.classList.add('open')
            }
        }
    }

    return (
        <div className='header'>
            <nav className='navbar py-4'>
                <div className='container-xxl border-bottom' style={{ paddingLeft: '0', marginLeft: '0' }}>
                    <div className='body-header d-flex pd-left-xsmall'>
                        <HeaderText />
                    </div>
                    <div style={{ marginLeft: 'auto' }} className='h-right d-flex align-items-center mr-5 mr-lg-0 order-1'>
                        <div className='mx-2 mx-lg-3 header-option-icons'>
                            <Link
                                className='nav-link text-primary collapsed'
                                to={process.env.PUBLIC_URL + '/'}
                                title='wallet'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    x='0px'
                                    y='0px'
                                    width='30px'
                                    height='30px'
                                    viewBox='0 0 64 64'>
                                    <path
                                        className='st1'
                                        d='M15,24c-3.86,0-7,2.691-7,6v20c0,3.309,3.14,6,7,6h41V32H15c-1.598,0-3-0.935-3-2s1.402-2,3-2h5.25 c0,0,1-5,5.75-5s6,5,6,5h22v-4H15z'
                                        style={{ fill: 'var(--primary-color)' }}
                                        data-st='fill:var(--chart-color4)'></path>
                                    <path
                                        className='st0'
                                        d='M42,4c-4.418,0-8,3.582-8,8s3.582,8,8,8c4.417,0,8-3.582,8-8S46.417,4,42,4z M42,16c-2.208,0-4-1.792-4-4 s1.792-4,4-4s4,1.792,4,4S44.208,16,42,16z'></path>
                                    <path
                                        className='st0'
                                        d='M26,20c-4.418,0-8,3.582-8,8h4c0-2.208,1.792-4,4-4s4,1.792,4,4h4C34,23.582,30.418,20,26,20z'></path>
                                </svg>
                            </Link>
                            <Link
                                className='nav-link text-primary collapsed'
                                style={{ marginLeft: '15px' }}
                                to={process.env.PUBLIC_URL + '/'}
                                title='nitifications'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    x='0px'
                                    y='0px'
                                    width='25px'
                                    height='25px'
                                    viewBox='0 0 38 38'>
                                    <path
                                        d='M36,34v-2h-2.98c-0.598-0.363-1.081-3.663-1.4-5.847c-0.588-4.075-1.415-9.798-4.146-13.723  C26.584,12.154,25.599,12,24.5,12c-3.646,0-5.576,1.657-7.106,4.086C15.089,19.746,14,30.126,14,33c0,2.757,2.243,5,5,5  c2.414,0,4.435-1.721,4.898-4H36z'
                                        style={{ fill: 'var(--primary-color)' }}
                                        data-st='fill:var(--chart-color4);'></path>
                                    <path
                                        className='st0'
                                        d='M33.02,32c-0.598-0.363-1.081-3.663-1.4-5.847c-0.851-5.899-2.199-15.254-9.101-17.604  C23.433,7.643,24,6.386,24,5c0-2.757-2.243-5-5-5s-5,2.243-5,5c0,1.386,0.567,2.643,1.482,3.549  c-6.902,2.35-8.25,11.705-9.101,17.604C6.209,27.324,5.991,28.813,5.733,30h2.042c0.192-0.961,0.376-2.127,0.586-3.562  C9.36,19.501,10.73,10,19,10c8.27,0,9.64,9.501,10.641,16.442c0.386,2.636,0.682,4.394,1.108,5.558H2v2h12.101  c0.464,2.279,2.485,4,4.899,4c2.415,0,4.435-1.721,4.899-4H36v-2H33.02z M19,8c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3  S20.654,8,19,8z M19,36c-1.304,0-2.416-0.836-2.829-2h5.658C21.416,35.164,20.304,36,19,36z'></path>
                                </svg>
                                <span className='pulse-ring'></span>
                            </Link>
                        </div>
                        <Dropdown className='dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center mx-2 mx-lg-3'>
                            <Dropdown.Toggle
                                as='dd'
                                className='nav-link dropdown-toggle pulse p-0'
                                role='button'
                                data-bs-toggle='dropdown'
                                data-bs-display='static'>
                                <img className='avatar lg rounded-circle img-thumbnail' src={userAvatar()} alt='profile' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0'>
                                <div className='card border-0 w280'>
                                    <div className='card-body pb-0'>
                                        <div className='d-flex py-1'>
                                            <img className='avatar rounded-circle' src={userAvatar()} alt='profile' />
                                            <div className='flex-fill ms-3'>
                                                <p className='mb-0'>
                                                    <span className='font-weight-bold'>{userData?.user.name}</span>
                                                </p>
                                                <small className=''>{userData?.user.email}</small>
                                            </div>
                                        </div>

                                        <div>
                                            <hr className='dropdown-divider border-dark' />
                                        </div>
                                    </div>
                                    <div className='list-group m-2 '>
                                        <Link
                                            to={'https://zfrmz.com/7y1fRA5uNSnCmYwlOL9U'}
                                            className='list-group-item list-group-item-action border-0 '>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 38 38" className="me-3">
                                                <path xmlns="http://www.w3.org/2000/svg" d="M36.15,38H1.85l0.16-1.14c0.92-6.471,3.33-7.46,6.65-8.83c0.43-0.17,0.87-0.36,1.34-0.561  c0.19-0.08,0.38-0.17,0.58-0.26c1.32-0.61,2.14-1.05,2.64-1.45c0.18,0.48,0.47,1.13,0.93,1.78C15.03,28.78,16.53,30,19,30  c2.47,0,3.97-1.22,4.85-2.46c0.46-0.65,0.75-1.3,0.931-1.78c0.5,0.4,1.319,0.84,2.64,1.45c0.2,0.09,0.39,0.17,0.58,0.26  c0.47,0.2,0.91,0.391,1.34,0.561c3.32,1.37,5.73,2.359,6.65,8.83L36.15,38z M20,13v4h-2v-4H20z" style={{ fill: 'var(--primary-color)' }} data-st="fill:var(--chart-color4);"></path>
                                                <path xmlns="http://www.w3.org/2000/svg" className="st0" d="M21.67,17.34C21.22,18.27,20.29,19,19,19s-2.22-0.73-2.67-1.66l-1.79,0.891C15.31,19.78,16.88,21,19,21  s3.69-1.22,4.46-2.77L21.67,17.34z M15,10.85c-0.61,0-1.1,0.38-1.1,1.65s0.49,1.65,1.1,1.65s1.1-0.38,1.1-1.65S15.61,10.85,15,10.85  z M23,10.85c-0.61,0-1.1,0.38-1.1,1.65s0.489,1.65,1.1,1.65s1.1-0.38,1.1-1.65S23.61,10.85,23,10.85z M35.99,36.86  c-0.92-6.471-3.33-7.46-6.65-8.83c-0.43-0.17-0.87-0.36-1.34-0.561c-0.19-0.09-0.38-0.17-0.58-0.26c-1.32-0.61-2.14-1.05-2.64-1.45  c-0.521-0.42-0.7-0.8-0.761-1.29C26.55,22.74,28,19.8,28,17V4.56l-1.18,0.21C26.1,4.91,25.58,5,25.05,5  c-1.439,0-2.37-0.24-3.35-0.49C20.71,4.26,19.68,4,18.21,4c-1.54,0-2.94,0.69-3.83,1.9l1.61,1.18C16.5,6.39,17.31,6,18.21,6  c1.22,0,2.08,0.22,3,0.45C22.22,6.71,23.36,7,25.05,7c0.32,0,0.63-0.02,0.95-0.06V17c0,3.44-2.62,7-7,7s-7-3.56-7-7V6.29  C12.23,5.59,13.61,2,18.21,2c1.61,0,2.76,0.28,3.88,0.55C23.06,2.78,23.98,3,25.05,3C26.12,3,27.19,2.74,28,2.47V0.34  C27.34,0.61,26.17,1,25.05,1c-0.83,0-1.6-0.18-2.49-0.4C21.38,0.32,20.05,0,18.21,0c-5.24,0-7.64,3.86-8.18,5.89L10,17  c0,2.8,1.45,5.74,3.98,7.47c-0.06,0.49-0.24,0.87-0.76,1.29c-0.5,0.4-1.32,0.84-2.64,1.45c-0.2,0.09-0.39,0.18-0.58,0.26  c-0.47,0.2-0.91,0.391-1.34,0.561c-3.32,1.37-5.73,2.359-6.65,8.83L1.85,38h34.3L35.99,36.86z M4.18,36c0.81-4.3,2.28-4.9,5.24-6.12  c0.62-0.25,1.29-0.53,2-0.86c1.09-0.5,2.01-0.949,2.73-1.479c0.8-0.56,1.36-1.22,1.64-2.12C16.76,25.78,17.83,26,19,26  s2.24-0.22,3.21-0.58c0.28,0.9,0.84,1.561,1.64,2.12c0.721,0.53,1.641,0.979,2.73,1.479c0.71,0.33,1.38,0.61,2,0.86  c2.96,1.22,4.43,1.83,5.24,6.12H4.18z"></path>
                                            </svg>
                                            Customer Service
                                        </Link>
                                        <Link
                                            to={'/security-page'}
                                            className='list-group-item list-group-item-action border-0 '>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                x='0px'
                                                y='0px'
                                                width='24px'
                                                height='24px'
                                                viewBox='0 0 32 32'
                                                className='me-3'>
                                                <path
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    d='M15.5,27.482C5.677,24.8,4.625,10.371,4.513,7.497C11.326,7.402,14.5,5.443,15.5,4.661  c0.999,0.782,4.174,2.742,10.986,2.836C26.375,10.371,25.323,24.8,15.5,27.482z'
                                                    style={{ fill: 'var(--primary-color)' }}
                                                    data-st='fill:var(--chart-color4);'></path>
                                                <path
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    className='st2'
                                                    d='M14.13,21.5c-0.801,0-1.553-0.311-2.116-0.873c-0.57-0.57-0.883-1.327-0.881-2.132  c0.001-0.8,0.314-1.55,0.879-2.11c0.555-0.563,1.297-0.876,2.093-0.885c0.131-0.001,0.256-0.054,0.348-0.146l4.63-4.63  c0.388-0.38,0.879-0.583,1.416-0.583s1.028,0.203,1.42,0.587c0.373,0.373,0.58,0.875,0.58,1.413c0,0.531-0.207,1.03-0.584,1.406  l-4.64,4.641c-0.094,0.095-0.146,0.222-0.146,0.354c0,0.782-0.311,1.522-0.873,2.087C15.693,21.189,14.938,21.5,14.13,21.5z'></path>
                                                <path
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    className='st0'
                                                    d='M15.5,4c0,0-2.875,3-11.5,3c0,0,0,18,11.5,21C27,25,27,7,27,7C18.375,7,15.5,4,15.5,4z M15.5,26.984  C6.567,24.43,5.217,11.608,5.015,7.965C11.052,7.797,14.213,6.15,15.5,5.251c1.287,0.899,4.448,2.545,10.484,2.713  C25.782,11.608,24.434,24.43,15.5,26.984z M22.27,10.37c-0.479-0.47-1.1-0.73-1.77-0.73s-1.29,0.261-1.77,0.73L14.1,15  c-0.92,0.01-1.79,0.37-2.44,1.03c-1.37,1.358-1.37,3.579,0,4.95c0.66,0.66,1.54,1.02,2.47,1.02c0.94,0,1.82-0.359,2.479-1.02  c0.66-0.66,1.021-1.53,1.021-2.44l4.64-4.64C22.74,13.43,23,12.81,23,12.14C23,11.47,22.74,10.84,22.27,10.37z M21.561,13.2  l-4.949,4.95c0.1,0.75-0.13,1.539-0.71,2.119C15.41,20.76,14.77,21,14.13,21c-0.64,0-1.28-0.24-1.76-0.73  c-0.98-0.979-0.98-2.56,0-3.539c0.49-0.489,1.12-0.729,1.76-0.729c0.12,0,0.24,0.01,0.36,0.03l4.949-4.95  c0.291-0.3,0.681-0.44,1.061-0.44s0.77,0.141,1.061,0.44C22.15,11.66,22.15,12.61,21.561,13.2z M19.79,12.14l0.71,0.7l-5.02,5.021  c0.27,0.56,0.18,1.238-0.29,1.699c-0.58,0.59-1.53,0.59-2.12,0c-0.58-0.58-0.58-1.529,0-2.119c0.47-0.461,1.16-0.562,1.71-0.291  L19.79,12.14z M16,11H9v-1h7V11z M14,13H9v-1h5V13z'></path>
                                            </svg>
                                            Settings
                                        </Link>
                                        <Link
                                            to={'/login'}
                                            className='list-group-item list-group-item-action border-0'
                                            onClick={() => handleUserLogout()}>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                x='0px'
                                                y='0px'
                                                width='24px'
                                                height='24px'
                                                viewBox='0 0 24 24'
                                                className='me-3'>
                                                <rect
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    className='st0'
                                                    width='24'
                                                    height='24'
                                                    style={{ fill: 'none' }}
                                                    fill='none'></rect>
                                                <path
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    d='M20,4c0-1.104-0.896-2-2-2H6C4.896,2,4,2.896,4,4v16c0,1.104,0.896,2,2,2h12  c1.104,0,2-0.896,2-2V4z'
                                                    style={{ fill: 'var(--primary-color)' }}
                                                    data-st='fill:var(--chart-color4);'></path>
                                                <path
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    className='st0'
                                                    d='M15,6.81v2.56c0.62,0.7,1,1.62,1,2.63c0,2.21-1.79,4-4,4s-4-1.79-4-4c0-1.01,0.38-1.93,1-2.63V6.81  C7.21,7.84,6,9.78,6,12c0,3.31,2.69,6,6,6c3.31,0,6-2.69,6-6C18,9.78,16.79,7.84,15,6.81z M13,6.09C12.68,6.03,12.34,6,12,6  s-0.68,0.03-1,0.09V12h2V6.09z'></path>
                                            </svg>
                                            Logout
                                        </Link>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <button
                        className='navbar-toggler p-0 border-0 menu-toggle order-3'
                        type='button'
                        onClick={() => handleMobileSidebarOpen()}>
                        <span className='fa fa-bars'></span>
                    </button>
                </div>
            </nav>
        </div>
    )
}

export default Header
