import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const autoTrading = createApi({
    reducerPath: 'autoTrading',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        createAutoTrading: build.mutation({
            query: (data) => ({
                url: 'auto-trading',
                method: 'POST',
                body: data
            })
        }),
        updateAutoTrading: build.mutation({
            query: ({ max_amount, max_simultaneous_trades, max_risk_level, tradeId }) => ({
                url: `auto-trading/${tradeId}`,
                method: 'PUT',
                body: { max_amount, max_simultaneous_trades, max_risk_level }
            })
        }),
        runAutoTrading: build.mutation({
            query: ({ run, tradeId }) => ({
                url: `auto-trading/${tradeId}/run`,
                method: 'POST',
                body: { run }
            })
        }),
        getAutoTradingList: build.query({
            query: () => ({
                url: 'auto-trading',
                method: 'GET'
            })
        }),
        deleteAutoTrading: build.mutation({
            query: ({ tradeId }) => ({
                url: `auto-trading/${tradeId}`,
                method: 'DELETE'
            })
        })
    })
})

export const {
    useCreateAutoTradingMutation,
    useUpdateAutoTradingMutation,
    useRunAutoTradingMutation,
    useDeleteAutoTradingMutation,
    useGetAutoTradingListQuery
} = autoTrading
