import React from 'react'
import { Link } from 'react-router-dom'

const appLogo = require('../../assets/images/logo/coiniy_logo.png')

const AuthHeader = () => {
    return (
        <div className='container-xxl'>
            <div className='row align-items-center'>
                <div className='col'>
                    <Link to='/' className='d-flex align-item-center'>
                        <img src={appLogo} className='img-fluid app-logo-wrapper' alt='App logo' />
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default AuthHeader
