import React, { useEffect, useState } from 'react'

const SignalsCardOpenTradesCountdown = ({ endTime, refetchTradesData }) => {
    const [countdown, setCountdown] = useState('')

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime()
            const endDate = new Date(endTime).getTime()
            const distance = endDate - now

            if (distance <= 0) {
                clearInterval(interval)
                setCountdown('Signal closed')
                refetchTradesData()
            } else {
                const days = Math.floor(distance / (1000 * 60 * 60 * 24))
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                const seconds = Math.floor((distance % (1000 * 60)) / 1000)

                setCountdown(() => (
                    <>
                        <span>Ends in: </span>
                        <span className='countdown-time'>{`${days}d ${hours}h ${minutes}m ${seconds}s`}</span>
                    </>
                ))
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
        // eslint-disable-next-line
    }, [endTime])

    return <div>{countdown}</div>
}
export default SignalsCardOpenTradesCountdown
