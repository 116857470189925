import React from 'react'
import { Route, Routes } from 'react-router-dom'

import AuthHeader from '../component/Comman/AuthHeader'

import Login from '../component/Auth/Login'
import Signup from '../component/Auth/Register'
import ForgotPassword from '../component/Auth/ForgotPassword'
import ErrorPage from '../component/Auth/ErrorPage'
import ResetPassword from '../component/Auth/ResetPassword'

const AuthIndex = () => {
    return (
        <div className='main p-2 py-3 p-xl-5'>
            <AuthHeader />
            <Routes>
                <Route exact path={'/login'} element={<Login />} />
                <Route exact path={'/register'} element={<Signup />} />
                <Route exact path={'/forgot-password'} element={<ForgotPassword />} />
                <Route exact path={'/reset-password'} element={<ResetPassword />} />
                <Route exact path={'/error-404'} element={<ErrorPage />} />
            </Routes>
        </div>
    )
}

export default AuthIndex
