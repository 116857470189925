import React from 'react'

import { Tooltip } from 'react-tooltip'

const StakingSummary = (props) => {
    const { coin, amount, fiat_currency, percentage, value } = props

    return (
        <div className='col col-summary-wrapper'>
            <div className='card'>
                <div className='card-body d-flex align-items-center'>
                    <div className='flex-fill text-truncate'>
                        <div className='card-summary-wrapper'>
                            <img
                                src={require(`../../assets/images/coin/${coin}.png`)}
                                alt='BTC'
                                className='img-fluid avatar mx-1'
                            />
                            <div className='w-100 ml-1'>
                                <span className='h6 fw-bold d-block' style={{ marginBottom: '0' }}>
                                    {coin}
                                </span>
                                <div className='d-flex justify-content-between'>
                                    <div className='price-block'>
                                        <span className='fs-6 fw-bold color-price-summary'>{parseFloat(amount)}</span>
                                        <span className='small text-muted px-2'>
                                            ≈ {parseFloat(value).toFixed(2)} {fiat_currency}
                                        </span>
                                    </div>
                                    <div className='price-report'>
                                        {(percentage * 100).toFixed(2) >= 60 ? (
                                            <>
                                                <span
                                                    className='small text-success'
                                                    data-tooltip-id='success-tooltip'
                                                    data-tooltip-content={`${parseFloat(
                                                        (percentage * 100).toFixed(2)
                                                    )} % of your ${coin} wallet is now actively earning for you.`}>
                                                    <i className='fa fa-question-circle'></i>{' '}
                                                    {parseFloat((percentage * 100).toFixed(2))}%
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <span
                                                    className='small text-danger'
                                                    data-tooltip-id='danger-tooltip'
                                                    data-tooltip-content={`Make your assets work harder! Right now, only ${parseFloat(
                                                        (percentage * 100).toFixed(2)
                                                    )} % of your ${coin} wallet is generating income.`}>
                                                    <i className='fa fa-question-circle'></i>{' '}
                                                    {parseFloat((percentage * 100).toFixed(2))}%
                                                </span>
                                            </>
                                        )}
                                        <Tooltip id='success-tooltip' />
                                        <Tooltip id='danger-tooltip' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default StakingSummary
