import React from 'react'
import StaterPageImage from '../../assets/images/no-data.svg'

const EmptyReferralProgram = () => {
    return (
        <div className='col-12'>
            <div className='card mb-3'>
                <div className='card-body text-center p-5'>
                    <img src={StaterPageImage} className='img-fluid mx-size' alt='There are no records to display' />
                    <div className='mt-4 mb-2'>
                        <div className='invalid-feedback mb-3 text-center h5' style={{ display: 'block' }}>
                            Referral Program is not available on demo account.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmptyReferralProgram
