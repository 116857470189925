import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const newsfeedApi = createApi({
    reducerPath: 'newsfeedApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        createPost: build.mutation({
            query: ({ content, coverUrl }) => ({
                url: 'newsfeed/post',
                method: 'POST',
                body: {
                    content,
                    cover_url: coverUrl
                }
            })
        }),
        createPostComment: build.mutation({
            query: ({ content, postId }) => ({
                url: `newsfeed/post/${postId}/comment`,
                method: 'POST',
                body: {
                    content,
                }
            })
        }),
        getPost: build.query({
            query: ({ postId }) => {
                let url = `newsfeed/post/${postId}`
                return {
                    url,
                    method: 'GET'
                }
            }
        }),
        getPosts: build.query({
            query: ({ limit, postId, cursor, tag }) => {
                let url = `newsfeed/post?limit=${limit}`
                if (postId) {
                    url += `&postId=${postId}`
                }
                if (cursor) {
                    url += `&cursor=${cursor}`
                }
                if (tag) {
                    url += `&tag=${tag}`
                }
                return {
                    url,
                    method: 'GET'
                }
            }
        }),
        getPostComments: build.query({
            query: (postId) => {
                let url = `newsfeed/post/${postId}/comment`
                return {
                    url,
                    method: 'GET'
                }
            }
        }),
        getLastPostComments: build.query({
            query: ({postId, count = 3, cursor = null}) => {
                let url = `newsfeed/post/${postId}/comment/last?count=${count}&cursor=${cursor ? cursor : ''}`
                return {
                    url,
                    method: 'GET'
                }
            }
        }),
        uploadMedia: build.mutation({
            query: (formData) => ({
                url: 'newsfeed/media',
                method: 'POST',
                body: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        }),
        likePost: build.mutation({
            query: (postId) => ({
                url: `newsfeed/post/${postId}/like`,
                method: 'POST'
            })
        }),
        getIsLikedPost: build.query({
            query: (postId) => ({
                url: `newsfeed/post/${postId}/like`,
                method: 'GET'
            })
        }),
        dislikePost: build.mutation({
            query: (postId) => ({
                url: `newsfeed/post/${postId}/dislike`,
                method: 'POST'
            })
        }),
    })
})

export const {
    useCreatePostMutation,
    useGetPostsQuery,
    useGetPostQuery,
    useGetPostCommentsQuery,
    useUploadMediaMutation,
    useLikePostMutation,
    useDislikePostMutation,
    useCreatePostCommentMutation,
    useGetLastPostCommentsQuery,
    useGetIsLikedPostQuery
} = newsfeedApi
