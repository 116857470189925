import React from 'react'
import StaterPageImage from '../../assets/images/no-data.svg'

const EmptyProfitData = () => {
    return (
        <div className='card-body text-center p-5'>
            <img src={StaterPageImage} className='img-fluid mx-size' alt='There are no records to display' />
            <div className='mt-4 mb-2'>
                <span className='text-muted'>
                    To start using the software and make profits, you need to add funds to your account. This step is quick
                    and secure. Right after that, you can start making profit!.
                </span>
            </div>
        </div>
    )
}

export default EmptyProfitData
