import React, { useEffect, useState } from 'react'

import { Modal } from 'react-bootstrap'
import { Form, Tab, Button, FloatingLabel } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { useGenerateRecoveryKeyMutation, useShowUserRecoveryKeyMutation } from '../../redux/services/userService'
import { useGetUserQuery } from '../../redux/services/authService'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const AccountRecoveryKey = () => {
    const [openModal, setOpenModal] = useState(false)
    const [recoveryKey, setRecoveryKey] = useState('')

    const [generateRecoveryKey, { isLoading }] = useGenerateRecoveryKeyMutation()
    const [recoveryKeyData, {}] = useShowUserRecoveryKeyMutation()
    const { currentData: userData, refetch: refetchUserData } = useGetUserQuery()

    useEffect(() => {
        refetchUserData()
    }, [recoveryKey])

    const defaultValues = {
        password: ''
    }

    const {
        control,
        setError,
        handleSubmit,
        formState: { errors }
    } = useForm({ defaultValues })

    const KEY_GENERATED = false

    const handleCloseModal = () => {
        MySwal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you wrote down the recovery key? Once you close this window, the action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Close',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false
        }).then(async function (result) {
            if (!result.value) {
                return null
            }
            if (result.isConfirmed) {
                setOpenModal(false)
            }
        })
    }

    const handleGenerateRecoveryKey = async () => {
        try {
            const result = await generateRecoveryKey({})
            setRecoveryKey(result.data.data.recoveryKey)
            if (result.error) {
            } else {
                setOpenModal(true)
            }
        } catch (error) {
            setError('password', {
                type: 'manual',
                message: error.error.data.message
            })
        }
    }

    const onSubmit = async (data) => {
        try {
            const result = await recoveryKeyData({ current_password: data.password })
            if (!result.error) {
                setRecoveryKey(result.data.data.recoveryKey)
            } else {
                setError('password', {
                    type: 'manual',
                    message: result.error.data.message
                })
            }
        } catch (error) {
            setError('password', {
                type: 'manual',
                message: error.error.data.message
            })
        }
    }

    return (
        <>
            <div className='py-2 mb-2'>
                <div className='row justify-content-between'>
                    <div className='col-lg-4 col-xl-4'>
                        <div className='d-flex'>
                            <i className='icofont-key fs-5 text-primary'></i>
                            <div className='d-flex flex-column px-2'>
                                <span className='fw-bold'>Recovery key</span>
                                <span className='text-muted small'>Recovery key to protect you account</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <div className='d-flex align-items-center'>
                            {recoveryKey ? (
                                <>
                                    <span className='text-muted'>{recoveryKey}</span>
                                </>
                            ) : (
                                <>
                                    {userData?.user?.recovery_key_generated ? (
                                        <>
                                            <Form onSubmit={handleSubmit(onSubmit)} className='rec-form'>
                                                <Form.Group controlId='password'>
                                                    <Controller
                                                        control={control}
                                                        name='password'
                                                        render={({ field: { onChange, value } }) => (
                                                            <FloatingLabel
                                                                controlId='password'
                                                                label='Enter current password'>
                                                                <Form.Control
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    isInvalid={errors.password}
                                                                    placeholder='Password'
                                                                    type='password'
                                                                    className='rec-input'
                                                                />
                                                                {errors?.password && (
                                                                    <Form.Control.Feedback type='invalid'>
                                                                        {errors.password.message}
                                                                    </Form.Control.Feedback>
                                                                )}
                                                            </FloatingLabel>
                                                        )}
                                                    />
                                                </Form.Group>
                                                <div className='btn-wrapper'>
                                                    <button type='submit' className='btn btn-primary'>
                                                        Show
                                                    </button>
                                                </div>
                                            </Form>
                                        </>
                                    ) : (
                                        <>
                                            <>
                                                <i className='icofont-close-circled fs-5 text-danger'></i>
                                                <span className='px-2'>Not generated</span>
                                            </>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        {userData?.user?.recovery_key_generated ? (
                            <div style={{ textAlign: 'right' }}>
                                <i className='icofont-check-circled fs-5 text-success'></i>
                                <span className='px-2'>Generated</span>
                            </div>
                        ) : (
                            <>
                                {isLoading ? (
                                    <>
                                        <button
                                            type='button'
                                            disabled
                                            className='btn flex-fill btn-light-success py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end mx-2'>
                                            Generating...
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {!KEY_GENERATED && (
                                            <button
                                                type='button'
                                                className='btn flex-fill btn-light-success py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end mx-2'
                                                onClick={handleGenerateRecoveryKey}>
                                                Generate
                                            </button>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <Modal
                    size='lg'
                    className='modal'
                    id='ChangeModal'
                    style={{ display: 'block' }}
                    show={openModal}
                    onHide={() => {
                        setOpenModal(true)
                    }}>
                    <div className='modal-content recovery-wrapper'>
                        <Modal.Header className='modal-header'>
                            <h5 className='modal-title'>Recovery key</h5>
                        </Modal.Header>
                        <Modal.Body className='modal-body'>
                            <div className='info-header'>
                                <i className='icofont-check-circled'></i>
                                <span className='info-title'>Recovery key generated</span>
                            </div>
                            <div>
                                <div className='info-body-warning'>
                                    <i className='icofont-warning'></i>
                                    <span className='text'>
                                        Important: Please read and follow these instructions carefully.
                                    </span>
                                </div>
                                <p>
                                    Your recovery key is a crucial piece of information that allows you to regain access to
                                    your account if you forget your password or encounter any issues. To ensure the security
                                    of your account, please take the following steps:
                                </p>
                                <ol>
                                    <li>Write down your recovery key immediately.</li>
                                    <li>Keep your recovery key in a safe and secure place.</li>
                                    <li>Do not share your recovery key with anyone else.</li>
                                </ol>
                                <p>
                                    Remember, losing your recovery key may result in permanent loss of access to your
                                    account. Take the necessary precautions to protect it.
                                </p>
                            </div>
                            <div role='alert' className='alert alert-secondary recovery-field-wrapper'>
                                {recoveryKey}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='modal-footer'>
                            <button className='btn btn-primary' data-bs-dismiss='modal' onClick={handleCloseModal}>
                                Ok
                            </button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default AccountRecoveryKey
