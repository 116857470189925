import React from 'react'
import { Form, Tab, Button, FloatingLabel } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { RegisterSchema } from '../../helpers/forms/schema/auth'
import { useGetUserQuery, useLoginMutation, useRegisterMutation } from '../../redux/services/authService'
import AuthLoadingButton from './AuthLoadingButton'

const NICKNAME = process.env.REACT_APP_FEATURE_NICKNAME

const Register = () => {
    const [userRegister, { isLoading }] = useRegisterMutation()
    const [userLogin] = useLoginMutation()
    const { refetch } = useGetUserQuery()

    const navigate = useNavigate()

    const saveTokenToCookie = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get('affiliate_token')

        if (token) {
            document.cookie = `affiliate_token=${encodeURIComponent(token)}; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/`
        }
    }

    saveTokenToCookie()

    const getAffiliateTokenFromCookie = () => {
        const cookieName = 'affiliate_token'
        const cookies = document.cookie.split(';')

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()

            if (cookie.startsWith(`${cookieName}=`)) {
                return decodeURIComponent(cookie.substring(cookieName.length + 1))
            }
        }

        return null
    }

    const affiliateToken = getAffiliateTokenFromCookie()

    const defaultValues = {
        name: '',
        surname: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone_number: '',
        terms: false,
        affiliate_token: affiliateToken ? affiliateToken : null,
        ...(NICKNAME === 'true' && { nickname: '' })
    }

    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(RegisterSchema) })

    const onSubmit = async (data) => {
        try {
            const result = await userRegister(data)
            if (!result.error) {
                await userLogin({ email: data.email, password: data.password })
                refetch()
                navigate('/')
            } else {
                if (result.error?.data?.errors) {
                    Object.keys(result.error?.data?.errors).forEach((key) => {
                        setError(key, {
                            type: 'manual',
                            message: result.error?.data?.errors[key][0]
                        })
                    })
                } else {
                    setError('terms', {
                        type: 'manual',
                        message: result?.error?.data?.message
                    })
                }
            }
        } catch (err) {
            Object.keys(err?.response?.data?.errors).forEach((key) => {
                setError(key, {
                    type: 'manual',
                    message: err?.response?.data?.message
                })
            })
        }
    }

    const NicknameComponent = () => {
        if (NICKNAME === 'true') {
            return (
                <>
                    <Form.Group className='mb-3' controlId='nickname'>
                        <Controller
                            control={control}
                            name='nickname'
                            render={({ field: { onChange, value } }) => (
                                <FloatingLabel controlId='nickname' label='Nickname' className='mb-3'>
                                    <Form.Control
                                        onChange={onChange}
                                        value={value}
                                        isInvalid={errors.nickname}
                                        placeholder='Nickname'
                                        type='text'
                                    />
                                    {errors?.nickname && (
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.nickname.message}
                                        </Form.Control.Feedback>
                                    )}
                                </FloatingLabel>
                            )}
                        />
                    </Form.Group>
                </>
            )
        }
    }

    return (
        <div className='body d-flex p-0 p-xl-5'>
            <div className='container'>
                <div className='row g-3'>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center auth-h100'>
                        <div className='d-flex flex-column'>
                            <h1>Create Your Account</h1>
                            <span className='text-muted text-center'>Register with your email address</span>
                            <Tab.Container defaultActiveKey='first'>
                                <Tab.Content className='tab-content mt-4 mb-3'>
                                    <Tab.Pane className='tab-pane fade' id='Email' eventKey='first'>
                                        <div className='card'>
                                            <div className='card-body p-4'>
                                                <Form onSubmit={handleSubmit(onSubmit)}>
                                                    <Form.Group className='mb-3' controlId='name'>
                                                        <Controller
                                                            control={control}
                                                            name='name'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='name'
                                                                    label='Name'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.name}
                                                                        placeholder='Name'
                                                                        type='text'
                                                                    />
                                                                    {errors?.name && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.name.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className='mb-3' controlId='surname'>
                                                        <Controller
                                                            control={control}
                                                            name='surname'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='surname'
                                                                    label='Surname'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.surname}
                                                                        placeholder='Surname'
                                                                        type='text'
                                                                    />
                                                                    {errors?.surname && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.surname.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <NicknameComponent />
                                                    <Form.Group className='mb-3' controlId='emailAddress'>
                                                        <Controller
                                                            control={control}
                                                            name='email'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='email'
                                                                    label='Email address'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.email}
                                                                        placeholder='Email address'
                                                                        type='email'
                                                                    />
                                                                    {errors?.email && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.email.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className='mb-3' controlId='password'>
                                                        <Controller
                                                            control={control}
                                                            name='password'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='password'
                                                                    label='Password'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.password}
                                                                        placeholder='Password'
                                                                        type='password'
                                                                    />
                                                                    {errors?.password && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.password.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className='mb-3' controlId='password_confirmation'>
                                                        <Controller
                                                            control={control}
                                                            name='password_confirmation'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='password_confirmation'
                                                                    label='Confirm password'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.password_confirmation}
                                                                        placeholder='Confirm password'
                                                                        type='password'
                                                                    />
                                                                    {errors?.password_confirmation && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.password_confirmation.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className='mb-3' controlId='phone_number'>
                                                        <Controller
                                                            control={control}
                                                            name='phone_number'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='phone_number'
                                                                    label='Phone number'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.phone_number}
                                                                        placeholder='Phone number'
                                                                        type='text'
                                                                    />
                                                                    {errors?.phone_number && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.phone_number.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <div className='form-check'>
                                                            <input
                                                                className='form-check-input'
                                                                type='checkbox'
                                                                id='terms'
                                                                {...register('terms')}
                                                            />
                                                            <label className='form-check-label' htmlFor='terms'>
                                                                I accept the{' '}
                                                                <Link
                                                                    target='_blank'
                                                                    to='https://coiniy.com/terms-and-conditions'>
                                                                    Terms & Conditions
                                                                </Link>{' '}
                                                                and{' '}
                                                                <Link target='_blank' to='https://coiniy.com/privacy-policy'>
                                                                    Privacy Policy
                                                                </Link>
                                                            </label>
                                                            {errors?.terms && (
                                                                <div className='check-invalid'>{errors.terms.message}</div>
                                                            )}
                                                        </div>
                                                    </Form.Group>
                                                    {isLoading ? (
                                                        <AuthLoadingButton />
                                                    ) : (
                                                        <Button
                                                            type='submit'
                                                            className='btn btn-primary py-2 fs-5 w-100 mt-2'>
                                                            Register
                                                        </Button>
                                                    )}
                                                </Form>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            <div className='text-center'>
                                <span>Already registered? </span>
                                <Link to='/login' title='#'>
                                    <span className='text-secondary text-decoration-underline'>Login</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
