import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        changePassword: build.mutation({
            query: (data) => ({
                url: 'auth/change-password',
                method: 'POST',
                body: data
            })
        }),
        changeEmail: build.mutation({
            query: (data) => ({
                url: 'user/change-email',
                method: 'POST',
                body: data
            })
        }),
        changeMainCurrency: build.mutation({
            query: (data) => ({
                url: 'user/main-fiat-currency',
                method: 'PUT',
                body: data
            })
        }),
        generateRecoveryKey: build.mutation({
            query: (data) => ({
                url: 'user/recovery-key',
                method: 'POST',
                body: data
            })
        }),
        showUserRecoveryKey: build.mutation({
            query: (data) => ({
                url: 'user/recovery-key/current',
                method: 'POST',
                body: data
            })
        }),
        getUserCryptoBalance: build.query({
            query: () => ({
                url: 'balance/crypto',
                method: 'GET'
            })
        }),
        getUserFiatBalance: build.query({
            query: () => ({
                url: 'balance/fiat',
                method: 'GET'
            })
        }),
        getUserTotalProfit: build.query({
            query: () => ({
                url: 'profit/summary',
                method: 'GET'
            })
        }),
        withdrawCrypto: build.mutation({
            query: (data) => ({
                url: 'withdraw-crypto/request',
                method: 'POST',
                body: data
            })
        }),
        uploadAvatar: build.mutation({
            query: (formData) => ({
                url: 'user/avatar',
                method: 'POST',
                body: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        }),
        getWithdrawCryptoRequests: build.query({
            query: () => ({
                url: 'withdraw-crypto',
                method: 'GET'
            })
        }),
        getCryptoDeposits: build.query({
            query: () => ({
                url: 'deposit/crypto',
                method: 'GET'
            })
        }),
        depositCrypto: build.mutation({
            query: ({ currency, amount }) => ({
                url: 'deposit/crypto/address',
                method: 'POST',
                body: { currency, amount }
            })
        }),
        changeCopyTradingFee: build.mutation({
            query: (data) => ({
                url: 'copy-trading/fee',
                method: 'POST',
                body: data
            })
        }),
        getCopyTradingFee: build.query({
            query: () => ({
                url: 'copy-trading/fee',
                method: 'GET'
            })
        })

    })
})

export const {
    useChangePasswordMutation,
    useChangeEmailMutation,
    useGetUserCryptoBalanceQuery,
    useWithdrawCryptoMutation,
    useDepositCryptoMutation,
    useGetUserTotalProfitQuery,
    useGetUserFiatBalanceQuery,
    useChangeMainCurrencyMutation,
    useGenerateRecoveryKeyMutation,
    useShowUserRecoveryKeyMutation,
    useGetWithdrawCryptoRequestsQuery,
    useGetCryptoDepositsQuery,
    useChangeCopyTradingFeeMutation,
    useGetCopyTradingFeeQuery,
    useUploadAvatarMutation,
} = userApi
