import React from 'react'
import DataTable from 'react-data-table-component'
import { Tooltip } from 'react-tooltip'

import { getRiskLevelText, renderRiskDots } from '../../helpers/utils'

import SignalsCardOpenTradesCountdown from './SignalsCardOpenTradesCountdown'

const SignalsCardOpenTrades = (props) => {
    const { openTrades, refetchTradesData } = props

    const columns = () => {
        return [
            {
                name: 'Trade',
                selector: (row) => row.pair,
                cell: (row) => {
                    return (
                        <div>
                            <div>
                                {row.type === 0 ? (
                                    <span className='color-price-up' style={{ marginBottom: '0', marginRight: '7px' }}>
                                        Buy <i className='fa fa-level-up'></i>
                                    </span>
                                ) : (
                                    <span className='color-price-down' style={{ marginBottom: '0', marginRight: '7px' }}>
                                        Sell <i className='fa fa-level-down'></i>
                                    </span>
                                )}
                                <span>{row.pair}</span>
                            </div>
                            <div
                                className='d-flex mt-2 justify-content-center'
                                data-tooltip-id={`tooltip-open-${row.risk_level}`}
                                data-tooltip-content={getRiskLevelText(row.risk_level)}>
                                {renderRiskDots(row.risk_level)}
                            </div>
                            <Tooltip id={`tooltip-open-${row.risk_level}`} />
                        </div>
                    )
                },
                sortable: true
            },
            {
                name: 'Time',
                selector: (row) => row.ends_date_time,
                sortable: true,
                cell: (row) => {
                    return (
                        <SignalsCardOpenTradesCountdown endTime={row.ends_date_time} refetchTradesData={refetchTradesData} />
                    )
                }
            },
            {
                name: 'Investment',
                selector: (row) => row.amount,
                sortable: true,
                cell: (row) => (
                    <>
                        <img
                            src={require(`../../assets/images/coin/${row.base}.png`)}
                            alt={row.base}
                            className='img-fluid avatar mx-1 cc-small-img'
                        />
                        <span>{row.amount}</span>
                    </>
                )
            },
            {
                name: 'Profit',
                selector: (row) => row.profit,
                sortable: true,
                cell: (row) => {
                    const Profit = () => {
                        if (row.profit > 0) {
                            return <span className='color-price-up'>+{row.profit}</span>
                        } else if (row.profit === 0) {
                            return <span>{row.profit}</span>
                        } else if (row.profit < 0) {
                            return <span className='color-price-down'>-{row.profit}</span>
                        } else {
                            return <span>{row.profit}</span>
                        }
                    }
                    return (
                        <>
                            <img
                                src={require(`../../assets/images/coin/${row.base}.png`)}
                                alt={row.base}
                                className='img-fluid avatar mx-1 cc-small-img'
                            />
                            <Profit />
                        </>
                    )
                }
            }
        ]
    }

    return (
        <DataTable
            title='Staking'
            columns={columns()}
            data={openTrades || []}
            defaultSortField='title'
            selectableRows={false}
            highlightOnHover={true}
            pagination
        />
    )
}

export default SignalsCardOpenTrades
