import { ACCOUNT_BALANCE_CHANGED } from "./actions";

const initialState = {
    isBalanceChanged: false,
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_BALANCE_CHANGED:
            return {
                ...state,
                isBalanceChanged: true,
            };
        default:
            return state;
    }
};

export default accountReducer;
