import React from 'react'
import StaterPagedetail from '../../component/Stater Page/StaterPagedetail'

function StaterPage() {
    return (
        <div className='container-xxl'>
            <StaterPagedetail />
        </div>
    )
}
export default StaterPage
