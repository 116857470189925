import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const tradeApi = createApi({
    reducerPath: 'tradeApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        getTrade: build.query({
            query: () => ({
                url: 'trade',
                method: 'GET'
            })
        }),
        openTrade: build.mutation({
            query: ({ amount, signalId }) => ({
                url: `trade/${signalId}`,
                method: 'POST',
                body: { amount }
            })
        }),
        getSignals: build.query({
            query: () => ({
                url: 'signal',
                method: 'GET'
            })
        })
    })
})

export const { useGetTradeQuery, useOpenTradeMutation, useGetSignalsQuery } = tradeApi
