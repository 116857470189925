import { APIUrl, getToken } from '../helpers/utils'
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

function resolveHeaders(headers) {
    headers.set('Accept', 'application/json')

    if (!headers.get('Content-Type')) {
        headers.set('Content-Type', 'application/json')   
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
        headers.delete('Content-Type')
    }

    const accessToken = getToken()

    if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`)
    }

    return headers
}

export default function apiBaseQueryFactory() {
    const baseQuery = fetchBaseQuery({
        baseUrl: APIUrl,
        prepareHeaders: (headers) => resolveHeaders(headers)
    })

    const baseQueryWithReauth = async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions)
        if (result.error && result.error.status === 401 && !['/login', '/register'].includes(window.location.pathname)) {
            window.location.href = '/login'
        }
        return result
    }

    return baseQueryWithReauth
}
