export const isStopLossPriceInvalid = (stopLossPrice, stopLossLongTargetPrice, direction) => {
    if (stopLossPrice === null) {
        return false;
    }

    if (direction === 'long') {
        if (parseFloat(stopLossPrice) > stopLossLongTargetPrice) {
            return true;
        }
    } else {
        if (parseFloat(stopLossPrice) < stopLossLongTargetPrice) {
            return true;
        }
    }

    return false;
}


export const isTakeProfitPriceInvalid = (takeProfitPrice, takeProfitLongTargetPrice, direction) => {
    if (takeProfitPrice === null) {
        return false;
    }

    if (direction === 'long') {
        if (parseFloat(takeProfitPrice) < takeProfitLongTargetPrice) {
            return true;
        }
    } else {
        if (parseFloat(takeProfitPrice) > takeProfitLongTargetPrice) {
            return true;
        }
    }

    return false;
}
