import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const exchangeCryptoApi = createApi({
    reducerPath: 'exchangeCryptoApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        getCryptoExchangeHistory: build.query({
            query: () => ({
                url: 'exchange/history',
                method: 'GET'
            })
        }),
        cryptoExchange: build.mutation({
            query: (data) => ({
                url: 'exchange/crypto-to-crypto',
                method: 'POST',
                body: data
            })
        }),
        cryptoExchangeCalculate: build.mutation({
            query: (data) => ({
                url: 'exchange/crypto-to-crypto/calculate',
                method: 'POST',
                body: data
            })
        })
    })
})

export const { useGetCryptoExchangeHistoryQuery, useCryptoExchangeMutation, useCryptoExchangeCalculateMutation } =
    exchangeCryptoApi
