import React from 'react'
import { Form, Tab, Button, FloatingLabel } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { ResetPasswordSchema } from '../../helpers/forms/schema/auth'
import { useResetPasswordMutation } from '../../redux/services/authService'
import AuthLoadingButton from './AuthLoadingButton'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const ResetPassword = () => {
    const [userResetPassword, { isLoading }] = useResetPasswordMutation()

    const navigate = useNavigate()

    const defaultValues = {
        email: '',
        password: '',
        password_confirmation: ''
    }

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(ResetPasswordSchema) })

    const onSubmit = async (data) => {
        try {
            const urlParams = new URLSearchParams(window.location.search)
            const token = urlParams.get('token')

            const result = await userResetPassword({
                email: data.email,
                password: data.password,
                password_confirmation: data.password_confirmation,
                token
            })
            if (!result.error) {
                MySwal.fire({
                    title: 'Password reset',
                    text: 'Your password has been changed',
                    icon: 'success',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                }).then(() => navigate('/'))
            } else {
                setError('apiError', {
                    type: 'manual',
                    message: result.error.data.message
                })
            }
        } catch (err) {
            setError('password_confirmation', {
                type: 'manual',
                message: err.response.data.message
            })
        }
    }

    return (
        <div className='body d-flex p-0 p-xl-5'>
            <div className='container'>
                <div className='row g-3'>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center auth-h100'>
                        <div className='d-flex flex-column'>
                            <h1>Reset Your Password</h1>
                            <span className='text-muted text-center'>Reset password to your account</span>
                            <Tab.Container defaultActiveKey='first'>
                                <Tab.Content className='tab-content mt-4 mb-3'>
                                    <Tab.Pane className='tab-pane fade' id='Email' eventKey='first'>
                                        <div className='card'>
                                            <div className='card-body p-4'>
                                                <Form onSubmit={handleSubmit(onSubmit)}>
                                                    <Form.Group className='mb-3' controlId='emailAddress'>
                                                        <Controller
                                                            control={control}
                                                            name='email'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='email'
                                                                    label='Email address'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.email}
                                                                        placeholder='Email address'
                                                                        type='email'
                                                                    />
                                                                    {errors?.email && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.email.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className='mb-3' controlId='password'>
                                                        <Controller
                                                            control={control}
                                                            name='password'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='password'
                                                                    label='Password'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.password}
                                                                        placeholder='Password'
                                                                        type='password'
                                                                    />
                                                                    {errors?.password && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.password.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className='mb-3' controlId='password_confirmation'>
                                                        <Controller
                                                            control={control}
                                                            name='password_confirmation'
                                                            render={({ field: { onChange, value } }) => (
                                                                <FloatingLabel
                                                                    controlId='password_confirmation'
                                                                    label='Confirm password'
                                                                    className='mb-3'>
                                                                    <Form.Control
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        isInvalid={errors.password_confirmation}
                                                                        placeholder='Confirm password'
                                                                        type='password'
                                                                    />
                                                                    {errors?.password_confirmation && (
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.password_confirmation.message}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </FloatingLabel>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    {errors?.apiError && (
                                                        <div
                                                            className='invalid-feedback mb-3 text-center'
                                                            style={{ display: 'block' }}>
                                                            {errors.apiError.message}
                                                        </div>
                                                    )}
                                                    {isLoading ? (
                                                        <AuthLoadingButton />
                                                    ) : (
                                                        <Button
                                                            type='submit'
                                                            className='btn btn-primary py-2 fs-5 w-100 mt-2'>
                                                            Reset password
                                                        </Button>
                                                    )}
                                                </Form>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            <div className='text-center'>
                                <Link to='/login' title='#' className='text-primary text-decoration-underline'>
                                    Back to login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
