import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Select, { components } from 'react-select'
import { useForm, Controller } from 'react-hook-form'

import { AutoTradingSchema } from '../../helpers/forms/schema/base'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'

import { useCreateAutoTradingMutation, useGetAutoTradingListQuery } from '../../redux/services/autoTradingService'
import { cryptocurrenciesData, simultaneousTrades as trades, riskLevels as risks } from '../../helpers/cryptocurrency'

const AutoTradingCard = (props) => {
    const { mainFiatCurrency } = props

    const [createAutoTrading] = useCreateAutoTradingMutation()
    const { refetch: refetchTradinglist } = useGetAutoTradingListQuery()

    const updatedCryptoData = cryptocurrenciesData.map((obj) => ({
        value: `${obj.value}-${mainFiatCurrency}`,
        label: `${obj.value} - ${mainFiatCurrency}`,
        currency: `${obj.value}`,
        to_currency: `${mainFiatCurrency}`,
        currency_icon: (
            <img src={require(`../../assets/images/coin/${obj.value}.png`)} alt='BTC' className='img-fluid img-pair mx-1' />
        ),
        to_currency_icon: (
            <img
                src={require(`../../assets/images/coin/${mainFiatCurrency}.png`)}
                alt={mainFiatCurrency}
                className='img-fluid img-pair mx-1'
            />
        )
    }))

    const [selectedPair, setSelectedPair] = useState(updatedCryptoData.find((pair) => pair.value === 'BTC-USD'))
    const [maxTrades, setMaxTrade] = useState(trades.find((trade) => trade.value === '1'))
    const [maxRisk, setMaxRisk] = useState(risks.find((risk) => risk.value === 'Low risk'))

    const PairOptionComponent = ({ data, ...props }) => {
        return (
            <components.Option {...props}>
                {data.currency_icon}
                {data.currency}
                {'  -'}
                {data.to_currency_icon}
                {data.to_currency}
            </components.Option>
        )
    }

    const OptionComponent = ({ data, ...props }) => {
        const icon = data.icon
        return (
            <components.Option {...props}>
                {icon}
                {data.label}
            </components.Option>
        )
    }

    const defaultValues = {
        amount: '0.001'
    }

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(AutoTradingSchema) })

    const onSubmit = async (data) => {
        try {
            const result = await createAutoTrading({
                pair: selectedPair.value,
                max_amount: data.amount,
                max_simultaneous_trades: maxTrades.value,
                max_risk_level: maxRisk.max_risk_level
            })
            if (result.error) {
                let errorMessage
                if (result.error.data.errors && result.error.data.errors.max_amount) {
                    errorMessage = result.error.data.errors.max_amount[0]
                    setError('amount', {
                        type: 'manual',
                        message: errorMessage
                    })
                } else {
                    errorMessage = result.error.data.message
                    setError('apiError', {
                        type: 'manual',
                        message: errorMessage
                    })
                }
            } else {
                toast.success('Auto trading started!')
                refetchTradinglist()
            }
        } catch (error) {
            setError('apiError', {
                type: 'manual',
                message: error?.data?.message
            })
        }
    }
    return (
        <>
            <div className='card mb-3 trading-card'>
                <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                    <h6 className='mb-0 fw-bold '>Auto Trading</h6>
                </div>
                <div className='card-body'>
                    <div className='card mb-3'>
                        <div className='card-body'>
                            <div className='row g-3'>
                                <div className='col-lg-12'>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group>
                                            <label className='form-label'>Pair</label>
                                            <Select
                                                name='selectedPair'
                                                options={updatedCryptoData}
                                                value={selectedPair}
                                                onChange={setSelectedPair}
                                                isClearable={false}
                                                components={{
                                                    Option: PairOptionComponent
                                                }}
                                            />
                                            {errors?.selectedPair && (
                                                <div className='invalid-feedback' style={{ display: 'block' }}>
                                                    {errors.selectedPair.message}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className='mb-3 mt-3' controlId='amount'>
                                            <label className='form-label'>Max trade amount</label>
                                            <Controller
                                                control={control}
                                                name='amount'
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control
                                                        onChange={onChange}
                                                        value={value.replace(/,/g, '.')}
                                                        isInvalid={errors.amount}
                                                        placeholder='Enter the amount'
                                                        type='text'
                                                    />
                                                )}
                                            />
                                            {errors?.amount && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.amount.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group className='mb-3 mt-3'>
                                            <label className='form-label'>Max simultaneous trades</label>
                                            <Select
                                                name='maxTrades'
                                                options={trades}
                                                value={maxTrades}
                                                onChange={setMaxTrade}
                                                isClearable={false}
                                                components={{
                                                    Option: OptionComponent
                                                }}
                                            />
                                            {errors?.maxTrades && (
                                                <div className='invalid-feedback' style={{ display: 'block' }}>
                                                    {errors.maxTrades.message}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className='mb-3 mt-3'>
                                            <label className='form-label'>Max risk level</label>
                                            <Select
                                                name='maxRisk'
                                                options={risks}
                                                value={maxRisk}
                                                onChange={setMaxRisk}
                                                isClearable={false}
                                                components={{
                                                    Option: OptionComponent
                                                }}
                                            />
                                            {errors?.maxRisk && (
                                                <div className='invalid-feedback' style={{ display: 'block' }}>
                                                    {errors.maxRisk.message}
                                                </div>
                                            )}
                                        </Form.Group>
                                        {errors?.apiError && (
                                            <div className='invalid-feedback mb-3 text-center' style={{ display: 'block' }}>
                                                {errors.apiError.message}
                                            </div>
                                        )}
                                        <Button
                                            type='submit'
                                            className='btn flex-fill btn-light-success py-2 fs-5 px-5 w-100'>
                                            Start auto trading
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AutoTradingCard
