import { createApi } from '@reduxjs/toolkit/query/react'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const affiliateApi = createApi({
    reducerPath: 'affiliateApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        getAffiliateToken: build.query({
            query: () => ({
                url: 'affiliate-program/token',
                method: 'GET'
            })
        }),
        getAffiliations: build.query({
            query: () => ({
                url: 'v1/affiliate-program/affiliations',
                method: 'GET'
            })
        })
    })
})

export const { useGetAffiliateTokenQuery, useGetAffiliationsQuery } = affiliateApi
