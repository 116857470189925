import React from 'react'
import UserDetailcard from '../../component/DashboardOne/UserDetailcard'
import AccountBalance from './AccountBalance'
import AccountProfit from './AccountProfit'
import AccountWithdrawList from "./AccountWithdrawList";
import AccountDepositList from './AccountDepositList';
import AccountBalanceTransfersList from "./AccountBalanceTransfersList";

const Dashboard = () => {
    return (
        <div className='container-xxl' style={{ margin: '0px' }}>
            <UserDetailcard />
            <div className='row g-3 mb-3 row-deck'>
                <AccountBalance />
                <AccountProfit />
            </div>
            <div className='row g-3 mb-3 row-deck'>
                <AccountWithdrawList />
            </div>
            <div className='row g-3 mb-3 row-deck'>
                <AccountDepositList />
            </div>
            <div className='row g-3 mb-3 row-deck'>
                <AccountBalanceTransfersList />
            </div>
        </div>
    )
}
export default Dashboard
