import React, { useState, useEffect } from 'react';
import {useGetLastPostCommentsQuery} from "../../../redux/services/newsfeedService";
import CommentList from "./CommentList";
import {useSub} from "../../../helpers/usePubSub";

const CommentListWrapper = ({ postId, commentsCount }) => {
    const [localComments, setLocalComments] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);

    const { data: result } = useGetLastPostCommentsQuery({ postId }, { skip: !commentsCount });

    useSub('post_comment', (comment) => {
        if (postId === comment.postId) {
            comment.isLiked = false;
            setLocalComments(prev => [...prev, comment]);
        }
    });

    useEffect(() => {
        if (result?.data?.comments) {
            setLocalComments(currentComments => [...currentComments, ...result.data.comments]);
            setHasNextPage(result.data.hasNextPage);
        }
    }, [result]);

    const getLastCommentId = () => {
        return localComments[localComments.length - 1]?.id;
    }

    return (
        <CommentList hasNextPage={hasNextPage} comments={localComments} postId={postId} cursor={getLastCommentId()}/>
    );
};

export default CommentListWrapper;
