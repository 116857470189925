import React from 'react'
import DataTable from 'react-data-table-component'
import EmptyTable from '../EmptyStates/EmptyTable'
import {Tooltip} from "react-tooltip";

const LongTermTableClosed = (props) => {
    const { cfdClosedDAta } = props

    const renderLeverage = (row) => {
        return (
            <>
                <Tooltip id={`leverage-tooltip-${row.id}`} />
                <span
                    className="text-muted d-block m-1"
                    data-tooltip-id={`leverage-tooltip-${row.id}`}
                    data-tooltip-content='Leverage'>{parseFloat(row.leverage).toFixed(0)} X</span>
            </>
        )
    }

    const renderCopyTrading = (row) => {
        if (row.following_trader_name) {
            return (
                <>
                    <div className={" dt-body-right"}>
                        <div className="d-flex">
                            <span className="text-muted small mx-1 min-width-55">Trader</span>
                            <span className="mx-1">{row.following_trader_name}</span>
                        </div>
                        <div className="d-flex">
                            <span className="text-muted small mx-1 min-width-55">Fee</span>
                            <span className="mx-1">{row.copy_trading_winning_fee_charged ? parseFloat(row.copy_trading_winning_fee_charged).toFixed(2) : '0'} {row.quote_currency}</span>
                        </div>
                    </div>
                </>
            )
        }

        return (
            <>
                -
            </>
        )
    }
    const columns = () => {
        return [
            {
                name: 'Pair',
                selector: (row) => row.asset,
                cell: (row) => {
                    const pairValue = row.asset
                    const pairValues = pairValue.split('-')
                    return (
                        <div className='price-block'>
                            <span className='fs-6 fw-bold pair-wrapper'>
                                <img
                                    src={require(`../../assets/images/coin/${pairValues[0]}.png`)}
                                    alt={pairValues[0]}
                                    className='img-fluid avatar pair-img'
                                    style={{ marginRight: '0.25rem' }}
                                />
                                {pairValues[0]} -
                                <img
                                    src={require(`../../assets/images/coin/${pairValues[1]}.png`)}
                                    alt={pairValues[1]}
                                    className='img-fluid avatar mx-1 pair-img'
                                />
                                {pairValues[1]}
                            </span>
                        </div>
                    )
                },
                sortable: true,
                minWidth: '180px',
                maxWidth: '220px'
            },
            {
                name: 'Position size',
                selector: (row) => row.position_size,
                cell: (row) => {
                    const pairValue = row.asset
                    const pairValues = pairValue.split('-')
                    return (
                        <>
                            {row.position_size} {pairValues[0]}
                        </>
                    )
                },
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Entry price',
                selector: (row) => row.entry_price,
                cell: (row) => (
                    <>
                        {parseFloat(row.entry_price).toFixed(2)} {row.quote_currency}
                    </>
                ),
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Direction',
                selector: (row) => row.direction,
                cell: (row) => {
                    if (row.direction === 'long') {
                        return <span className='color-price-up'>Buy / Long</span>
                    } else if (row.direction === 'short') {
                        return <span className='color-price-down'>Sell / Short</span>
                    } else {
                        return <>{row.direction}</>
                    }
                },
                sortable: true,
                minWidth: '100px',
                maxWidth: '100px'
            },
            {
                name: 'Margin',
                selector: (row) => row.margin,
                cell: (row) => {
                    return (
                        <>
                            {renderLeverage(row)}
                            {parseFloat(row.margin).toFixed(2)} {row.quote_currency}
                        </>
                    )
                },
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Close price',
                selector: (row) => row.close_price,
                cell: (row) => (
                    <>
                        {parseFloat(row.close_price).toFixed(2)} {row.quote_currency}
                    </>
                ),
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Open date',
                selector: (row) => row.position_opened_at,
                cell: (row) => <>{row.position_opened_at}</>,
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Close date',
                selector: (row) => row.close_time,
                cell: (row) => <>{row.close_time}</>,
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Profit/loss',
                selector: (row) => row.profit_loss,
                cell: (row) => {
                    if (row.profit_loss < 0) {
                        return (
                            <>
                                <span className='color-price-down'>
                                    {parseFloat(row.profit_loss).toFixed(2)} {row.quote_currency}
                                </span>
                            </>
                        )
                    } else if (row.profit_loss === 0) {
                        return (
                            <>
                                <span>
                                    {parseFloat(row.profit_loss).toFixed(2)} {row.quote_currency}
                                </span>
                            </>
                        )
                    } else if (row.profit_loss > 0) {
                        return (
                            <>
                                <span className='color-price-up'>
                                    +{parseFloat(row.profit_loss).toFixed(2)} {row.quote_currency}
                                </span>
                            </>
                        )
                    } else {
                        return (
                            <>
                                {parseFloat(row.profit_loss).toFixed(2)} {row.quote_currency}
                            </>
                        )
                    }
                },
                sortable: true,
                minWidth: '150px'
            },

            {
                name: 'Copy trading',
                selector: (row) => row.following_trader_name,
                cell: (row) => {
                    if (row.following_trader_name) {
                        return (
                            <>
                                {renderCopyTrading(row)}
                            </>
                        )
                    }

                    return (
                        <>
                            -
                        </>
                    )
                },
                sortable: false,
                minWidth: '250px'
            },

        ]
    }

    return (
        <div className='row g-3 mb-3'>
            <div className='col-lg-12 col-md-12'>
                <div id='DataTables_Table_11_wrapper' className='dataTables_wrapper dt-bootstrap5 no-footer'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <DataTable
                                title='Rewarded Staking'
                                columns={columns()}
                                data={cfdClosedDAta?.data || []}
                                noDataComponent={EmptyTable()}
                                defaultSortField='title'
                                selectableRows={false}
                                highlightOnHover={true}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LongTermTableClosed
