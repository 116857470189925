import DataTable from "react-data-table-component";
import React, {useState} from "react";
import CopyTradingModal from "./CopyTradingModal";

const TradersList = props => {
    const {traders, copyTradingOpened, followedTraders} = props;

    const [copyTradingModalOpen, setCopyTradingModalOpen] = useState(false);
    const [selectedTrader, setSelectedTrader] = useState(null)
    const [fullscreen, setFullscreen] = useState(true)
    const followedTradersIds = followedTraders ? followedTraders.map(trader => trader.traderId) : []

    const handleShowModal = row => {
        setSelectedTrader(row)
        setCopyTradingModalOpen(true)
        setFullscreen(true)
    }

    const columns = () => {
        const renderLastMonthReturn = (row) => {
            if (parseFloat(row.lastMonthReturn) > 0) {
                return <span className='color-price-up'>+{parseFloat(row.lastMonthReturn)}%</span>
            } else if (parseFloat(row.lastMonthReturn) < 0) {
                return <span className='color-price-down'>{parseFloat(row.lastMonthReturn)}%</span>
            } else {
                return <span>{parseFloat(row.lastMonthReturn)}%</span>
            }
        }

        const renderTopTradingCurrencies = (row) => {
            const top5TradingCurrencies = row.topTradingCurrencies.slice(0, 5)
            return top5TradingCurrencies.map(currency => (
                <img
                    key={`top-trading-currency-${row.id}-${currency}`}
                    src={require(`../../assets/images/coin/${currency}.png`)}
                    alt={row.name}
                    className='img-fluid avatar mx-1 cc-small-img'
                />
            ));
        }

        const renderCopyButton = (row) => {
            if (followedTradersIds.includes(row.id)) {
                return (
                <button type='button' className='btn btn-success border lift mt-1' disabled={true}>
                    Copied
                </button>
                )
            } else {
                return (
                    <button
                        data-bs-toggle='modal'
                        data-bs-target='#icoModal'
                        type='button'
                        className='btn btn-primary border lift mt-1'
                        onClick={() => handleShowModal(row)}>
                        Copy
                    </button>
                )
            }
        }

        return [
            {
                name: 'Trader',
                selector: (row) => row.nickname,
                cell: (row) => (
                    <>
                        {row.nickname}
                    </>
                ),
                sortable: true,
                minWidth: '200px'
            },
            {
                name: 'Return (last mo.)',
                selector: (row) => row.lastMonthReturn,
                sortable: true,
                cell: (row) => (
                    <>
                        {renderLastMonthReturn(row)}
                    </>
                )
            },
            {
                name: 'Top currencies (last mo.)',
                selector: (row) => row.topTradingCurrencies,
                sortable: false,
                cell: (row) => (
                    <>
                        {renderTopTradingCurrencies(row)}
                    </>
                )
            },
            {
                name: 'Copiers',
                selector: (row) => row.copiers,
                sortable: true,
                cell: (row) => (
                    <>
                        {row.copiersCount}
                    </>
                )
            },
            {
                name: 'Fee',
                selector: (row) => row.winningFeePercent,
                sortable: true,
                cell: (row) => (
                    <>
                        {`${Number(parseFloat(row.winningFeePercent) * 100)}%`}
                    </>
                )
            },
            {
                name: 'Actions',
                selector: (row) => 'Copy',
                sortable: false,
                cell: (row) => renderCopyButton(row)
            }
        ]
    }

    return (
        <div className='row g-3 mb-3'>
            <div className='col-lg-12 col-md-12'>
                <div id='DataTables_Table_11_wrapper' className='dataTables_wrapper dt-bootstrap5 no-footer'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <DataTable
                                title='Staking'
                                columns={columns()}
                                data={traders ?? []}
                                defaultSortField='nickname'
                                selectableRows={false}
                                highlightOnHover={true}
                                pagination
                            />
                        </div>
                        <CopyTradingModal
                            fullscreen={fullscreen}
                            copyTradingModalOpen={copyTradingModalOpen}
                            setCopyTradingModalOpen={setCopyTradingModalOpen}
                            traderName={selectedTrader?.nickname}
                            traderLastMonthReturn={selectedTrader?.lastMonthReturn}
                            traderTopTradingCurrencies={selectedTrader?.topTradingCurrencies}
                            traderId={selectedTrader?.id}
                            copyTradingOpened={copyTradingOpened}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TradersList
