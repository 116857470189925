import React, { useCallback, useEffect, useState } from 'react'

import { useGetTradeQuery } from '../../redux/services/tradeService'
import SignalsCardClosedTrades from './SignalsCardClosedTrades'
import SignalsCardOpenTrades from './SignalsCardOpenTrades'

import SignalsCardSingle from './SignalsCardSingle'
import useWebSocket from 'react-use-websocket'

const SignalsCard = () => {
    const { currentData: tradesData, refetch: refetchTradesData, isLoading: isLoadingTradesData } = useGetTradeQuery()
    const [signals, setSignals] = useState([])
    const { sendMessage, lastMessage } = useWebSocket('wss://api.coiniy.com:2053/')
    const fetchSignals = useCallback(() => sendMessage(JSON.stringify({ channel: 'signals' })), [])

    useEffect(() => {
        fetchSignals()
    }, [])

    useEffect(() => {
        if (lastMessage) {
            const data = JSON.parse(lastMessage.data)
            setSignals(data)
        }
    }, [lastMessage])

    return (
        <>
            {signals && signals?.length > 0 ? (
                <div className='col-sm-12 col-xl-5 col-summary-wrapper'>
                    {signals &&
                        signals.map((signal) => {
                            return (
                                <SignalsCardSingle
                                    pair={signal.pair}
                                    key={`single-${signal.id}`}
                                    riskLevel={signal.risk_level}
                                    signalType={signal.type}
                                    signalId={signal.id}
                                    signalExpires={signal.expires}
                                    signalCreated={signal.created}
                                    refetchTradesData={refetchTradesData}
                                />
                            )
                        })}
                </div>
            ) : (
                <div className='col-sm-12 col-xl-5 col-summary-wrapper'>
                    <div className='card mb-3'>
                        <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                            <h6 className='mb-0 fw-bold '>Signals</h6>
                        </div>
                        <div className='card-body'>Loading...</div>
                    </div>
                </div>
            )}
            <div className='col-xl-7'>
                <div className='card mb-3'>
                    <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                        <h6 className='mb-0 fw-bold '>Open trades</h6>
                    </div>
                    {isLoadingTradesData ? (
                        <div className='card-body'>Loading...</div>
                    ) : (
                        <div className='card-body'>
                            <SignalsCardOpenTrades openTrades={tradesData?.open} refetchTradesData={refetchTradesData} />
                        </div>
                    )}
                </div>
                <div className='card mb-3'>
                    <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                        <h6 className='mb-0 fw-bold '>Closed trades</h6>
                    </div>
                    {isLoadingTradesData ? (
                        <div className='card-body'>Loading...</div>
                    ) : (
                        <div className='card-body'>
                            <SignalsCardClosedTrades closedTrades={tradesData?.closed} />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default SignalsCard
