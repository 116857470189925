import React, { useState } from 'react'
import { Form, Tab, Button, FloatingLabel } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoginSchema } from '../../helpers/forms/schema/auth'
import { useGetUserQuery, useLoginMutation } from '../../redux/services/authService'
import AuthLoadingButton from './AuthLoadingButton'

import Verification from './Verification'

const Login = () => {
    const [showVerificationForm, setShowVerificationForm] = useState(false)

    const [userLogin, { isLoading }] = useLoginMutation()
    const { refetch } = useGetUserQuery()

    const navigate = useNavigate()

    const defaultValues = {
        email: '',
        password: ''
    }

    const {
        control,
        setError,
        handleSubmit,
        watch,
        reset,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(LoginSchema) })

    const watchEmail = watch('email')
    const watchPassword = watch('password')

    const handleResetForm = () => {
        setShowVerificationForm(false)
        reset()
    }

    const onSubmit = async (data) => {
        try {
            const result = await userLogin(data)
            if (!result.error) {
                refetch()
                navigate('/')
            } else if (result.error.status === 402) {
                setShowVerificationForm(true)
            } else {
                setError('password', {
                    type: 'manual',
                    message: 'Invalid email or password'
                })
            }
        } catch (err) {
            Object.keys(err.response.data.errors).forEach((key) => {
                setError(key, {
                    type: 'manual',
                    message: err.response.data.message
                })
            })
        }
    }

    return (
        <>
            {showVerificationForm ? (
                <>
                    <Verification watchEmail={watchEmail} watchPassword={watchPassword} handleResetForm={handleResetForm} />
                </>
            ) : (
                <>
                    <div className='body d-flex p-0 p-xl-5'>
                        <div className='container'>
                            <div className='row g-3'>
                                <div className='col-lg-12 d-flex justify-content-center align-items-center auth-h100'>
                                    <div className='d-flex flex-column'>
                                        <h1>Account Login</h1>
                                        <span className='text-muted'>
                                            Welcome back! Log In with your email and password.
                                        </span>
                                        <Tab.Container defaultActiveKey='first'>
                                            <Tab.Content className='tab-content mt-4 mb-3'>
                                                <Tab.Pane className='tab-pane fade ' id='Email' eventKey='first'>
                                                    <div className='card'>
                                                        <div className='card-body p-4'>
                                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                                <Form.Group className='mb-3' controlId='emailAddress'>
                                                                    <Controller
                                                                        control={control}
                                                                        name='email'
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <FloatingLabel
                                                                                controlId='email'
                                                                                label='Email address'
                                                                                className='mb-3'>
                                                                                <Form.Control
                                                                                    onChange={onChange}
                                                                                    value={value}
                                                                                    isInvalid={errors.email}
                                                                                    placeholder='Email address'
                                                                                    type='email'
                                                                                />
                                                                                {errors?.email && (
                                                                                    <Form.Control.Feedback type='invalid'>
                                                                                        {errors.email.message}
                                                                                    </Form.Control.Feedback>
                                                                                )}
                                                                            </FloatingLabel>
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className='mb-3' controlId='password'>
                                                                    <Controller
                                                                        control={control}
                                                                        name='password'
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <FloatingLabel
                                                                                controlId='password'
                                                                                label='Password'
                                                                                className='mb-3'>
                                                                                <Form.Control
                                                                                    onChange={onChange}
                                                                                    value={value}
                                                                                    isInvalid={errors.password}
                                                                                    placeholder='Password'
                                                                                    type='password'
                                                                                />
                                                                                {errors?.password && (
                                                                                    <Form.Control.Feedback type='invalid'>
                                                                                        {errors.password.message}
                                                                                    </Form.Control.Feedback>
                                                                                )}
                                                                            </FloatingLabel>
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                                <Link
                                                                    to='/forgot-password'
                                                                    title='#'
                                                                    className='text-primary text-decoration-underline'>
                                                                    Forgot password?
                                                                </Link>
                                                                {isLoading ? (
                                                                    <AuthLoadingButton />
                                                                ) : (
                                                                    <Button
                                                                        type='submit'
                                                                        className='btn btn-primary py-2 fs-5 w-100 mt-2'>
                                                                        Login
                                                                    </Button>
                                                                )}
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                        <div className='text-center'>
                                            <span>Don't hane an account? </span>
                                            <Link
                                                to='/register'
                                                title='#'
                                                className='text-primary text-decoration-underline'>
                                                Register now
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default Login
