import { authApi } from '../redux/services/authService'
import { userApi } from '../redux/services/userService'
import { stakingApi } from '../redux/services/stakingService'
import { affiliateApi } from '../redux/services/affiliateService'
import { exchangeCryptoApi } from '../redux/services/exchangeCryptoService'
import { cfdApi } from '../redux/services/cfdService'
import { autoTrading } from '../redux/services/autoTradingService'
import { buyCryptoApi } from '../redux/services/buyCryptoService'
import { sellCryptoApi } from '../redux/services/sellCryptoService'
import { tradeApi } from '../redux/services/tradeService';
import { copyTradingApi} from "../redux/services/copyTradingService";
import {newsfeedApi} from "../redux/services/newsfeedService";
import {balanceApi} from "../redux/services/balanceService";

const apiSlices = [
    authApi,
    userApi,
    stakingApi,
    affiliateApi,
    exchangeCryptoApi,
    cfdApi,
    autoTrading,
    buyCryptoApi,
    sellCryptoApi,
    tradeApi,
    copyTradingApi,
    newsfeedApi,
    balanceApi
]

export const clearApiState = (dispatch) => {
    apiSlices.forEach((apiSlice) => {
        dispatch(apiSlice.util.resetApiState())
    })
}
