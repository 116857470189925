import React from 'react'

import StakingAssets from './StakingAssets'
import StakingActiveAssets from './StakingActiveAssets'
import StakingRewardAssets from './StakingRewardAssets'
import StakingSummary from './StakingSummary'

import { useGetStakingAmountsQuery } from '../../redux/services/stakingService'

const Staking = () => {
    const { currentData: stakingAmountsData } = useGetStakingAmountsQuery()

    return (
        <div className='container-xxl bs-container-reset pd-left-xsmall'>
            <div className='row g-3 mb-3'>
                <div
                    style={{ marginTop: '0', paddingRight: '0' }}
                    className='row g-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 mb-3'>
                    {stakingAmountsData && (
                        <>
                            {Object.keys(stakingAmountsData.data.amounts).map((item, index) => {
                                const amountData = stakingAmountsData.data.amounts[item]
                                return (
                                    <StakingSummary
                                        coin={item}
                                        amount={amountData.amount}
                                        fiat_currency={amountData.price_currency}
                                        value={amountData.value}
                                        percentage={amountData.value_percent}
                                        key={`staking-summary-${index}`}
                                    />
                                )
                            })}
                        </>
                    )}
                </div>
                <div className='col-xxl-12'>
                    <div className='card'>
                        <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                            <h6 className='mb-0 fw-bold '>Assets</h6>
                        </div>
                        <StakingAssets />
                    </div>
                </div>
                <div className='col-xxl-12'>
                    <div className='card mb-3'>
                        <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                            <h6 className='mb-0 fw-bold '>Active assets</h6>
                        </div>
                        <div className='card-body'>
                            <StakingActiveAssets />
                        </div>
                    </div>
                    <div className='card mb-3'>
                        <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0'>
                            <h6 className='mb-0 fw-bold '>Rewards</h6>
                        </div>
                        <div className='card-body'>
                            <StakingRewardAssets />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Staking
