import React from 'react'
import Chart from 'react-apexcharts'

import { useGetUserTotalProfitQuery } from '../../redux/services/userService'
import EmptyProfitData from '../EmptyStates/EmptyProfitData'

const AccountProfit = () => {
    const { currentData: profitData, isLoading: isProfitDataLoading } = useGetUserTotalProfitQuery()

    const fiatCurrency = profitData?.data.currency
    const totalProfit = parseFloat(profitData?.data?.total_profit).toFixed(2)
    const cfdProfit = parseFloat(profitData?.data?.cfd_profit).toFixed(2)
    const stakingProfit = parseFloat(profitData?.data?.staking_profit).toFixed(2)
    const affiliateProfit = parseFloat(profitData?.data?.affiliate_profit).toFixed(2)
    const tradingProfit = parseFloat(profitData?.data?.trading_profit).toFixed(2)

    const singleProfitData = profitData?.data || {}

    const isAnyValueNonZero = Object.keys(singleProfitData)
        .filter((key) => key !== 'currency')
        .some((key) => singleProfitData[key] !== 0)

    const series = [Number(stakingProfit), Number(cfdProfit), Number(tradingProfit), Number(affiliateProfit)]

    const options = {
        chart: {
            height: 250,
            type: 'donut'
        },
        labels: ['Staking', 'Future (CFD)', 'Trades (Trading BOT, Signals)', 'Referral Program'],
        dataLabels: {
            enabled: false
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            show: true
        },
        colors: ['var(--chart-color1)', 'var(--chart-color2)', 'var(--chart-color3)', 'var(--chart-color4)'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }
        ]
    }

    return (
        <div className='col-xl-6'>
            <div className='card'>
                <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0 align-items-center'>
                    <h6 className='mb-0 fw-bold '>Profit</h6>
                </div>
                {!isAnyValueNonZero ? (
                    <>
                        {isProfitDataLoading ? (
                            <div className='d-flex justify-content-center'>Loading...</div>
                        ) : (
                            <EmptyProfitData />
                        )}
                    </>
                ) : (
                    <>
                        {isProfitDataLoading ? (
                            <div className='d-flex justify-content-center'>Loading...</div>
                        ) : (
                            <div className='card-body'>
                                <div className='row g-3'>
                                    <div className='col-lg-6'>
                                        <div>Total profit:</div>
                                        <h3>
                                            {totalProfit} {fiatCurrency}
                                        </h3>
                                        <div className='mt-3 pt-3 pt-2 small'>Staking</div>
                                        {stakingProfit !== 0 ? (
                                            <h5>
                                                {stakingProfit} {fiatCurrency}
                                            </h5>
                                        ) : (
                                            <span className='text-muted small'>You don't have any profit yet.</span>
                                        )}

                                        <div className='mt-3 small'>Future (CFD)</div>
                                        {cfdProfit !== 0 ? (
                                            <h5>
                                                {cfdProfit} {fiatCurrency}
                                            </h5>
                                        ) : (
                                            <span className='text-muted small'>You don't have any profit yet.</span>
                                        )}
                                        <div className='mt-3 small'>Trades (Trading BOT, Signals)</div>
                                        {tradingProfit !== 0 ? (
                                            <h5>
                                                {tradingProfit} {fiatCurrency}
                                            </h5>
                                        ) : (
                                            <span className='text-muted small'>You don't have any profit yet.</span>
                                        )}
                                        <div className='mt-3 small'>Affiliate</div>
                                        {affiliateProfit !== 0 ? (
                                            <h5>
                                                {affiliateProfit} {fiatCurrency}
                                            </h5>
                                        ) : (
                                            <span className='text-muted small'>You don't have any profit yet.</span>
                                        )}
                                    </div>
                                    <div className='col-lg-6' style={{ position: 'relative' }}>
                                        <Chart options={options} series={series} type='donut' height='250' />
                                        <div className='resize-triggers'>
                                            <div className='expand-trigger'>
                                                <div style={{ width: '512px', height: '260px' }}></div>
                                            </div>
                                            <div className='contract-trigger'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default AccountProfit
