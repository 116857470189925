import { createApi } from '@reduxjs/toolkit/query/react'
import { setTokens } from '../helpers/utils'
import apiBaseQueryFactory from './apiBaseQueryFactory'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: apiBaseQueryFactory(),
    endpoints: (build) => ({
        register: build.mutation({
            query: (data) => ({
                url: `register?affiliate_token=${data.affiliate_token}`,
                method: 'POST',
                body: data
            })
        }),
        login: build.mutation({
            query: (data) => ({
                url: 'auth/login',
                method: 'POST',
                body: data
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    setTokens({
                        accessToken: data.access_token
                    })
                } catch (error) {}
            }
        }),
        getUser: build.query({
            query: () => ({
                url: 'auth/me',
                method: 'GET'
            })
        }),
        forgotPassword: build.mutation({
            query: (email) => ({
                url: 'forgot-password',
                method: 'POST',
                body: { email }
            })
        }),
        resetPassword: build.mutation({
            query: (data) => ({
                url: 'forgot-password/new-password',
                method: 'POST',
                body: data
            })
        })
    })
})

export const {
    useRegisterMutation,
    useLoginMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useGetUserQuery
} = authApi
