import React from 'react';
import DOMPurify from 'dompurify';

function ContentWithTags({ text }) {
    const linkify = (inputText) => {
        // Extended to handle mentions as well
        const regex = /(\s|^)([#][a-z\d-]+)/ig;
        return inputText.replace(regex, (match, space, tagOrMention) => {
            const path = tagOrMention.startsWith('#') ? 'tag' : 'profile';
            return `${space}<a _target="same" href="/newsfeed?${path}=${tagOrMention.substring(1)}">${tagOrMention}</a>`;
        });
    };

    // Sanitize and linkify the input text
    const sanitizedContent = DOMPurify.sanitize(linkify(text), { ALLOWED_TAGS: ['a'], ALLOWED_ATTR: ['href', '_target'] });

    // Using dangerouslySetInnerHTML with sanitized content
    return <span dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
}

export default ContentWithTags;
