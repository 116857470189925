import React, { useState } from 'react'

import { Button, Form, Modal } from 'react-bootstrap'
import Select, { components } from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'

import { TransferFormSchema } from '../../helpers/forms/schema/base'

import {cryptocurrenciesData, renderIcon} from '../../helpers/cryptocurrency'
import { useTransferBalanceMutation } from '../../redux/services/balanceService'
import {useDispatch} from "react-redux";
import {accountBalanceChanged} from "../../redux/reducer/actions";

const AccountBalanceTransferModal = (props) => {
    const { modalOpen, setModalOpen } = props

    const cryptocurrencies = [
        {
            value: 'USD',
            label: 'US Dollar',
            icon: renderIcon('USD'),
            default_amount: '10'
        },
        ...cryptocurrenciesData,
    ]

    const [transferBalance] = useTransferBalanceMutation()

    const [selectedCrypto, setSelectedCrypto] = useState(cryptocurrencies.find((currency) => currency.value === 'BTC'))

    const defaultValues = {
        amount: '',
        receiver: '',
        currency: 'USD'
    }

    const dispatch = useDispatch();

    const OptionComponent = ({ data, ...props }) => {
        const icon = data.icon
        return (
            <components.Option {...props}>
                {icon}
                {data.label}
            </components.Option>
        )
    }

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(TransferFormSchema) })

    const handleCloseModal = () => {
        setModalOpen(false)
        reset()
    }

    const onSubmit = async (data) => {
        try {
            const result = await transferBalance({
                amount: data.amount,
                receiver: data.receiver,
                currency: selectedCrypto.value
            })
            const validationErrors = result?.error?.data?.errors;
            if (typeof validationErrors === 'object') {
                Object.keys(validationErrors).forEach((key) => {
                    setError(key, {
                        type: 'manual',
                        message: validationErrors[key][0]
                    })
                })
            } else if (result.error) {
                setError('apiError', {
                    type: 'manual',
                    message: result.error.data.message
                })
            } else {
                toast.success('Balance transferred and it will be available in your account in a few seconds')
                handleCloseModal()
                dispatch(accountBalanceChanged());
            }
        } catch (error) {
            setError('apiError', {
                type: 'manual',
                message: error.data.message
            })
        }
    }

    return (
        <Modal
            className='modal fade'
            id='icoModal'
            show={modalOpen}
            onHide={() => {
                handleCloseModal()
            }}
            fullscreen='sm-down bs-modal-xs'>
            <Modal.Dialog className='' style={{ maxWidth: '900px' }}>
                <div className='modal-content'>
                    <Modal.Header className='modal-header' closeButton>
                        <h5 className='modal-title'>Transfer</h5>
                    </Modal.Header>
                    <Modal.Body className='modal-body custom_setting'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className='col-md-12'>
                                        <Form.Group className='mb-3'>
                                            <label className='form-label'>Cryptocurrency</label>
                                            <Select
                                                name='cryptocurrency'
                                                options={cryptocurrencies}
                                                value={selectedCrypto}
                                                onChange={setSelectedCrypto}
                                                isClearable={false}
                                                components={{
                                                    Option: OptionComponent
                                                }}
                                            />
                                            {errors?.cryptocurrency && (
                                                <div className='invalid-feedback' style={{ display: 'block' }}>
                                                    {errors.cryptocurrency.message}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId='amount'>
                                            <label className='form-label'>Amount</label>
                                            <Controller
                                                control={control}
                                                name='amount'
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control
                                                        onChange={onChange}
                                                        value={value.replace(/,/g, '.')}
                                                        isInvalid={errors.amount}
                                                        placeholder='Enter the amount'
                                                        type='text'
                                                    />
                                                )}
                                            />
                                            {errors?.amount && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.amount.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId='receiver'>
                                            <label className='form-label'>User nickname or email</label>
                                            <Controller
                                                control={control}
                                                name='receiver'
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control
                                                        onChange={onChange}
                                                        value={value}
                                                        isInvalid={errors.receiver}
                                                        placeholder='Enter user nickname or email'
                                                        type='text'
                                                    />
                                                )}
                                            />
                                            {errors?.receiver && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.receiver.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        {errors?.apiError && (
                                            <div className='invalid-feedback mb-3 text-center' style={{ display: 'block' }}>
                                                {errors.apiError.message}
                                            </div>
                                        )}
                                        <Button type='submit' className='btn flex-fill fs-5 w-100'>
                                            Transfer
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal.Dialog>
        </Modal>
    )
}

export default AccountBalanceTransferModal
