import React from 'react'
import { Button } from 'react-bootstrap'

const AuthLoadingButton = () => {
    return (
        <Button disabled type='submit' className='btn btn-primary py-2 fs-5 w-100 mt-2 btn-loading'>
            Loading
            <div className='spinner-border text-light' role='status'></div>
        </Button>
    )
}

export default AuthLoadingButton
