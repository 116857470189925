import React, {useEffect} from 'react'

import {useGetWithdrawCryptoRequestsQuery} from '../../redux/services/userService'
import EmptyStateImage from "../../assets/images/no-data.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Tooltip} from "react-tooltip";
import {connect} from "react-redux";


const AccountWithdrawList = (isBalanceChanged) => {

    const { currentData: cryptoWithdraws, refetch: refetchCryptoWithdraws, isLoading } = useGetWithdrawCryptoRequestsQuery({}, { refetchOnMountOrArgChange: true })

    useEffect(() => {
        if (isBalanceChanged) {
            refetchCryptoWithdraws()
        }
    }, [cryptoWithdraws, isBalanceChanged, refetchCryptoWithdraws]);

    const truncate = (input, length = 50) => {
        if (input.length > length) {
            return input.substring(0, length) + '...';
        }
        return input;
    };

    const addressWithTag = (address, tag) => {
        return tag ? `${address} (tag: ${tag})` : address;
    }

    const renderStatus = (status) => {
        if (status === 'unconfirmed_request') {
            return (
                <span className='badge bg-info'>Waiting for confirmation</span>
            )
        }

        if (status === 'confirmed_request') {
            return (
                <span className='badge bg-success'>Confirmed</span>
            )
        }

        if (status === 'canceled_request') {
            return (
                <span className='badge bg-warning'>Canceled</span>
            )
        }

        if (status === 'rejected') {
            return (
                <span className='badge bg-danger'>Rejected</span>
            )
        }

        if (status === 'accepted') {
            return (
                <span className='badge bg-success'>Accepted</span>
            )
        }

        if (status === 'done') {
            return (
                <span className='badge bg-success'>Done</span>
            )
        }
    }

    const getStatusTooltipContent = (status) => {
        if (status === 'unconfirmed_request') {
            return 'Withdraw request is waiting for confirmation. Email with confirmation link was sent to your email address.'
        }

        if (status === 'confirmed_request') {
            return 'Withdraw request is confirmed. Withdraw will be processed soon.';
        }

        if (status === 'canceled_request') {
            return 'Withdraw request is canceled.';
        }

        if (status === 'rejected') {
            return 'Withdraw request is rejected. Please contact support for more information.';
        }

        if (status === 'accepted') {
            return 'Withdraw request is accepted. Withdraw will be processed soon.';
        }

        if (status === 'done') {
            return 'Withdraw request is done. Your funds are sent to your wallet.';
        }
    }
    return (
        <div className='col-xl-12 col-xxl-12'>
            <div className='card card-balance-wrapper'>
                <div className='card-header py-3 d-flex flex-wrap justify-content-between align-items-center bg-transparent border-bottom-0'>
                    <h6 className='mb-0 fw-bold'>Withdraws</h6>
                </div>
                {!cryptoWithdraws?.length ? (
                    <>
                        {isLoading ? (
                            <div className='d-flex justify-content-center mb-4'>Loading...</div>
                        ) : (
                            <div className='card-body text-center p-5'>
                                <img src={EmptyStateImage} className='img-fluid mx-size' alt='Your account has no withdraws' />
                                <div className='mt-4 mb-2'>
                                    <span className='text-muted'>Your account has no withdraws</span>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className='card-body' style={{ paddingTop: '0px' }}>
                        <PerfectScrollbar className='table-fixed-height'>
                            <div className='table-responsive'>
                                <table className='table table-nowrap mb-0'>
                                    <thead>
                                    <tr>
                                        <th>Currency</th>
                                        <th>Amount</th>
                                        <th>Transaction fee</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Wallet</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {cryptoWithdraws.map((withdraw, index) => (
                                        <tr key={index}>
                                            <td style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: '20px', marginRight: '5px' }}
                                                    src={require(`../../assets/images/coin/${withdraw.currency.symbol}.png`)}
                                                    alt={withdraw.currency.name}
                                                />
                                                {withdraw.currency.name}
                                            </td>
                                            <td>
                                                <span className='text-muted'>{parseFloat(withdraw.amount)} {withdraw.currency.symbol}</span>
                                            </td>
                                            <td>
                                                {withdraw.transaction_fee.amount > 0 ? (
                                                    <span className='text-muted'>{parseFloat(withdraw.transaction_fee.amount)} {withdraw.transaction_fee.currency.symbol}</span>
                                                ) : (
                                                    <span className='text-muted'>0 {withdraw.transaction_fee.currency.symbol}</span>
                                                )}
                                            </td>
                                            <td>
                                                <Tooltip id={`cryptoWithdrawStatus-${index}`} key={`cryptoWithdrawStatus-${index}`} />
                                                <span
                                                    data-tooltip-id={`cryptoWithdrawStatus-${index}`}
                                                    data-tooltip-content={getStatusTooltipContent(withdraw.status.value)}
                                                    className='text-muted'>{renderStatus(withdraw.status.value)}</span>
                                            </td>
                                            <td>
                                                <span className='text-muted'>{withdraw.created_at}</span>
                                            </td>
                                            <td>
                                                <Tooltip id={`cryptoWithdrawAddress-${index}`} key={`cryptoWithdrawAddress-${index}`} />
                                                <span className='text-muted'
                                                      data-tooltip-id={`cryptoWithdrawAddress-${index}`}
                                                      data-tooltip-content={addressWithTag(withdraw.address.address, withdraw.address.tag)}>
                                                    {truncate(withdraw.address.address, 20)}</span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </PerfectScrollbar>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isBalanceChanged: state.accountReducer.isBalanceChanged,
});

export default connect(mapStateToProps)(AccountWithdrawList);
