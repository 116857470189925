import React, { useEffect, useState } from 'react'
const LimitAmountSwitch = ({onSwitch, initialValue = 'Amount'}) => {
    const limitText = 'Limit'
    const amountText = 'Amount'


    const [currentText, setCurrentText] = useState(initialValue)

    const handleSwitch = () => {
        onSwitch(currentText === 'Limit' ? 'Amount' : 'Limit')
        setCurrentText(currentText === 'Limit' ? amountText : limitText)
    }

    return (
        <span className="">{currentText} <i style={{'cursor': 'pointer'}} onClick={() =>handleSwitch()} className='fa fa-retweet'></i></span>
    )
}

export default LimitAmountSwitch
