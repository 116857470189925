export const renderIcon = (name) => {
    return <img src={require(`../assets/images/coin/${name}.png`)} alt={name} className='img-fluid avatar mx-1' />
}


/**
 * @type {[{default_amount: string, icon: *, label: string, value: string},{default_amount: string, icon: *, label: string, value: string},{default_amount: string, icon: *, label: string, value: string},{default_amount: string, icon: *, label: string, value: string},{default_amount: string, icon: *, label: string, value: string},null,null,null,null,null,null,null,null,null,null,null,null,null]}
 */
export const cryptocurrenciesData = [
    {
        value: 'BTC',
        label: 'Bitcoin',
        icon: renderIcon('BTC'),
        default_amount: '0.0006'
    },
    {
        value: 'ETH',
        label: 'Ethereum',
        icon: renderIcon('ETH'),
        default_amount: '0.0071'
    },
    {
        value: 'LTC',
        label: 'Litecoin',
        icon: renderIcon('LTC'),
        default_amount: '0.036'
    },
    {
        value: 'BCH',
        label: 'Bitcoin Cash',
        icon: renderIcon('BCH'),
        default_amount: '0.01'
    },
    {
        value: 'USDT',
        label: 'Tether',
        icon: renderIcon('USDT'),
        default_amount: '100'
    },
    {
        value: 'USDC',
        label: 'USD Coin',
        icon: renderIcon('USDC'),
        default_amount: '1'
    },
    {
        value: 'ADA',
        label: 'Cardano',
        icon: renderIcon('ADA'),
        default_amount: '1'
    },
    {
        value: 'MATIC',
        label: 'Polygon',
        icon: renderIcon('MATIC'),
        default_amount: '1'
    },
    {
        value: 'SOL',
        label: 'Solana',
        icon: renderIcon('SOL'),
        default_amount: '0.01'
    },
    {
        value: 'TRX',
        label: 'TRON',
        icon: renderIcon('TRX'),
        default_amount: '1'
    },
    {
        value: 'DOT',
        label: 'Polkadot',
        icon: renderIcon('DOT'),
        default_amount: '0.1'
    },
    {
        value: 'SHIB',
        label: 'Shiba Inu',
        icon: renderIcon('SHIB'),
        default_amount: '1000'
    },
    {
        value: 'DAI',
        label: 'Dai',
        icon: renderIcon('DAI'),
        default_amount: '1'
    },
    {
        value: 'AVAX',
        label: 'Avalanche',
        icon: renderIcon('AVAX'),
        default_amount: '0.1'
    },
    {
        value: 'WBTC',
        label: 'Wrapped Bitcoin',
        icon: renderIcon('WBTC'),
        default_amount: '0.0006'
    },
    {
        value: 'LINK',
        label: 'Chainlink',
        icon: renderIcon('LINK'),
        default_amount: '0.1'
    },
    {
        value: 'ATOM',
        label: 'Cosmos',
        icon: renderIcon('ATOM'),
        default_amount: '0.1'
    },
    {
        value: 'UNI',
        label: 'Uniswap',
        icon: renderIcon('UNI'),
        default_amount: '0.1'
    }
]

export const simultaneousTrades = [
    {
        value: '1',
        label: '1'
    },
    {
        value: '2',
        label: '2'
    },
    {
        value: '3',
        label: '3'
    },
    {
        value: '4',
        label: '4'
    },
    {
        value: '5',
        label: '5'
    }
]

export const riskLevels = [
    {
        value: 'Low risk',
        label: 'Low risk',
        max_risk_level: '0'
    },
    {
        value: 'Medium risk',
        label: 'Medium risk',
        max_risk_level: '1'
    },
    {
        value: 'High risk',
        label: 'High risk',
        max_risk_level: '2'
    },
    {
        value: 'Highest risk',
        label: 'Highest risk',
        max_risk_level: '3'
    }
]

export const leverageValuesData = ['1', '2', '5', '10', '20']
