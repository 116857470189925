import React from 'react';
import { Card, Image } from 'react-bootstrap';
import PostActions from "./PostActions";
import Profile from '../../../assets/images/profile_av.svg'
import { timeAgo } from '../../../helpers/dateFormater';
import CommentListWrapper from "./CommentListWrapper";
import ContentWithTags from "./ContentWithTags";

const SinglePost = ({post}) => {

    if (!post) return (<></>);
    const userAvatarUrl = post.userAvatar || Profile;

    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-start p-4">
                <div>
                    <div className="d-flex align-items-center mb-1">
                        <Image src={userAvatarUrl} roundedCircle width="30" height="30" />
                        <strong className="ms-2">{`@${post.userNickname}`}</strong>
                        <span className="text-muted text-light m-lg-1">· {timeAgo(post.createdAt)}</span>
                    </div>
                    <div>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="pt-0 p-4">
                <Card.Text>
                    <ContentWithTags text={post.content}/>
                </Card.Text>
                {post.coverUrl && <Image src={post.coverUrl} style={{width: '100%', height: 'auto', objectFit: 'cover', marginBottom: '10px'}} />}
                <PostActions postId={post.id} isLiked={post.isLiked} likesCount={post.likesCount} commentsCount={post.commentsCount}/>
            </Card.Body>
            <CommentListWrapper commentsCount={post.commentsCount} postId={post.id}/>
        </Card>
    );
};

export default SinglePost;
